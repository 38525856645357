

export class NumericResultKm {

	constructor(element) {

		//console.log("NumericResult:", element);

		this.element = element;

	}

	transformData = (data) => {

		try {

			let lang = navigator.language || "de-DE";

			let formatter = new Intl.NumberFormat(lang);

			for (let row of data.results.bindings) {

				if (row[this.element.id]) {

					let value = formatter.format(parseFloat(row[this.element.id].value) / 1000);

					row[this.element.id] = { type: "numeric", value: value + " km" };

				}

			}

		} catch (e) {
			console.error(e);
		}

		return data;

	}

}
