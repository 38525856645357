import React, { useEffect } from "react";
import {
	Input,
	FlexboxGrid,
	Toggle
} from "rsuite";

import { DecoratedInput } from "./DecoratedInput.js";
import { ResultSelectorFunction } from "./ResultSelectorFunction.js";


export const TextInputFunction = (props) => {

	//{ id: let id, label: let label, value: this.state.value, invisible: this.invisible, visible: this.state.visible, onChange: this.onChange, onClean: this.onClean } = props);

	const [id, setId] = React.useState(props.id);
	const [label, setLabel] = React.useState(props.label);
	const [unit, setUnit] = React.useState(props.unit);
	const [value, setValue] = React.useState(props.value?.text || "");
	const [visible, setVisible] = React.useState(props.visible || false);
	const [regex, setRegex] = React.useState(props.value?.regex || false);
	const [invisible, setInvisible] = React.useState(props.invisible);


	useEffect(() => {
		//console.log("Update", props.id, props.visible, props.value);

	}, [props]);

	useEffect(() => {

		if (props.visible != visible) {

			//console.log("Effect!", props.id, props.visible);
			setVisible(props.visible);

		}

	}, [props.visible]);

	const onChangeVisible = (newVisible) => {

		setVisible(newVisible)

		props.onChange(id, { value: value, visible: newVisible }, "visibility");

	}

	const onChangeValue = (newValue) => {

		let valueObject = { text: newValue, regex: regex }

		setValue(newValue)



		if (newValue === "") {

			//console.log("Cleanup")

			props.onClean(id, valueObject);

		} else {

			props.onChange(id, { value: valueObject, visible: visible }, "value");

		}

	}

	const onChangeRegex = (newRegex) => {

		let valueObject = { text: value, regex: newRegex }

		setRegex(newRegex);

		if (value !== "") {

			props.onChange(id, { value: valueObject, visible: visible }, "value");

		} else {

			props.onClean(id, valueObject);

		}

	}



	return (

		<DecoratedInput id={id} label={label} unit={unit} invisible={invisible} selector={

			<ResultSelectorFunction
				id={id}
				visible={visible}
				onChange={onChangeVisible}
			></ResultSelectorFunction>

		}>


			<FlexboxGrid
				justify="space-between"
				align="middle"
				style={{ paddingLeft: "0rem" }}
			>

				<FlexboxGrid.Item colspan={20}>
					<Input
						onChange={onChangeValue}
						value={value}
					/>
				</FlexboxGrid.Item>


				<FlexboxGrid.Item colspan={3}>
					<Toggle
						size={"lg"}
						style={{ width: "95%" }}
						checkedChildren={"regex"}
						unCheckedChildren={"literal"}
						value={regex}
						onChange={onChangeRegex}
						checked={regex}
					/>
				</FlexboxGrid.Item>

			</FlexboxGrid>
		</DecoratedInput>
	);



}
