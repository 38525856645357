import axios from "axios";

import React, { useState, useEffect } from "react";
import { SelectPicker, InputPicker, IconButton, Stack, Divider } from "rsuite";
import Utils from "../../utils/Utils.js";
import { getPhrase, getCurrentLanguage } from "../../utils/Languages.js";
import { selectStyle } from "../../styles/Styles.js";

import { EraPicker } from "./EraPicker.js";
import { EraIcon } from "../../styles/Icon.js";

import { SEARCH_HINT_API } from "../../config/config.js";

export const RouteQuery = ({
  globalState,
  setGlobalState,

  focusMap,
  fitMap,
  planRoute,
  clearRoutes,
}) => {
  const [from, setFrom] = useState({});
  const [to, setTo] = useState({});

  const [viaPoints, setViaPoints] = useState([]);
  const [via, setVia] = useState({});

  const [vehicle, setVehicle] = useState({});

  // Check if query params are already provided from a permalink

  if (
    globalState.from.id &&
    globalState.from.id !== from.id &&
    globalState.to.id &&
    globalState.to.id !== to.id
  ) {
    setFrom(globalState.from);
    setTo(globalState.to);
  }

  if (
    globalState.via &&
    JSON.stringify(globalState.via) !== JSON.stringify(via)
  ) {
    const fields = [];
    const viaData = {};

    setVia(globalState.via);
  }

  if (
    globalState.compatibilityVehicleType.id &&
    globalState.compatibilityVehicleType.id !== vehicle.id
  ) {
    setVehicle(globalState.compatibilityVehicleType);
  }

  // Side effect function used to trigger map focus and routing process when all conditions are met
  useEffect(() => {
    if (from.id) {
      if (to.id) {
        // We have both FROM and TO, assemble via points (if any) and start route planning
        const viaPath = [];

        for (let key in via) {
          if (Object.keys(via[key]).length !== 0) {
            viaPath.push(via[key]);
          }
        }

        // Start route planning process
        planRoute(from, to, viaPath);

        if (from.lngLat && to.lngLat) {
          fitMap([from.lngLat, to.lngLat]);
        }
      } else {
        // We have only FROM, then zoom in to its location
        //focusMap(from.lngLat, [10]);
      }
    } else if (to.id) {
      // We have only TO, then zoom in to its location
      //focusMap(to.lngLat, [10]);
    }
  }, [from, to, via, vehicle]);

  const selectOperationalPoint = async (op, item, type, element) => {
    clearRoutes();

    if (type === "from" && !item.create) {
      setFrom({ id: item.value, lngLat: item.lngLat });
      setGlobalState({
        from: { id: item.value, lngLat: item.lngLat, label: item.label },
      });
    }

    if (type === "to" && !item.create) {
      setTo({ id: item.value, lngLat: item.lngLat });
      setGlobalState({
        to: { id: item.value, lngLat: item.lngLat, label: item.label },
      });
    }

    if (type === "via" && !item.create) {
      let newVia = via;
      newVia[element] = {
        id: item.value,
        lngLat: item.lngLat,
        label: item.label,
      };

      setVia((current) => {
        return { ...current, ...newVia };
      });

      setGlobalState((current) => {
        return { ...current, via: newVia };
      });
    }
  };

  const selectVehicleType = (op, item) => {
    if (!item.create) {
      clearRoutes();
      setVehicle({ id: item.value });
      setGlobalState({
        compatibilityVehicleType: { id: item.value, label: item.label },
      });
    }
  };

  const clear = (param) => {
    if (param === "from") {
      setFrom({});
      setGlobalState({ from: {} });
    }

    if (param === "to") {
      setTo({});
      setGlobalState({ to: {} });
    }

    if (param === "vehicle") {
      setVehicle({});
      setGlobalState({ compatibilityVehicleType: {} });
    }

    clearRoutes();
  };

  //// VIA POINT

  const addViaPoint = () => {
    const next = Object.keys(via).length;
    const element = `via-point-${next}`;

    let newVia = via;
    newVia[element] = {};

    setVia((current) => {
      return { ...current, ...newVia };
    });
    setGlobalState((current) => {
      return { ...current, via: newVia };
    });
  };

  const removeViaPoint = (element) => {
    let newVia = { ...via };
    delete newVia[element];

    setVia(newVia);

    setGlobalState((current) => {
      return { ...current, via: newVia };
    });

    clearRoutes();
  };

  useEffect(() => {
    //Render via points
    let elements = [];

    for (let element in via) {
      elements.push(
        <Stack.Item
          key={element}
          style={{ paddingLeft: "10px", width: "100%" }}
        >
          <EraPicker
            key={element}
            picker_type={"operational-points"}
            prefix={"via"}
            onSelect={(op, item) =>
              selectOperationalPoint(op, item, "via", element)
            }
            onClean={() => removeViaPoint(element)}
            selected={via[element]}
          ></EraPicker>
        </Stack.Item>
      );
    }

    setViaPoints(elements);
  }, [via]);

  return (
    <Stack
      alignItems={"flex-start"}
      direction={"column"}
      style={{ padding: "5px" }}
      spacing={"10px"}
    >
      <Stack.Item style={{ width: "100%", marginBottom: "10px" }} key={"from"}>
        <EraPicker
          picker_type={"operational-points"}
          prefix={"from"}
          onSelect={(op, item) => selectOperationalPoint(op, item, "from")}
          onClean={() => clear("from")}
          selected={from}
        ></EraPicker>
      </Stack.Item>

      {viaPoints}

      <Stack.Item key={"add-via"} style={{ marginLeft: "10px" }}>
        <IconButton
          style={{ margin: "0px" }}
          appearance="primary"
          size="sm"
          icon={<EraIcon faName="plus" style={null} />}
          placement="left"
          onClick={() => addViaPoint()}
        >
          Add via point
        </IconButton>
      </Stack.Item>

      <Stack.Item style={{ width: "100%", marginTop: "10px" }} key={"to"}>
        <EraPicker
          picker_type={"operational-points"}
          prefix={"to"}
          onSelect={(op, item) => selectOperationalPoint(op, item, "to")}
          onClean={() => clear("to")}
          selected={to}
        ></EraPicker>
      </Stack.Item>

      <Stack.Item style={{ width: "100%" }} key={"vehicle"}>
        <EraPicker
          picker_type={"vehicle-types"}
          prefix={"vehicleType"}
          onSelect={(op, item) => selectVehicleType(op, item)}
          onClean={() => clear("vehicle")}
          selected={vehicle}
        ></EraPicker>
      </Stack.Item>
    </Stack>
  );
};
