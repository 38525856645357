import React, { Component } from "react";


import { library, findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Icon } from "@rsuite/icons";

library.add(fas);


const FaSvgIcon = ({ faIcon, ...rest }) => {
		
	const [ width, height, , , svgPathData ] = faIcon.icon;

	return (
		<svg {...rest} viewBox={`0 0 ${width} ${height}`} /* width="100%" */ height="100%" fill="currentColor">
			<path d={svgPathData}></path>
		</svg>
	);
};

export const EraIcon = (props) => {
	

	const {
		faName, 
		color, 
		style = { margin: "5px" }, 
		pulse = false, 
		size = "1em",
		onClick,
		...rest
	} = props;

	// { color: color, margin: margin }

	const faIcon = findIconDefinition({ prefix: 'fas', iconName: faName })

	return (
		<Icon 
			as={FaSvgIcon} 
			faIcon={faIcon} 
			style={style} 
			pulse={pulse} 
			color={color} 
			width={size} 
			height={size}
			onClick={onClick}
			{...rest}
		/>
	);
		
};
