import { saveAs } from "file-saver";
import { some, xor } from 'lodash';

import {
	SPARQL_ENDPOINT,
} from "../../../config/config.js";

import WebworkerPromise from 'webworker-promise';

export class NTExport {

	constructor(query, formDefinition, skosValues, data, sparqlQuery) {

		this.sparqlQuery = sparqlQuery;

		//console.log(sparqlQuery);

		this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

	}

	async generateFile() {

		let result = await this.executor.exec("construct_query", { data: { endpoint: SPARQL_ENDPOINT, query: this.sparqlQuery["construct"], format: "application/n-triples" } });

		const blob = new Blob([result], { type: "application/n-triples;charset=utf-8" });

		let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)

		saveAs(blob, "search_results_" + date + ".nt");



	}

}




export class RDFExport {

	constructor(query, formDefinition, skosValues, data, sparqlQuery) {

		this.sparqlQuery = sparqlQuery;

		//console.log(sparqlQuery);

		this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

	}

	async generateFile() {

		let result = await this.executor.exec("construct_query", { data: { endpoint: SPARQL_ENDPOINT, query: this.sparqlQuery["construct"], format: "application/rdf+xml" } });

		const blob = new Blob([result], { type: "application/rdf+xml;charset=utf-8" });

		let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)

		saveAs(blob, "search_results_" + date + ".rdf");



	}

}


export class JSONLDExport {

	constructor(query, formDefinition, skosValues, data, sparqlQuery) {

		this.sparqlQuery = sparqlQuery;

		this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

	}

	async generateFile() {

		let result = await this.executor.exec("construct_query", { data: { endpoint: SPARQL_ENDPOINT, query: this.sparqlQuery["construct"], format: "application/ld+json" } });

		const blob = new Blob([JSON.stringify(result, null, 2)], { type: "application/ld+json;charset=utf-8" });

		let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)

		saveAs(blob, "search_results_" + date + ".jsonld");



	}

}

