import axios from "axios";

import {
	CHECKS_API
} from "../config/config.js";


export async function verifyCompatibilityNew(tracks, vehicle) {
	
	let report;
		
	// Filter for the moment just the URIs, can be cleaned before in the future
	
	tracks = tracks.map((track) => {return track.track});
	vehicle = vehicle.id;
		
	//console.log(tracks, vehicle);

	console.log("[API] "+CHECKS_API)

	let t0 = new Date()
	
	let response = await axios.post(CHECKS_API, {
			"track_list": tracks.join(","),
			"vehicle_uri": vehicle, });

	console.log('Compatibility report took (API)', new Date() - t0, 'ms');

	report = response.data.result;

    return report;
}
