import React, { useEffect, useState } from "react";
import { FlexboxGrid } from "rsuite";
import {
  DataPagination,
  DataTable,
  SearchBar,
  UploadDatasetModal,
  UploadDocumentsModal,
} from "./PanelComponents.js";

import dataAssetsService from "./DataAssetsService.js";

import { EraIcon } from "../../styles/Icon.js";

import { useMsal } from "@azure/msal-react";

export const DatasetsPanel = ({ setAlert, dataAssetType }) => {
  const [dataList, setDataList] = useState([]);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const pageSize = 50;
  const [searchFilter, setSearchFilter] = useState(null);
  const DATA_ASSET_TYPE_DATASET = "datasets";

  const { instance } = useMsal();

  useEffect(() => {
    setDataList([]);
    setFilteredDataList([]);
    setActivePage(1);
    fetchDataAssets(dataAssetType);
  }, [dataAssetType]);

  useEffect(() => {
    const syncTimer = setInterval(() => {
      syncDataAssets();
    }, 15000);

    return () => clearInterval(syncTimer);
  }, [dataList]);

  useEffect(() => {
    if (searchFilter != null && searchFilter.length > 0) {
      const filtered = dataList.filter((item) => {
        let matchFileName =
          item.fileName &&
          item.fileName.toLowerCase().includes(searchFilter.toLowerCase());
        let matchOrganization =
          item.owner?.organization?.name &&
          item.owner?.organization?.name
            .toLowerCase()
            .includes(searchFilter.toLowerCase());

        return matchFileName || matchOrganization;
      });

      setFilteredDataList(filtered);
    } else {
      setFilteredDataList(dataList);
    }
  }, [dataList, searchFilter]);

  function getDataPage(data, page) {
    const startIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    return data.slice(startIndex, endIndex);
  }

  function handleSearch(keyword) {
    setSearchFilter(keyword);
  }

  function fetchDataAssets(dataAssetType) {
    setIsLoading(true);
    dataAssetsService
      .fetchDataAssets(instance, dataAssetType)
      .then((response) => {
        //setFilteredDataList([]);
        setDataList(response.data);
        //setFilteredDataList(response.data);
      })
      .catch((error) => {
        //console.log(error);
        error.response
          ? setAlert("error", "Fetch error: " + error.response.data)
          : setAlert(
              "error",
              "An error occurred while fetching the files. Please, try again later."
            );
      })
      .finally(() => setIsLoading(false));
  }

  function syncDataAssets() {
    dataAssetsService
      .fetchDataAssets(instance, dataAssetType)
      .then((response) => {
        setDataList(response.data);
        //setFilteredDataList(response.data);
      })
      .catch((error) => {
        error.response
          ? setAlert("error", "Fetch error: " + error.response.data)
          : setAlert(
              "error",
              "An error occurred while fetching the files. Try again"
            );
      });
  }

  function uploadDataset(file, datasetFileType) {
    setAlert("success", "Uploading dataset file " + file.name + "...");

    //setIsLoading(true)
    dataAssetsService
      .uploadDataset(instance, file, datasetFileType)
      .then(() => {
        setAlert("success", "File " + file.name + " uploaded successfully");
        syncDataAssets(DATA_ASSET_TYPE_DATASET);
      })
      .catch((error) => {
        error.response
          ? setAlert("error", "Upload error: " + error.response.data)
          : setAlert(
              "error",
              "An error occurred while uploading the dataset file. Please, try again later."
            );
      })
      .finally //() => setIsLoading(false)
      ();
  }

  function updateDatasetStatus(id, status) {
    //setIsLoading(true)
    //console.log(id, status)

    dataAssetsService
      .updateDatasetStatus(instance, id, status)
      .then(() => {
        setAlert("success", "Dataset pipeline action queued successfully.");
        syncDataAssets(DATA_ASSET_TYPE_DATASET);
      })
      .catch((error) => {
        error.response
          ? setAlert("error", "Update error: " + error.response.data)
          : setAlert(
              "error",
              "An error occurred while requesting the pipeline action. Please, try again later."
            );
      })
      .finally
      //() => setIsLoading(false)
      ();
  }

  function deleteDataAssets(dataAssetType, id) {
    //setIsLoading(true)

    dataAssetsService
      .deleteDataAsset(instance, dataAssetType, id)
      .then(() => {
        setAlert("success", "Files deleted successfully");
        syncDataAssets(dataAssetType);
      })
      .catch((error) => {
        error.response
          ? setAlert("error", "Delete error: " + error.response.data)
          : setAlert(
              "error",
              "An error occurred while deleting the dataset file. Please, try again later."
            );
      })
      .finally
      //() => setIsLoading(false)
      ();
  }

  function downloadDataAsset(dataAssetType, id, s3Uri) {
    //WTF IS THIS
    dataAssetsService
      .downloadDataAsset(instance, dataAssetType, id, s3Uri)
      .then((response) => {
        const dispositionHeader = response.headers["content-disposition"];
        const link = document.createElement("a");
        link.href = response.data;
        link.download = extractFilenameFromHeader(dispositionHeader) || "file";
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        error.response
          ? setAlert("error", "Download error: " + error.response.data)
          : setAlert(
              "error",
              "An error occurred while downloading the file. Try again"
            );
      });

    function extractFilenameFromHeader(header) {
      if (header) {
        const matches = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (matches && matches.length > 1) {
          return matches[1].replace(/['"]/g, "").replace(/_+$/, ""); // Remove trailing underscores
        }
      }
      return null;
    }
  }

  function renameDataAsset(dataAssetTpe, id, newName) {
    dataAssetsService
      .renameDataAsset(instance, dataAssetTpe, id, newName)
      .then(() => {
        setAlert("success", "File renamed successfully");
        fetchDataAssets(dataAssetType);
      })
      .catch((error) => {
        error.response
          ? setAlert("error", "Rename error: " + error.response.data)
          : setAlert(
              "error",
              "An error occurred while renaming the file. Try again"
            );
      });
  }

  return (
    <>
      <FlexboxGrid style={{ margin: "25px 0px" }} justify="space-between">
        <FlexboxGrid.Item colspan={19}>
          <SearchBar onSearch={(keyword) => handleSearch(keyword)} />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={3}>
          <UploadDatasetModal dataList={dataList} onUpload={uploadDataset} />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DataTable
        dataList={getDataPage(filteredDataList, activePage)}
        isLoading={isLoading}
        dataType={dataAssetType}
        onDelete={deleteDataAssets}
        onDownload={downloadDataAsset}
        onRename={renameDataAsset}
        onSelectPipeline={updateDatasetStatus}
      />
      <DataPagination
        style={{ margin: "25px 0px" }}
        total={filteredDataList.length}
        activePage={activePage}
        limit={pageSize}
        onChangePage={setActivePage}
      />
    </>
  );
};
