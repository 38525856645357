

export class NumericResult {

	constructor(element) {

		//console.log("NumericResult:", element);

		this.element = element;

	}

	transformData = (data) => {

		try {

			let lang = navigator.language || "de-DE";

			let formatter = new Intl.NumberFormat(lang);

			let unit;

			for (let row of data.results.bindings) {

				if (this.element.input.definition.unit !== undefined) {

					unit = this.element.input.definition.unit.symbol;

				}

				if (row[this.element.id]) {

					let value = formatter.format(row[this.element.id].value);

					if (unit !== undefined) {

						row[this.element.id] = { type: "numeric", value: value + " " + unit };

					} else {

						row[this.element.id] = { type: "numeric", value: value };

					}

				}

			}

		} catch (e) {
			console.error(e);
		}

		return data;

	}

}
