import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  lazy,
  Suspense,
} from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Header,
  Content,
  Footer,
  Panel,
  Loader,
  Message,
  Tag,
  Timeline,
} from "rsuite";

import "rsuite/dist/rsuite.min.css";
import "./index.css";

import NavHeader from "./Header.js";
import NavFooter from "./Footer.js";

import {
  acquireToken,
  getUserInfo,
  getUserRoles,
} from "../../azure/MSGraphService.js";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import {
  ToastContainer,
  toast,
  Slide,
  Zoom,
  Flip,
  Bounce,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  NAMED_KG_RINF,
  NAMED_KG_ERATV,
  NAMED_KG_ERA_VOCABULARY,
  NAMED_KG_ERA_SKOS,
  NAMED_KG_ERA_SHACL,
  SPARQL_ENDPOINT,
  BASE_URI,
} from "../../config/config.js";

import WebworkerPromise from "webworker-promise";

const userInfoService = new WebworkerPromise(
  new Worker(new URL("./workers/UserInfo.worker.js", import.meta.url))
);

// Apps as components

const Landing = lazy(() => import("../Landing/Main.js"));

const Vocabulary = lazy(() => import("../Vocabulary/Main.js"));
const DataStories = lazy(() => import("../DataStories/Main.js"));
const Endpoint = lazy(() => import("../Endpoint/Main.js"));

const Search = lazy(() => import("../Search/Main.js"));
const RouteCompatibility = lazy(() => import("../RouteCompatibility/Main.js"));
const MapExplorer = lazy(() => import("../MapExplorer/Main.js"));

const Describe = lazy(() => import("../Describe/Main.js"));
const NotificationsManager = lazy(() =>
  import("../NotificationsManager/Main.js")
);
const DatasetManager = lazy(() => import("../DatasetManager/Main.js"));

// Azure provider

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {
  msalConfig,
  msGraphRequest,
  apiRequest,
} from "./../../azure/authConfig.js";
import { AuthenticationHandlerOptions } from "@microsoft/microsoft-graph-client";

const msalInstance = new PublicClientApplication(msalConfig);

// Other context providers

export const AuthContext = createContext(null);
export const TranslationContext = createContext(null);
export const ThemeContext = createContext(null);
export const LocationContext = createContext(null);
export const ToolsContext = createContext(null);

// App root component

function DebugAzure() {
  const { auth, setRole, auth_debug } = useContext(AuthContext);

  return (
    <Container>
      <h3>Azure debug data</h3>
      <pre>{JSON.stringify(auth, null, "  ")}</pre>
      <pre>{JSON.stringify(auth_debug, null, "  ")}</pre>
    </Container>
  );
}

function Root() {
  //console.log("Initializing app");

  // Webpack populated
  const bundle = ENV;

  console.groupCollapsed("Bundle information:");

  for (let v of Object.keys(bundle)) {
    console.info(v + ": " + bundle[v]);
  }

  console.groupEnd();

  console.groupCollapsed("Env information:");

  console.info("Named graphs: ", [
    NAMED_KG_RINF,
    NAMED_KG_ERATV,
    NAMED_KG_ERA_VOCABULARY,
    NAMED_KG_ERA_SKOS,
    NAMED_KG_ERA_SHACL,
  ]);

  console.info("SPARQL endpoint:", SPARQL_ENDPOINT);

  console.groupEnd();

  return (
    <MsalProvider instance={msalInstance}>
      <ContextsWrapper />
    </MsalProvider>
  );
}

// Context wrapper

function ContextsWrapper() {
  // Azure

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // Auth / role

  const [auth, setAuth] = useState(undefined);
  const [authReady, setAuthReady] = useState(false);
  const [authInfo, setAuthInfo] = useState(null);
  const [authTokens, setAuthTokens] = useState(null);
  const [authRoles, setAuthRoles] = useState(null);
  const [authActiveRoles, setAuthActiveRoles] = useState({
    role: localStorage.getItem("activeRole"),
    company: localStorage.getItem("activeIM"),
  });

  const [auth_debug, setAuthDebug] = useState();

  // Translation

  const [translation, setTranslation] = useState();

  // Theme

  const [theme, setTheme] = useState();

  // Tools

  const notify = (notification_type, notification_text) => {
    const method = {
      success: toast.success,
      info: toast.info,
      warn: toast.warn,
      error: toast.error,
    };

    console.log(notification_type, notification_text);

    if (method[notification_type] !== undefined) {
      method[notification_type](notification_text, {
        position: "top-center",
        autoClose: 10000,
        pauseOnFocusLoss: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
  };

  // Location

  const setRole = (role, company) => {
    if (role === "nre") {
      localStorage.setItem("activeIM", company);
    }
    localStorage.setItem("activeRole", role);

    let active = {
      role: role || null,
      company: company || null,
    };

    setAuthActiveRoles(active);
  };

  useEffect(() => {
    setAuthRoles({
      set: setRole,
      active: authActiveRoles,
      available: authRoles?.available,
      company: authRoles?.company,
    });
  }, [authActiveRoles]);

  useEffect(() => {
    let intervalId;

    const checkTokenValidity = async (init) => {
      if (inProgress == "none" && instance?.initialized === true) {
        let accounts = instance.getAllAccounts();
        if (isAuthenticated) {
          try {
            let userToken = await acquireToken(instance, msGraphRequest);
            let apiToken = await acquireToken(instance, apiRequest);

            if (init || !userToken.fromCache || !apiToken.fromCache) {
              //console.log("Updating tokens");
              setAuthTokens({ userToken: userToken, apiToken: apiToken });
            }
          } catch (e) {
            console.log("Expired auth");
            console.error(e);

            if (accounts.length > 0) {
              instance.logoutRedirect({
                onRedirectNavigate: (url) => {
                  return false;
                },
              });
            }

            setAuth(false);

            return;
          }
        }
      }

      intervalId = setTimeout(checkTokenValidity, 5 * 1000); // 5 minute
    };

    checkTokenValidity(true);

    return () => {
      clearTimeout(intervalId);
    };
  }, [auth]);

  const fetchUserInfo = async () => {
    let response = await userInfoService.exec("getUserInfo", {
      token: authTokens.userToken,
    });

    if (response?.srm?.roles && response?.srm?.info) {
      setAuthRoles({
        set: setRole,
        active: authActiveRoles,
        available: response?.srm?.roles,
        company: response?.srm?.company,
      });

      setAuthInfo({
        displayName: response?.srm?.info?.name,
        mail: response?.token?.info?.mail,
      });
    } else {
      // No data from auth backend
      notify(
        "error",
        "The account you logged in is not registered on RINF, you'll be redirected to logout and use a different one."
      );

      setTimeout(() => {
        instance
          .logoutRedirect({
            postLogoutRedirectUri: "/",
          })
          .then(() => {});
      }, 5 * 1000);

      setAuth(false);
      console.log("Wrong auth from SRM");
    }
  };

  useEffect(() => {
    if (auth && authTokens !== null) {
      fetchUserInfo();
    }
  }, [auth, authTokens]);

  // Auth status initialization
  const fetchAuthStatus = async () => {
    //console.log(inProgress);

    if (inProgress == "none" && instance?.initialized === true) {
      let accounts = instance.getAllAccounts();

      if (isAuthenticated) {
        //console.log("User auth");

        try {
          if (accounts.length > 0) {
            // We don't expect more than one

            await acquireToken(instance, msGraphRequest);

            if (
              localStorage.getItem("activeAccount") !==
              accounts[0].localAccountId
            ) {
              localStorage.setItem("activeAccount", accounts[0].localAccountId);
              setAuthActiveRoles({
                role: undefined,
                company: undefined,
              });
            }

            setAuth(true);
          } else {
            setAuth(false);
          }

          //setAuthDebug({});
        } catch (error) {
          console.log("Auth error: ", error);

          // Handle cleanup for some token expiration cases

          if (accounts.length > 0) {
            instance.logoutRedirect({
              onRedirectNavigate: (url) => {
                return false;
              },
            });
          }

          setAuth(false);
        }
      } else {
        setAuth(false);
      }
    }

    return auth;
  };

  useEffect(() => {
    fetchAuthStatus();
  }, [isAuthenticated, inProgress]);

  // Once all effects are done, we trigger the ready state change
  useEffect(() => {
    if (auth === true) {
      if (authInfo !== null && authRoles != null && authTokens !== null) {
        //console.log(authRoles);
        setAuthReady(true);
      } else {
        setAuthReady(false);
      }
    }
    if (auth === false) {
      setAuthReady(true);
    }
  }, [auth, authInfo, authRoles, authTokens]);

  // ¿?
  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.ACCOUNT_REMOVED) {
        const result = message.payload;
        console.log(result);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ auth, authReady, authInfo, authTokens, authRoles }}
    >
      <TranslationContext.Provider value={{ translation, setTranslation }}>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <ToolsContext.Provider value={{ notify }}>
            <Router>
              <App />
            </Router>
          </ToolsContext.Provider>
        </ThemeContext.Provider>
      </TranslationContext.Provider>
    </AuthContext.Provider>
  );
}

// App main elements

function LazyRoute(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <Route {...props} />
    </Suspense>
  );
}

function PageLoader() {
  return (
    <Panel
      style={{
        margin: "35vh 2rem 35vh 2rem",
        borderRadius: "0px",
        borderTop: "2px solid #bbb",
        borderBottom: "2px solid #bbb",
      }}
    >
      <Loader
        style={{ width: "200px", marginLeft: "calc(50% - 100px)" }}
        vertical
        content="Loading app..."
        size="md"
      />
    </Panel>
  );
}

function App() {
  const debugMode = localStorage.getItem("debugMode") === "1";
  const [ready, setReady] = useState(false);
  const [maintenance, setMaintenance] = useState();
  const { auth, authReady, authInfo, authTokens, authRoles } =
    useContext(AuthContext);
  const location = useLocation();
  const [maintenanceInfo, setMaintenanceInfo] = useState({});

  const componentPaths = {
    "/": Landing,
    "/data-stories": DataStories,
    "/vocabulary": Vocabulary,
    "/endpoint": Endpoint,
    "/describe": Describe,
    "/search": Search,
    "/map-explorer": MapExplorer,
    "/route-compatibility": RouteCompatibility,
    "/dataset-manager": DatasetManager,
    "/notifications-manager": NotificationsManager,
  };

  /*
  <Route exact path="/debug-azure" component={DebugAzure}></Route>
  <Route exact path="/test-component" component={TestComponent}></Route>
  */

  // There is a new endpoint that will return the next maintenance date and reason
  // For example {"current_time":"2024-09-04T11:10:46Z","next_maintenance_event":{"end_time":"2023-03-01T10:00:00Z","reason":"Server reboot","start_time":"2023-03-01T08:00:00Z"}}

  const apiEndpoint = BASE_URI + "/api/maintenance";

  const fetchMaintenanceStatus = async () => {
    try {
      const response = await fetch(apiEndpoint);
      if (!response.ok) {
        throw new Error("Network error");
      }
      const data = await response.json();

      //console.log(data)

      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  useEffect(() => {
    let intervalId;

    const checkMaintenanceStatus = async () => {
      const maintenanceData = await fetchMaintenanceStatus();

      if (
        maintenanceData &&
        maintenanceData.next_maintenance_event &&
        !debugMode
      ) {
        let lang = navigator.language || "de-DE";
        let formatter = new Intl.DateTimeFormat(lang, {
          dateStyle: "short",
          timeStyle: "long",
        });

        const startTime = new Date(
          maintenanceData.next_maintenance_event.start_time
        );
        const endTime = new Date(
          maintenanceData.next_maintenance_event.end_time
        );
        const currentTime = new Date(maintenanceData.current_time);

        setMaintenanceInfo({
          start_time: formatter.format(startTime),
          end_time: formatter.format(endTime),
          reason: maintenanceData.next_maintenance_event.reason,
        });

        if (currentTime >= startTime && currentTime <= endTime) {
          setMaintenance(true);
        } else {
          setMaintenance(false);
        }
      } else {
        setMaintenance(false);
      }

      intervalId = setTimeout(checkMaintenanceStatus, 5 * 60 * 1000); // 5 minute
    };

    checkMaintenanceStatus();

    return () => {
      clearTimeout(intervalId);
    };
  }, []);

  const prevMaintenance = useRef(maintenance);

  useEffect(() => {
    if (
      (maintenance === true && prevMaintenance.current === false) ||
      (maintenance === false && prevMaintenance.current === true)
    ) {
      window.location.reload();
    }
    prevMaintenance.current = maintenance;
  }, [maintenance]);

  useEffect(() => {
    if (authReady) {
      setReady(true);
    }
  }, [authReady]);

  return (
    <>
      <ToastContainer />
      {maintenance && (
        <Container>
          <Content style={{ height: "100vh" }}>
            <Panel style={{ margin: "calc(50vh - (300px/2)) 2rem" }}>
              <Message
                showIcon
                type="warning"
                header={<h5>Services are under maintenance</h5>}
              >
                <br />
                <p>
                  <b>Reason:</b> {maintenanceInfo.reason}
                </p>
                <br />
                <Timeline>
                  <Timeline.Item>
                    {maintenanceInfo.start_time}{" "}
                    <b>Maintenance planned start</b>
                  </Timeline.Item>
                  <Timeline.Item>
                    {maintenanceInfo.end_time} <b>Maintenance planned end</b>
                  </Timeline.Item>
                </Timeline>
                <br />
                <p>
                  <i>
                    This information is merely indicative, the time required may
                    be shorter or require extension.
                  </i>
                </p>
              </Message>
            </Panel>
          </Content>
        </Container>
      )}

      {ready && maintenance === false && (
        <Container>
          <Header>
            <NavHeader />
          </Header>
          <Content style={{ minHeight: "calc(100vh - 150px)" }}>
            <LocationContext.Provider value={{ location }}>
              <Switch>
                {Object.keys(componentPaths).map((path) => {
                  const Component = componentPaths[path];
                  return (
                    <LazyRoute
                      exact
                      key={path}
                      path={path}
                      component={Component}
                    />
                  );
                })}
              </Switch>
            </LocationContext.Provider>
          </Content>
          <Footer>
            <NavFooter />
          </Footer>
        </Container>
      )}

      {!ready && !maintenance && (
        <Container>
          <Content style={{ height: "100vh" }}>
            <PageLoader />
          </Content>
        </Container>
      )}
    </>
  );
}

export default Root;
