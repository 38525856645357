import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Message } from "rsuite";


export const MapDisclaimerModal = ({show, onClose}) => {
	
	const disclaimerText = (<>
			<p>The designations employed and the presentation of material on the map do not imply the expression of any opinion whatsoever on the part of the European Union concerning the legal status of any country, territory or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.</p>
			<p>Kosovo: This designation is without prejudice to positions on status, and is in line with UNSCR 1244/1999 and the ICJ Opinion on the Kosovo declaration of independence.</p>
			<p>Palestine: This designation shall not be construed as recognition of a State of Palestine and is without prejudice to the individual positions of the Member States on this issue.</p>
	</>);
	
	
	return (
	
		<Modal open={show} onClose={onClose} size={"lg"}>
			<Modal.Header>
				<Modal.Title><b>Disclaimer for map representations</b></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Message type="warning">
					{disclaimerText}
				</Message>
			</Modal.Body>
		</Modal>
		
	);
	
}

export const MapDisclaimer =({map, onClick}) => {
	
	const [data, setData] = useState();
	  
	let position = "bottom-right";
	
	let container;
	
	const onAdd = (map) => {
		
		container = document.createElement('b');
		container.className =  'mapboxgl-ctrl mapboxgl-ctrl-attrib';
		container.style = 'margin: 0px; cursor: pointer; color: #a81818';
		container.onclick = onClick;
		container.textContent = 'Disclaimer';
		
		return container;
	}
		

	const onRemove = (map) => {
	
		container.parentNode.removeChild(container);
	
	}
	
	//console.log(map);
	
	
	useEffect(() => {
		
		//console.log(map);
		
		if(map && map.current !== null){
			map.current.addControl({onAdd:onAdd, onRemove:onRemove}, position);
		}
	}, [map]);
	
	
	return null
	
}


const MapDisclaimerControl = ({map, onClick}) => {
	
	let container;
	
	const onAdd = (map) => {
		
		container = document.createElement('div');
		container.className =  'mapboxgl-ctrl';
		container.textContent = 'Disclaimer';

		
		return container;
	}
		

	const onRemove = (map) => {
	
		container.parentNode.removeChild(container);
	
	}
}
