import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
  useRef,
} from "react";

import { useLocation } from "react-router-dom";

import { openDB, deleteDB, wrap, unwrap } from "idb";

import {
  Container,
  Panel,
  Loader,
  Button,
  IconButton,
  Tooltip,
  Whisper,
  ButtonToolbar,
  ButtonGroup,
  Dropdown,
  Navbar,
  Nav,
  Input,
  InputGroup,
} from "rsuite";

import {
  SPARQL_ENDPOINT,
  SPARQL_ENDPOINT_PUB,
  NAMED_KG_RINF,
  NAMED_KG_ERATV,
  NAMED_KG_ERA_VOCABULARY,
  NAMED_KG_ERA_SKOS,
  NAMED_KG_ERA_SHACL,
} from "../../config/config.js";

const replacements = {
  "${{NAMED_KG_RINF}}": NAMED_KG_RINF,
  "${{NAMED_KG_ERATV}}": NAMED_KG_ERATV,
  "${{NAMED_KG_ERA_VOCABULARY}}": NAMED_KG_ERA_VOCABULARY,
  "${{NAMED_KG_ERA_SKOS}}": NAMED_KG_ERA_SKOS,
  "${{NAMED_KG_ERA_SHACL}}": NAMED_KG_ERA_SHACL,
};

import RDF from "./onto/owl.ttl";
import OWL from "./onto/rdf.ttl";
import RDFS from "./onto/rdfs.ttl";
import DCTERMS from "./onto/dcterms.ttl";
import SKOS from "./onto/skos.ttl";
import GEOSPARQL from "./onto/geosparql.ttl";
import WGS84 from "./onto/wgs84.ttl";
import QUDT from "./onto/qudt.ttl";

import { EraIcon } from "../../styles/Icon.js";

import WebworkerPromise from "webworker-promise";

import describe_query from "./queries/describe.sparql";
import describe_index_query from "./queries/describe_index.sparql";

import load_ontology_query from "./queries/load_ontology.sparql";
import load_country_SKOS_query from "./queries/load_SKOS_countries.sparql";

import { DescribeDetails } from "./DescribeDetails.js";

//const query_executor = new WebworkerPromise(new Worker(new URL("./workers/QueryExecuter.worker.js", import.meta.url)));
const store_executor = new WebworkerPromise(
  new Worker(new URL("./workers/DataStore.worker.js", import.meta.url))
);

import { LocationContext } from "./../App/Main.js";

export const StoreContext = createContext(null);
export const URIContext = createContext(null);

const Help = ({ text, children }) => {
  return (
    <Whisper
      trigger="hover"
      placement="auto"
      speaker={<Tooltip>{text}</Tooltip>}
    >
      {children}
    </Whisper>
  );
};

const SearchDetails = ({ uri, placeholder, onClick }) => {
  const [input, setInput] = useState("");

  useEffect(() => {
    if (input !== uri) {
      setInput(uri);
    }
  }, [uri]);

  const onChange = (value) => {
    setInput(value);
  };

  const onEnter = (key) => {
    if (key.charCode == 13) {
      onClick(input);
    }
  };

  const gotoTripleStore = () => {
    let triple_store = SPARQL_ENDPOINT_PUB.replace("/sparql", "/describe");

    let url = triple_store + "/?url=" + encodeURIComponent(uri);

    window.open(url, "_blank");
  };

  const copyURI = () => {
    navigator.clipboard.writeText(input);
  };

  return (
    <InputGroup style={{ margin: "15px 0px 25px 0px" }}>
      <InputGroup.Addon>Resource:</InputGroup.Addon>
      <Input
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onEnter}
        value={input}
      />

      <InputGroup.Button
        onClick={() => {
          onClick(input);
        }}
      >
        Search <EraIcon faName={"search"} style={{ marginLeft: "10px" }} />
      </InputGroup.Button>
      <Help text="Copy this URI to clipboard">
        <InputGroup.Button onClick={copyURI}>
          <EraIcon faName={"clipboard"} style={null} />
        </InputGroup.Button>
      </Help>
      <Help text="Open this URI in the Triple Store interface">
        <InputGroup.Button onClick={gotoTripleStore}>
          <EraIcon faName={"right-from-bracket"} style={null} />
        </InputGroup.Button>
      </Help>
    </InputGroup>
  );
};

export const Describe = (props) => {
  const { location } = useContext(LocationContext);

  const [data, setData] = useState();

  const [dataReady, setDataReady] = useState(false);
  const [metadataReady, setMetadataReady] = useState(false);

  const [uri, setUri] = useState(
    decodeURIComponent(location.hash.replace("#", ""))
  );
  const [validURI, setValidURI] = useState();

  useEffect(() => {
    requestMetadata();
  }, []);

  useEffect(() => {
    setUri(decodeURIComponent(location.hash.replace("#", "")));
    //console.log(location.hash);
  }, [location]);

  useEffect(() => {
    setDataReady(false);
    requestData(uri);
  }, [uri]);

  const requestMetadata = useCallback(async () => {
    const externalOnto = [
      RDF,
      OWL,
      RDFS,
      DCTERMS,
      SKOS,
      GEOSPARQL,
      WGS84,
      QUDT,
    ];

    try {
      for (let onto of externalOnto) {
        //console.log(onto);
        await store_executor.exec("load_by_data", {
          data: { blob: onto, format: "ttl", destination: "metadata" },
        });
      }
    } catch (e) {
      //console.log(e);
    }

    try {
      await store_executor.exec("load_by_query", {
        data: {
          endpoint: SPARQL_ENDPOINT,
          query: load_country_SKOS_query,
          destination: "metadata",
        },
      });
    } catch (e) {
      //console.log("Error loading contry SKOS values");
      //console.log(e);
    }

    try {
      let query = load_ontology_query
        .replaceAll("${{NAMED_KG_ERA_VOCABULARY}}", NAMED_KG_ERA_VOCABULARY)
        .replaceAll("${{NAMED_KG_ERA_SKOS}}", NAMED_KG_ERA_SKOS);

      await store_executor.exec("load_by_query", {
        data: {
          endpoint: SPARQL_ENDPOINT,
          query: query,
          destination: "metadata",
        },
      });
    } catch (e) {
      //console.log("Error loading ontology and RINF SKOS");
      //console.log(e);
    }

    setMetadataReady(true);
  }, []);

  const requestData = useCallback(async (uri) => {
    if (
      !(
        uri.startsWith("https://") ||
        uri.startsWith("http://") ||
        uri.startsWith("nodeID://")
      )
    ) {
      setValidURI(false);

      setDataReady(true);

      return;
    } else {
      setValidURI(true);

      let clear_results = await store_executor.exec("clear_graph", {
        data: { destination: "data" },
      });

      let query = describe_query.replaceAll("${{URI}}", uri);

      for (let replacement of Object.keys(replacements)) {
        query = query.replaceAll(replacement, replacements[replacement]);
      }

      //console.log(query);

      let results = await store_executor.exec("load_by_query", {
        data: { endpoint: SPARQL_ENDPOINT, query: query, destination: "data" },
      });

      //console.log("Load result: ", results);

      let query_index = describe_index_query.replaceAll("${{URI}}", uri);

      for (let replacement of Object.keys(replacements)) {
        query_index = query_index.replaceAll(
          replacement,
          replacements[replacement]
        );
      }

      //console.log(query_index);

      results = await store_executor.exec("load_by_query", {
        data: {
          endpoint: SPARQL_ENDPOINT,
          query: query_index,
          destination: "data",
        },
      });

      //console.log("Load result: ", results);

      setData(results);

      setDataReady(true);
    }
  }, []);

  const gotoURI = (uri, other) => {
    //console.log(uri, other)

    window.location.href =
      window.location.href.split("#")[0] + "#" + encodeURIComponent(uri);
  };

  return (
    <StoreContext.Provider value={{ store_executor }}>
      <URIContext.Provider value={{ uri }}>
        {!metadataReady && (
          <Container style={{ height: "calc(100vh - 144px)" }}>
            <Panel
              style={{
                margin: "35vh 2rem 35vh 2rem",
                borderRadius: "0px",
                borderTop: "2px solid #bbb",
                borderBottom: "2px solid #bbb",
              }}
            >
              <Loader
                style={{ width: "250px", marginLeft: "calc(50% - 125px)" }}
                vertical
                content="Loading information..."
                size="md"
              />
            </Panel>
          </Container>
        )}

        {metadataReady && (
          <Container style={{ padding: "50px" }}>
            <SearchDetails
              uri={uri}
              placeholder={"Input a resource URI to start"}
              onClick={gotoURI}
            />

            {dataReady && validURI && (
              <DescribeDetails uri={uri} store_executor={store_executor} />
            )}
          </Container>
        )}
      </URIContext.Provider>
    </StoreContext.Provider>
  );
};
