

export class TextQuery {

	constructor(element, value) {

		//console.log("TextQuery:", element, value);

		this.element = element;

		this.value = value;

	}

	build_filter = () => {

		let filter = [];

		if (this.value.regex === true) {

			filter.push(`FILTER(regex(?${this.element.id}, "${this.value.text.replace('"', '\"')}", "ix" ))`);

		} else {

			filter.push(`FILTER(lcase(str(?${this.element.id})) = lcase("${this.value.text.replace('"', '\"')}"))`);

		}

		return filter;

	}

	build_extra_patterns = () => {

		let patterns = [];

		return patterns;

	}

	build_extra_vars = () => {

		let vars = [];

		return vars;

	}

}
