import React from "react";

import {
	DatePicker
} from "rsuite";

import { DecoratedInput } from "./DecoratedInput.js";

import { ResultSelectorFunction } from "./ResultSelectorFunction.js";

import { AbstractInput } from "./AbstractInput.js";


export class DateInput extends AbstractInput {

	constructor(props) {

		super(props);

		this.emptyValue = null;

		//console.log("Date input:", props);

	}

	setValueDate = (value) => {

		if (value === null) {

			this.cleanup(this.emptyValue);

		} else {

			this.setValue(value);

		}

	}


	render() {

		return (

			<DecoratedInput id={this.id} label={this.label} selector={

				<ResultSelectorFunction
					id={this.id}
					visible={this.state.visible}
					onChange={this.setVisible}
				></ResultSelectorFunction>
			}>

				<DatePicker
					block
					onChange={this.setValueDate}
					format={"dd/MM/yyyy"}
					placement={"bottomEnd"}
					placeholder={"Date as dd/mm/yyyy"}
					oneTap
					value={this.state.value ? new Date(this.state.value) : null}
				/>
			</DecoratedInput>
		);

	}

}
