import React, { Component, Fragment } from "react";
import {
  Panel,
  Steps,
  Loader,
  Button,
  Modal,
  Tag,
  TagGroup,
  Table,
  Tooltip,
  Whisper,
  Message,
} from "rsuite";
import Utils from "../../utils/Utils.js";
import { ERA, RDFS, SKOS } from "../../utils/NameSpaces.js";
import { getPhrase } from "../../utils/Languages.js";
import { assembleRoute2 } from "../../algorithm/RouteAssembler.js";

import { details } from "../../algorithm/RestAPI.js";

import { stepStyle, stepItemStyle, stepstyle } from "../../styles/Styles.js";

import { EraIcon } from "../../styles/Icon.js";

import "../../styles/Override.css";

const { Column, HeaderCell, Cell } = Table;

const TransformURI = (uri) => {
  if (uri.startsWith("http://data.europa.eu/949/")) {
    return "/describe#" + encodeURIComponent(uri);
  } else {
    return uri;
  }
};

const DetailCellParameter = ({ rowData, dataKey, ...props }) => {
  let element = rowData[dataKey];

  let cell_content;

  //console.log(element);

  if (typeof element === "object" && "uri" in element) {
    cell_content = (
      <a href={TransformURI(rowData[dataKey]["uri"])}>
        {rowData[dataKey]["value"]}
      </a>
    );
  } else {
    cell_content = rowData[dataKey];
  }

  return (
    <Cell {...props} key={dataKey + "-" + rowData["name"]}>
      {cell_content}
    </Cell>
  );
};

const DetailCell = ({ rowData, dataKey, ...props }) => {
  let cell_content;

  if (Array.isArray(rowData[dataKey])) {
    if (rowData[dataKey].length > 1) {
      let cell_elements = [];

      for (let element of rowData[dataKey]) {
        if (typeof element === "object" && "uri" in element) {
          cell_elements.push(
            <li key={element["uri"]}>
              <a href={TransformURI(element["uri"])}>{element["value"]}</a>
            </li>
          );
        } else {
          cell_elements.push(<li key={element}>{element}</li>);
        }
      }

      cell_content = <ol>{cell_elements}</ol>;
    } else {
      if (rowData[dataKey].length == 1) {
        let element = rowData[dataKey][0];

        if (typeof element === "object" && "uri" in element) {
          cell_content = (
            <a href={TransformURI(element["uri"])}>{element["value"]}</a>
          );
        } else {
          cell_content = element;
        }
      } else {
        cell_content = <i style={{ color: "#999" }}> - </i>;
      }
    }
  } else {
    cell_content = (
      <a href={TransformURI(rowData[dataKey])}>{rowData[dataKey]}</a>
    );
  }

  return (
    <Cell {...props} key={dataKey + "-" + rowData["name"]}>
      {cell_content}
    </Cell>
  );
};

const RCCCell = ({ rowData, dataKey, ...props }) => {
  let cell_content = rowData[dataKey];

  let cell_color, match;

  switch (rowData["result"]) {
    case "http://data.europa.eu/949/checkResult/exactMatch":
      match = "Compatible";
      cell_color = "rgba(50, 150, 50, 0.2)";
      break;
    case "http://data.europa.eu/949/checkResult/closeMatch":
      match = "Compatible (need manual check)";
      cell_color = "rgba(100, 150, 50, 0.2)";
      break;
    case "http://data.europa.eu/949/checkResult/noMatch":
      match = "Not compatible";
      cell_color = "rgba(150, 50, 50, 0.2)";
      break;
    default:
      match = "Unable to check";
      cell_color = "rgba(150, 150, 150, 0.2)";
      break;
  }

  //console.log(rowData, rowData["result"], cell_color);

  if (dataKey == "name") {
    cell_content = (
      <Whisper
        placement="top"
        trigger="hover"
        delay="500"
        speaker={<Tooltip>{match}</Tooltip>}
      >
        <b>{cell_content}</b>
      </Whisper>
    );
  }

  return (
    <Cell
      {...props}
      key={dataKey + "-" + rowData["name"]}
      style={{ backgroundColor: cell_color }}
    >
      {cell_content}
    </Cell>
  );
};

const ParametersModal = ({ track, vehicle, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const [loaded, setLoaded] = React.useState(false);

  const [table_data, setTableData] = React.useState([]);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = async () => {
    setOpen(true);

    let results = await details(track, vehicle);

    setTableData(results);

    setLoaded(true);
  };

  return (
    <>
      {open && (
        <Modal size={"lg"} open={open} onClose={onClose}>
          <Modal.Header>
            <Modal.Title>Detailed parameters for track and vehicle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loaded ? (
              <Table
                data={table_data}
                wordWrap={true}
                affixHeader
                height={window.innerHeight - 200}
              >
                <Column
                  key={"name"}
                  align={"center"}
                  verticalAlign={"middle"}
                  flexGrow={1}
                >
                  <HeaderCell>Ontology property</HeaderCell>
                  <DetailCellParameter dataKey="parameter" />
                </Column>

                <Column
                  key={"track"}
                  align={"center"}
                  verticalAlign={"middle"}
                  flexGrow={1}
                >
                  <HeaderCell>Values for track</HeaderCell>
                  <DetailCell dataKey="track_values" />
                </Column>

                <Column
                  key={"vehicle"}
                  align={"center"}
                  verticalAlign={"middle"}
                  flexGrow={1}
                >
                  <HeaderCell>Values for vehicle</HeaderCell>
                  <DetailCell dataKey="vehicle_values" />
                </Column>
              </Table>
            ) : (
              <div style={{ margin: "50px" }}>
                <Loader center vertical content="Loading data..." size="md" />
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}

      <Button onClick={onOpen}>View all parameters</Button>
    </>
  );
};

const DetailsPanel = ({ data, ...props }) => {
  const table_data = data;

  const [expanded, setExpanded] = React.useState(false);

  const onExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Panel header={"Details"} collapsible bordered onSelect={onExpand}>
      {expanded && (
        <Table data={table_data} wordWrap={true} autoHeight width={600}>
          <Column
            key={"name"}
            align={"center"}
            width={200}
            verticalAlign={"middle"}
          >
            <HeaderCell>Check name</HeaderCell>
            <RCCCell dataKey="name" />
          </Column>

          <Column
            key={"track"}
            align={"center"}
            width={200}
            verticalAlign={"middle"}
          >
            <HeaderCell>Track</HeaderCell>
            <RCCCell dataKey="track" />
          </Column>

          <Column
            key={"vehicle"}
            align={"center"}
            width={200}
            verticalAlign={"middle"}
          >
            <HeaderCell>Vehicle</HeaderCell>
            <RCCCell dataKey="vehicle" />
          </Column>
        </Table>
      )}
    </Panel>
  );
};

export class RoutesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: null,
    };
  }

  getRouteHeader = (route, index) => {
    let style_track = {
      backgroundColor: "rgba(250,200,250,0.4)",
      color: "black",
      border: "0px",
      float: "right",
      margin: "0px 5px",
    };

    let style_length = {
      backgroundColor: "rgba(200,200,200,0.4)",
      color: "black",
      border: "0px",
      float: "right",
      margin: "0px 5px",
    };

    return (
      <>
        <b>Calculated route</b>
        <Tag
          key={"route-tracks"}
          style={style_track}
        >{`${route.tracks.length} tracks`}</Tag>
        <Tag key={"route-length"} style={style_length}>{`${
          route.length / 1000
        } km`}</Tag>
      </>
    );
  };

  getOperationalPointTitle = (op, internal, route) => {
    return (
      <span>
        <a
          href={TransformURI(op.op)}
          target={"_blank"}
          style={{ color: "#000" }}
        >
          <p>{op.implementation.label}</p>
        </a>
      </span>
    );
  };

  formatValues = (values, col) => {
    let ret = [];

    let style_skos = {
      backgroundColor: "rgba(200,200,200,0.3)",
      color: "black",
      border: "1px solid rgba(100,100,100,0.5)",
      boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
    };

    let style_numeric = {
      backgroundColor: "rgba(100,100,200,0.3)",
      color: "black",
      border: "1px dotted rgba(100,100,100,0.5)",
      boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
    };

    let style_boolean = {
      backgroundColor: "rgba(200,200,200,0.3)",
      color: "black",
      border: "0px",
    };

    let style_text = {
      backgroundColor: "rgba(250,250,250,0.4)",
      color: "black",
      border: "0px",
    };

    let style_na_nya = {
      backgroundColor: "rgba(150,100,100,0.3)",
      color: "black",
      border: "1px solid rgba(100,100,100,0.5)",
      boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
    };

    //data.europa.eu/949/notYetAvailable

    http: if (values) {
      let i = 0;

      for (let value of values || []) {
        if (value.type == "skos") {
          if (
            value.uri == "http://data.europa.eu/949/notYetAvailable" ||
            value.uri == "http://data.europa.eu/949/notApplicable"
          ) {
            ret.push(
              <Tag key={value.label + "-" + col + i} style={style_na_nya}>
                <a href={TransformURI(value.uri)}>{value.label}</a>
              </Tag>
            );
          } else {
            ret.push(
              <Tag key={value.label + "-" + col + i} style={style_skos}>
                <a href={TransformURI(value.uri)}>{value.label}</a>
              </Tag>
            );
          }
        }
        if (value.type == "boolean") {
          ret.push(
            <Tag key={value.label + "-" + col + i} style={style_boolean}>
              {value.value}
            </Tag>
          );
        }
        if (value.type == "numeric") {
          ret.push(
            <Tag key={value.value + "-" + col + i} style={style_numeric}>
              {value.value}
            </Tag>
          );
        }
        if (value.type == "text") {
          ret.push(
            <Tag key={value.value + "-" + col + i} style={style_text}>
              {value.value}
            </Tag>
          );
        }

        i++;
      }

      return <TagGroup style={{ padding: "25px" }}>{ret}</TagGroup>;
    } else {
      /*return (<Whisper placement="top" trigger="hover" delay="500" speaker={
						<Tooltip>Some data is missing and the automated check can not be performed</Tooltip>}
					><i>No data</i></Whisper>);*/

      return <i>No data</i>;
    }
  };

  getTrackDescription = (track, reps) => {
    let style_track = {
      backgroundColor: "rgba(250,200,250,0.1)",
      color: "black",
      border: "0px",
    };

    let style_length = {
      backgroundColor: "rgba(200,200,200,0.4)",
      color: "black",
      border: "0px",
    };

    if (track.implementation.label === "VLINK") {
      return (
        <span>
          <div>
            Track:{" "}
            <Tag style={style_track}>
              <a href={TransformURI(track.track)} target={"_blank"}>
                {track.implementation.label}
              </a>
            </Tag>
          </div>
          <br />
          <div>
            Track length:{" "}
            <Tag style={style_track}>{`${track.length / 1000} km`}</Tag>
          </div>
          <br />
          <Message showIcon type="warning" header="">
            This kind of track is VLINK type.
          </Message>
        </span>
      );
    }

    if (!reps) {
      return (
        <span>
          <div>
            Track:{" "}
            <Tag style={style_track}>
              <a href={TransformURI(track.track)} target={"_blank"}>
                {track.implementation.label}
              </a>
            </Tag>
          </div>
          <br />
          <div>
            Track length:{" "}
            <Tag style={style_track}>{`${track.length / 1000} km`}</Tag>
          </div>
        </span>
      );
    } else {
      let table_data = [];

      let check_summary = {
        "http://data.europa.eu/949/checkResult/exactMatch": 0,
        "http://data.europa.eu/949/checkResult/closeMatch": 0,
        "http://data.europa.eu/949/checkResult/noMatch": 0,
        "http://data.europa.eu/949/checkResult/unableToCheck": 0,
      };

      for (let check of Object.keys(reps)) {
        check_summary[reps[check].check_value] += 1;

        table_data.push({
          name: reps[check].name,
          track: this.formatValues(reps[check].rinf, check + track.track),
          vehicle: this.formatValues(reps[check].eratv, check + track.track),
          result: reps[check].check_value,
        });
      }

      let style_vehicle = {
        backgroundColor: "rgba(250,250,200,0.4)",
        color: "black",
        border: "0px",
      };

      let style_checks = {
        backgroundColor: "rgba(200,200,200,0.2)",
        color: "black",
        border: "1px solid",
      };

      //console.log(check_summary);

      return (
        <>
          <div>
            Track:{" "}
            <Tag style={style_track}>
              <a href={TransformURI(track.track)} target={"_blank"}>
                {track.implementation.label}
              </a>
            </Tag>
          </div>
          <div>
            Track length:{" "}
            <Tag style={style_track}>{`${track.length / 1000} km`}</Tag>
          </div>
          <br />
          <div>
            Vehicle:{" "}
            <Tag color={style_vehicle}>
              <a
                href={TransformURI(this.props.compatibilityVehicleType.id)}
                target={"_blank"}
              >
                {this.props.compatibilityVehicleType.label}
              </a>
            </Tag>
          </div>
          <br />
          <div>
            Summary:{" "}
            <Tag style={style_checks}>
              {"Compatible: " +
                check_summary[
                  "http://data.europa.eu/949/checkResult/exactMatch"
                ]}
            </Tag>
            <Tag style={style_checks}>
              {"Need manual check: " +
                check_summary[
                  "http://data.europa.eu/949/checkResult/closeMatch"
                ]}
            </Tag>
            <Tag style={style_checks}>
              {"Not compatible: " +
                check_summary["http://data.europa.eu/949/checkResult/noMatch"]}
            </Tag>
            <Tag style={style_checks}>
              {"Unknown: " +
                check_summary[
                  "http://data.europa.eu/949/checkResult/unableToCheck"
                ]}
            </Tag>
          </div>

          <br />
          <DetailsPanel data={table_data}></DetailsPanel>
          <br />
          <ParametersModal
            track={track.track}
            vehicle={this.props.compatibilityVehicleType.id}
          ></ParametersModal>
        </>
      );
    }
  };

  async componentDidUpdate(prevProps) {
    const { routes } = this.props;
    const newRoutes =
      JSON.stringify(prevProps.routes) !== JSON.stringify(routes);
    const newVehicle =
      prevProps.compatibilityVehicleType !==
      this.props.compatibilityVehicleType;
    const newLanguage = prevProps.language !== this.props.language;

    if (newRoutes || newVehicle || newLanguage) {
      if (routes.length > 0) {
        const fromLoc = this.props.from.lngLat;
        const toLoc = this.props.to.lngLat;

        for (const [i, r] of routes.entries()) {
          let routeStructure = null;
          let t0 = new Date();

          //console.log("Route", r)

          routeStructure = assembleRoute2(r); //Pending cleanup

          const { steps, tracks, length, locations } = routeStructure;

          // Visualize route OPs
          //this.props.updateOPLocations(locations);

          // Register path properties
          r.length = length;
          r.steps = steps;
          r.tracks = tracks;
          r.report = [];

          //console.log("Route populated", r)

          this.props.setLoaderMessage(
            "Performing route compatibility check for " +
              r.tracks.length +
              " tracks..."
          );

          // Perform route compatibility if a vehicle type has been selected

          if (this.props.compatibilityVehicleType.id) {
            t0 = new Date();

            r.report = await this.props.checkCompatibility(tracks);
          }

          //console.log(r.report);

          t0 = new Date();

          this.props.persistCompatibilityReports(r);

          let route_steps = (
            <Steps
              id={"route_steps"}
              current={0}
              vertical
              style={{ padding: "15px" }}
            >
              {Array.from(steps.values()).map((op, i) => (
                <Steps.Item
                  style={{ stepItemStyle }}
                  key={`step-${i}`}
                  id={`step-${i}`}
                  status={"process"}
                  title={this.getOperationalPointTitle(op, false, r)}
                  description={
                    i < steps.size - 1
                      ? this.getTrackDescription(
                          tracks[i],
                          r.report[tracks[i].track]
                        )
                      : null
                  }
                ></Steps.Item>
              ))}
            </Steps>
          );

          //console.log("Rendering report done in ",  new Date() - t0, 'ms')

          this.setState({ steps: route_steps });
        }

        this.props.setGlobalState({ showReport: true });
      }

      // Display route info steps

      this.props.setLoaderMessage(null);
    }
  }

  render() {
    const { steps } = this.state;
    const { visibility } = this.props;

    //console.log("Report visibility", visibility);

    if (visibility) {
      return steps;
    } else {
      return null;
    }
  }
}
