import React, {useEffect, useState} from "react";
import moment from 'moment';
import {
    Button,
    Uploader,
    SelectPicker,
    Steps,
    Form, IconButton,
    Input,
    InputGroup,
    Modal,
    Pagination, Popover, Radio,
    RadioGroup,
    Table, Whisper,
    Dropdown, Tooltip,
    Tag, Container, Panel, List, ButtonToolbar, Progress,
    Message
} from "rsuite";
import {Icon, More, Trash, Edit, Import} from "@rsuite/icons";
import {DatasetFileStatus, DatasetFileType, DatasetPipelines} from "../DatasetConstants.js";
import dateFormat from "dateformat";
const { Header, Title, Body, Footer } = Modal;
const { Column, HeaderCell, Cell, } = Table;
import { saveAs } from "file-saver";


import { EraIcon } from "../../../styles/Icon.js";



export const UploadDocumentsModal = ({dataList, onUpload}) => {
    const [showModal, setShowModal] = useState(false)
    const [showDuplicatedModal, setShowDuplicatedModal] = useState(false)
    const [datasetFileType, setDatasetFileType] = useState(DatasetFileType.XmlFull)
    const [file, setFile] = useState();
    const [uploadDisabled, setUploadDisabled] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [acceptedExtensions, setAcceptedExtensions] = useState();
    

    const [fileList, setFileList] = useState([]);

    function handleOnChangeFile (files) {
        
        //console.log(files);
        
        if (files.length != 0){
			setFileList(files);
			setSubmitDisabled(false);
		}else{
			setFileList([]);
			setSubmitDisabled(true);
		}
        
        
    }
    
    const onCleanup = () => {
		
		setShowModal(false);
		setFileList([]);
		setUploadDisabled(true);
		setSubmitDisabled(true);
		
	}

    function handleClickSubmit (){
        

		let fileBlobs = fileList.map((x) => {return x.blobFile});
		
		//console.log(fileList, fileBlobs);

        onUpload(fileBlobs);
        onCleanup();
            
        
    }

    const handleClickSubmitDuplicated = () => {
        onUpload(fileList)
        setShowDuplicatedModal(false)
        onCleanup()
    }

    const handleClickCancel = () => {
        setShowModal(false)
        onCleanup()
    }

    
    

    const AlertUploadDuplicated = ({fileName, onSubmit, showModal, setShowModal}) => {
        function handleSubmit() {
            onSubmit()
            setShowModal(false)
        }

        return(
            <>
                <Modal open={showModal}>
                    <Header>
                        <Title>Duplicated File</Title>
                    </Header>
                    <Body>
                        A file with the same name <b>"{fileName}"</b> already exists.
                        Do you want to upload it anyway?
                    </Body>
                    <Footer>
                        
                        <Button onClick={() => setShowModal(false)} appearance="subtle">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} appearance="primary">
                            Confirm
                        </Button>
                    </Footer>
                </Modal>
            </>
        )
    }

    return(
        <>
            <AlertUploadDuplicated fileName={file?.name} showModal={showDuplicatedModal}
                                   setShowModal={setShowDuplicatedModal}
                                   onSubmit={handleClickSubmitDuplicated}
            />

            <IconButton 
				icon={<EraIcon faName={"upload"} style={null} />}
				appearance="primary" 
				color={"green"} 
				onClick={() => setShowModal(true)}
				block
			>
                Upload
            </IconButton>
            
            
            {setShowModal && (
				<Modal open={showModal} onClose={handleClickCancel}>
					<Header>
						<Title>Upload files</Title>
					</Header>
					<Body>
						

						<Message showIcon type="info" style={{"marginTop": "15px"}}>Reference files can be provided one by one or aggregated in a ZIP file. The system will decompress and treat each one as a separate document.</Message>

							
						 <Uploader fileList={fileList} accept={"*/*"} action={""} style={{marginTop: "25px"}} onChange={handleOnChangeFile} autoUpload={false}>
							<IconButton icon={<EraIcon faName={"paperclip"} style={null} />}>Select documents...</IconButton>
						 </Uploader>

						
					</Body>
					<Footer>
						<Button onClick={handleClickCancel} appearance="subtle">
							Cancel
						</Button>
						<IconButton icon={<EraIcon faName={"upload"} style={null} />} disabled={submitDisabled} onClick={handleClickSubmit} appearance="primary" color={"green"}>
							Upload documents
						</IconButton>
					</Footer>
				</Modal>
			)}
        </>
    )
}
