
const prefixes = {

	"http://data.europa.eu/949/functionalInfrastructure/operationalPoints/": "op:",
	"http://data.europa.eu/949/functionalInfrastructure/sectionsOfLine/": "sol:",
	"http://data.europa.eu/949/functionalInfrastructure/tracks/": "track:",
	"http://data.europa.eu/949/funtionalInfrastructure/tunnels/": "tunnel:"
}

export class TextResult {

	constructor(element) {

		//console.log("NumericResult:", element);

		this.element = element;

	}

	transformData = (data) => {

		try {

			let lang = navigator.language || "de-DE";

			for (let row of data.results.bindings) {

				//console.log(row);

				if (row[this.element.id]) {

					if (row[this.element.id]?.type === "uri") {

						let value = row[this.element.id].value;

						let label = value;

						for (let prefix of Object.keys(prefixes)) {

							label = label.replace(prefix, prefixes[prefix]);

						}

						row[this.element.id] = { type: "uri", label: label, value: value };

					}

				}

			}

		} catch (e) {
			console.error(e);
		}

		return data;

	}

}
