import React, { useEffect } from "react";

import ReactMapboxGl, {
	ZoomControl,
	Marker,
	Layer,
	Feature
} from "react-mapbox-gl";

import {
	Modal,
	FlexboxGrid,
	InputNumber,
	IconButton,
	Button
} from "rsuite";

import {
	marker
} from '../../../styles/Styles.js';

import {
	EraIcon
} from "../../../styles/Icon.js";

import { DecoratedInput } from "./DecoratedInput.js";

import { ResultSelectorFunction } from "./ResultSelectorFunction.js";

import { AbstractInput } from "./AbstractInput.js";



const MapBox = ReactMapboxGl({
	accessToken:
		"pk.eyJ1Ijoic3VzaGlsZ2hhbWJpciIsImEiOiJjazUyZmNvcWExM2ZrM2VwN2I5amVkYnF5In0.76xcCe3feYPHsDo8eXAguw"
});

export class MapInput extends AbstractInput {

	constructor(props) {

		super(props);

		//console.log("Map input:", props, this.state);

		this.zoom = [5];

		this.lineStyle = {
			"line-color": ['get', 'color'],
			"line-width": 2,
			"line-dasharray": [1, 1]
		}

		this.mapOpened = false;

		if (this.state.value === null) {

			this.state.value = { type: "box", lngLat: [] }; // 2 points

		} else {

			this.center = this.state.value.lngLat;

		}

		this.state.center = [4.360854, 50.859658];

		this.state.mapFeatures = { box: [], markers: [] };

	}

	// Can be exact or range


	toggleModal = () => {

		let mapOpen = !this.state.mapOpen;

		this.setState({ mapOpen: mapOpen });

		if (this.state.mapFeatures.markers.length == 1) {

			this.state.value.lngLat = [];

			this.cleanup(this.state.value);

			this.updateMap();

		}

	}

	updateMap = () => {


		let markers = [];
		let lines = [];

		let oldState = this.state.mapFeatures;


		for (let lngLat of this.state.value.lngLat) {

			markers.push(<Feature key={lngLat} coordinates={lngLat} />);

		}

		if (this.state.value.lngLat.length == 2) {

			let lngLat1 = this.state.value.lngLat[0];
			let lngLat2 = this.state.value.lngLat[1];

			let maxLng = Math.max(lngLat2[0], lngLat1[0]);
			let minLng = Math.min(lngLat2[0], lngLat1[0]);
			let maxLat = Math.max(lngLat2[1], lngLat1[1]);
			let minLat = Math.min(lngLat2[1], lngLat1[1]);

			let boxLngLat1 = [maxLng, maxLat];
			let boxLngLat2 = [maxLng, minLat];
			let boxLngLat4 = [minLng, maxLat];
			let boxLngLat3 = [minLng, minLat];

			lines.push(<Feature key={[boxLngLat1, boxLngLat2]} coordinates={[boxLngLat1, boxLngLat2]} />);
			lines.push(<Feature key={[boxLngLat2, boxLngLat3]} coordinates={[boxLngLat2, boxLngLat3]} />);
			lines.push(<Feature key={[boxLngLat3, boxLngLat4]} coordinates={[boxLngLat3, boxLngLat4]} />);
			lines.push(<Feature key={[boxLngLat4, boxLngLat1]} coordinates={[boxLngLat4, boxLngLat1]} />);

		}

		//console.log("MAP update", lines, markers);

		this.setState({ mapFeatures: { box: lines, markers: markers } });

	}

	mapClick = (map, event) => {

		//console.log(event.lngLat);

		let lngLat = [event.lngLat.lng, event.lngLat.lat];

		if (this.state.mapFeatures.markers.length == 0) { // First point

			this.state.value.lngLat.push(lngLat);

			this.updateMap();

			return;

		}

		if (this.state.mapFeatures.markers.length == 1) { // Second point

			this.state.value.lngLat.push(lngLat);

			this.setValue(this.state.value);

			this.updateMap();

			return;


		}

		if (this.state.mapFeatures.markers.length == 2) { // Reset

			this.state.value.lngLat = [];

			this.state.value.lngLat.push(lngLat);

			this.updateMap();

			return;

		}

	}

	onChangeLat = (value, index) => {

		//console.log(value, index);

		this.state.value.lngLat[index][0] = value;

		this.setValue(this.state.value);

	}

	onChangeLong = (value, index) => {

		//console.log(value, index);

		this.state.value.lngLat[index][1] = value;

		this.setValue(this.state.value);

	}

	render() {

		return (

			<DecoratedInput id={this.id} label={this.label} selector={
				<ResultSelectorFunction
					id={this.id}
					visible={this.state.visible}
					onChange={this.setVisible}
				></ResultSelectorFunction>
			}>
				<FlexboxGrid
					justify="space-between"
					align="middle"
				>
					<FlexboxGrid.Item colspan={5}>
						<InputNumber
							prefix={"lng 0"}
							style={{ width: "95%" }}
							onChange={(v) => { this.onChangeLat(v, 0) }}
							value={this.state.value.lngLat?.[0]?.[0] || null}
						/>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={5}>
						<InputNumber
							prefix={"lat 0"}
							style={{ width: "95%" }}
							onChange={(v) => { this.onChangeLong(v, 0) }}
							value={this.state.value.lngLat?.[0]?.[1] || null}
						/>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={5}>
						<InputNumber
							prefix={"lng 1"}
							style={{ width: "95%" }}
							onChange={(v) => { this.onChangeLat(v, 1) }}
							value={this.state.value.lngLat?.[1]?.[0] || null}
						/>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={5}>
						<InputNumber
							prefix={"lat 1"}
							style={{ width: "95%" }}
							onChange={(v) => { this.onChangeLong(v, 1) }}
							value={this.state.value.lngLat?.[1]?.[1] || null}
						/>
					</FlexboxGrid.Item>
					<FlexboxGrid.Item colspan={3}>
						<IconButton
							size="md"
							style={{ float: "right", marginTop: "0px", width: "95%" }}
							appearance="default"
							onMouseDown={this.toggleModal}
							icon={<EraIcon faName={"map-location-dot"} color={"brown"} style={null} />}
						>
							Map
						</IconButton>
					</FlexboxGrid.Item>
				</FlexboxGrid>
				<Modal size={"lg"} open={this.state.mapOpen} onClose={this.toggleModal}>
					<Modal.Header>
						<Modal.Title>Location selector</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FlexboxGrid
							justify="space-between"
							align="middle"
						>
							<FlexboxGrid.Item colspan={24} style={{ height: window.innerHeight - 200 }}>
								Select two points to define a bounding box.
								<MapBox
									containerStyle={{
										height: "100%", width: '100%'
									}}
									style="mapbox://styles/mapbox/light-v10"
									center={this.state.center}
									zoom={this.zoom}
									antiAlias={true}
									onStyleLoad={this.updateMap}
									onClick={this.mapClick}
								>
									{/*<Layer type="symbol" layout={lineLayout} paint={linePaint}>
										<Feature coordinates={this.state.value.lngLat} />
									</Layer>*/}

									<Layer
										type="line"
										paint={this.lineStyle}
									>
										{this.state.mapFeatures.box}
									</Layer>


									<Layer
										type="symbol"
										layout={{ "icon-image": "marker" }}
										images={['marker', marker]}
									>
										{this.state.mapFeatures.markers}
									</Layer>
									<ZoomControl position={'top-right'} />
								</MapBox>

							</FlexboxGrid.Item>
							{/*<FlexboxGrid.Item colspan={8}>
								<Button>Use selected</Button>
							</FlexboxGrid.Item>*/}
						</FlexboxGrid>


					</Modal.Body>
				</Modal>
			</DecoratedInput>
		);

	}

}
