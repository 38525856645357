import React, {useEffect} from "react";

import {
	EraIcon
} from "../../../styles/Icon.js";

import { ButtonToolbar, IconButton } from "rsuite";

import { getPhrase } from "../../../utils/Languages.js";

export const ActionButtons = (props) => {

	const {onSearch, onSparql, onClear, onExport, search_disabled, export_disabled, search_working, export_working, language} = props;

	return(

		<ButtonToolbar style={{
			justifyContent: "right",
			display: "flex",
			padding: "1.5rem",
			paddingRight: "3rem",
			position:"fixed",
			width: "100vw",
			bottom: "70px",
			left: "0px",
			background: "rgba(200,200,200,0.4)",
			zIndex: "100" }}>


				<IconButton
					onClick={onExport}
					disabled={export_disabled}
					placement={"right"}
					appearance={"primary"}
					color={"green"}
					icon={<EraIcon faName={"file-export"} style={null} />}
				>
					{getPhrase("Export data", language)}
				</IconButton>

				<IconButton
					onClick={onSparql}
					disabled={export_disabled}
					placement={"right"}
					appearance={"default"}
					icon={<EraIcon faName={"terminal"} style={null} />}
				>
					{getPhrase("View query", language)}
				</IconButton>

				<div style={{flexGrow:1}}></div>
			
				<IconButton
					onClick={onClear}
					disabled={search_disabled}
					placement={"right"}
					appearance={"primary"}
					color={"red"}
					size="md"
					icon={<EraIcon faName={"broom"} style={null} />}
				>{getPhrase("Clear", language)}
				</IconButton>
					 
				<IconButton
					onClick={onSearch}
					disabled={search_disabled}
					appearance="primary"
					placement={"right"}
					size="md"
					icon={search_working ? <EraIcon faName={"gear"} style={null} pulse={true}/> : <EraIcon faName={"search"} style={null}/>}
				>
					 {getPhrase("search", language)}
				</IconButton>
				
		</ButtonToolbar>

	);


}
