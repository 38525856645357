

export class MapQuery {

	constructor(element, value) {

		//console.log("MapQuery:", element, value);

		this.element = element;

		this.value = value;

	}

	build_filter = () => {

		let filter = [];


		let lngLat1 = this.value.lngLat[0];
		let lngLat2 = this.value.lngLat[1];

		let maxLng = Math.max(lngLat2[0], lngLat1[0]);
		let minLng = Math.min(lngLat2[0], lngLat1[0]);
		let maxLat = Math.max(lngLat2[1], lngLat1[1]);
		let minLat = Math.min(lngLat2[1], lngLat1[1]);

		filter.push(`FILTER(?${this.element.id}_lat >= ${minLat} && ?${this.element.id}_lat <= ${maxLat})`);
		filter.push(`FILTER(?${this.element.id}_lng >= ${minLng} && ?${this.element.id}_lng <= ${maxLng})`);


		return filter;

	}

	build_extra_patterns = () => {


		let patterns = [];

		patterns.push(`?${this.element.id} <http://www.w3.org/2003/01/geo/wgs84_pos#lat> ?${this.element.id}_lat.`);

		patterns.push(`?${this.element.id} <http://www.w3.org/2003/01/geo/wgs84_pos#long> ?${this.element.id}_lng.`);

		return patterns;


	}

	build_extra_vars = () => {

		let vars = [];

		vars.push(`?${this.element.id}_lat`);

		vars.push(`?${this.element.id}_lng`);

		return vars;

	}


}
