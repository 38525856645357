import React from "react";

import {
	Container,
	SelectPicker,
	Button,
	ImageButton,
	Tag,
	Form,
	Panel,
	Placeholder,
	FlexboxGrid,
	PanelGroup,
	List
} from "rsuite";

import { isEqual } from 'lodash';

import { getPhrase } from "../../utils/Languages.js";

import { era, rdfs, skos, addPrefixes, removePrefixes } from "../../utils/NameSpace.js";

import {
	EraIcon
} from "../../styles/Icon.js";

import componentMappingFile from './FormDefinition.yml'


import { Translation } from 'react-i18next';


const TransformURI = (uri) => {

	if (uri.startsWith("http://data.europa.eu/949/")) {

		return "/describe#" + encodeURIComponent(uri);

	} else {

		return uri;

	}

}

const search_element = (id, formDefinition) => {

	let element = null;

	for (let definition of formDefinition) {

		for (let eid of Object.keys(definition.elements)) {

			if (eid == id) {

				element = definition.elements[id];

				break;

			}

		}

		for (let eid of Object.keys(definition.custom_filters)) {

			if (eid == id) {

				element = definition.custom_filters[id];

				break;

			}

		}

	}

	return element;

}

const Decorator = (type, value, skosValues) => {

	const TextInput = (value) => {

		if (value.regex) {

			return (<Tag color="grey" style={{ border: "dotted 1px #777" }}>{value.text} <i>(regex)</i></Tag>);

		} else {

			return (<Tag color="grey" style={{ border: "dotted 1px #777" }}>{value.text}</Tag>);

		}

		return JSON.stringify(value);

	}

	const NumericInput = (value) => {

		if (value.range) {

			return (
				<> between
					<Tag color="grey" style={{ border: "dotted 1px #777", margin: "0px 5px" }}>{value.from}</Tag>
					and
					<Tag color="grey" style={{ border: "dotted 1px #777", margin: "0px 5px" }}>{value.to}</Tag>
				</>);

		} else {

			return (<Tag color="grey" style={{ border: "dotted 1px #777" }}>{value.from}</Tag>);

		}

	}

	const DateInput = (value) => {

		let lang = navigator.language || "de-DE";

		let formatter = new Intl.DateTimeFormat(lang);

		let date = formatter.format(new Date(value));

		return (<Tag color="grey" style={{ border: "dotted 1px #777" }}>{date}</Tag>);

	}

	const PickerInput = (value) => {

		//return JSON.stringify(value);

		let selected = []

		for (let option of value.selected) {

			selected.push(<Tag color="grey" style={{ border: "dotted 1px #777" }} key={option}><a href={TransformURI(option)}>{skosValues[option]}</a></Tag>);

		}

		return (<>{selected}</>);


	}

	const BooleanInput = (value) => {


		if (value) {

			return (<Tag color="green">True</Tag>);

		} else {

			return (<Tag color="red">False</Tag>);

		}

	}

	const MapInput = (value) => {

		// {"type":"box","lngLat":[[0.6914204062485112,48.47832864171639],[5.63526806249925,49.444860543452734]]}

		let lang = navigator.language || "de-DE";

		let formatter = new Intl.NumberFormat(lang, { maximumSignificantDigits: 6 })


		if (value.type == "box") {

			return (<>Box from
				<Tag color="grey" style={{ border: "dotted 1px #777", margin: "0px 5px" }}>
					{formatter.format(value["lngLat"][0][0])}, {formatter.format(value["lngLat"][0][1])}
				</Tag> to
				<Tag color="grey" style={{ border: "dotted 1px #777", margin: "0px 5px" }}>
					{formatter.format(value["lngLat"][1][0])}, {formatter.format(value["lngLat"][1][1])}
				</Tag></>);

		} else {

			//console.log(value);

		}

	}




	const components = {

		TextInput: TextInput,
		NumericInput: NumericInput,
		DateInput: DateInput,
		PickerInput: PickerInput,
		BooleanInput: BooleanInput,
		MapInput: MapInput

	};



	return components[type](value);

}

export const SearchSummary = ({ formDefinition, formValues, skosValues }) => {

	const [visible, setVisible] = React.useState([]);
	const [value, setValue] = React.useState([]);

	//console.log(skosValues);


	const sortByIndex = (a, b) => {

		if (a.input.definition?.rinfIndex === b.input.definition?.rinfIndex) {
			return 0;
		}

		if (a.input.definition.rinfIndex > b.input.definition?.rinfIndex || a.input.definition?.rinfIndex === undefined) {
			return 1;
		}

		if (a.input.definition.rinfIndex < b.input.definition?.rinfIndex || b.input.definition?.rinfIndex === undefined) {
			return -1;
		}

	}

	const updateVisible = () => {

		let new_visible = [];

		let elements = [];

		for (let id of Object.keys(formValues.query.visible)) {

			let value = formValues.query.visible[id];

			let element = { ...search_element(id, formDefinition) };

			element.value = value;

			if (element !== null) {

				elements.push(element);

			}

		}

		//console.log(elements);

		elements.sort(sortByIndex);

		for (let element of elements) {

			if (element.input.definition.rinfIndex) {

				new_visible.push(<List.Item key={element.id}><Tag color="blue">{element.input.definition.rinfIndex}</Tag> <Tag color="grey">{element.input.definition.label}</Tag></List.Item>);

			} else {

				new_visible.push(<List.Item key={element.id}><Tag color="grey">{element.input.definition.label}</Tag></List.Item>);

			}

		}

		setVisible(new_visible);
	}

	const updateValue = () => {

		let new_value = [];

		let elements = [];

		for (let id of Object.keys(formValues.query.value)) {

			let value = formValues.query.value[id];

			let element = { ...search_element(id, formDefinition) };

			element.value = value;

			if (element !== null) {

				//console.log(element);

				elements.push(element);


			}

		}


		elements.sort(sortByIndex);
		//elements.reverse();


		for (let element of elements) {

			//console.log(element.input.component);

			let value = Decorator(element.input.component, element.value, skosValues);


			if (element.input.definition.rinfIndex) {

				new_value.push(<List.Item key={element.id}><Tag color="blue">{element.input.definition.rinfIndex}</Tag> <Tag color="grey">{element.input.definition.label}</Tag><EraIcon faName={"arrow-right"} style={{ margin: "0px 15px" }} /> {value}</List.Item>);

			} else {

				new_value.push(<List.Item key={element.id}><Tag color="grey">{element.input.definition.label}</Tag><EraIcon faName={"arrow-right"} style={{ margin: "0px 15px" }} /> {value}</List.Item>);

			}

		}

		setValue(new_value);

	}

	React.useEffect(() => {

		updateVisible();

		updateValue();

	}, [formValues]);




	return (



		<Panel style={{ margin: "2rem 2rem 72px 2rem", borderRadius: "0px", borderTop: "2px solid #bbb", borderBottom: "2px solid #bbb" }} header={<h3>Search summary</h3>}>


			{visible.length > 0 && (

				<Panel style={{ margin: "1rem", borderRadius: "0px" }} header={<h5>Fields selected</h5>}>

					<List hover size="sm">
						{visible}
					</List>

				</Panel>

			)}

			{value.length > 0 && (

				<Panel style={{ margin: "1rem", borderRadius: "0px" }} header={<h5>Filters applied</h5>}>

					<List hover size="sm">
						{value}
					</List>

				</Panel>

			)}


		</Panel>



	);

}
