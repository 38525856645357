import { Notification } from "rsuite";
import { saveAs } from "file-saver";
import { some, xor } from 'lodash';


import { MapResult } from "./../SearchResultComponents/MapResult.js";
import { NumericResult } from "./../SearchResultComponents/NumericResult.js";
import { NumericResultKm } from "./../SearchResultComponents/NumericResultKm.js";

import { DateResult } from "./../SearchResultComponents/DateResult.js";
import { TextResult } from "./../SearchResultComponents/TextResult.js";

import { sha256_sync } from "../../../utils/Misc.js"


import { era, rdfs, skos, rdf, addPrefixes, removePrefixes } from "../../../utils/NameSpace.js";



export class JsonExportNested {


	constructor(query, formDefinition, skosValues, data, sparqlQuery) {

		this.data = data;

		this.query = query;

		this.formDefinition = formDefinition;

		this.skosValues = skosValues;

		this.components = {

			MapResult: MapResult,
			NumericResult: NumericResult,
			NumericResultKm: NumericResultKm,
			DateResult: DateResult,
			TextResult: TextResult

		}

		//this.headers = Object.keys(this.data.results.bindings[0])

		this.headers = this.data.head.vars;

		for (let form of this.formDefinition) {

			if (form.object.value === this.query.rootObject) {

				this.elements = form.elements;

			}

		}

		this.root_element = null;

		for (let head of this.headers) {

			if (this.elements[head].id === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"
				|| this.elements[head].id === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				this.root_element = this.elements[head];

			}

		}



		this.used_elements = [];

		for (let head of this.headers) {

			if (this.elements[head] !== undefined
				&& this.elements[head].id !== "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"
				&& this.elements[head].id !== "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				this.used_elements.push(this.elements[head]);

			}

		}

		//console.log(this.used_elements, this.root_element);

	}

	// Generate tree based on query properties and paths

	generate_property_tree(elements, root_object) {

		let tree = {}

		tree[root_object.input.property] = { id: root_object.id }

		let tree_pos;

		for (let element of elements) { // Iterate over used elements

			//console.log(element, element.input.path);

			let property_path = element.input.path.concat([element.input.property]);

			//console.log("Element path:", property_path);

			tree_pos = tree[root_object.input.property];

			for (let [i, property] of property_path.entries()) { // Iterate over property path of an element

				if (!(property in tree_pos)) {

					tree_pos[property] = {};

					if (i === property_path.length - 1) {

						tree_pos[property].id = element.id;

					}

				}


				tree_pos = tree_pos[property];

			}

		}

		return tree;

	}

	find_element_by_path(path, use_root = 1) {

		let result = null;

		for (let key of Object.keys(this.elements)) {

			let element = this.elements[key];

			//console.log(key, element, path.slice[0], path.slice(1,-1));

			//if(element.input.property === path.slice(-1)[0] && xor(element.input.path, path.slice(1,-1)).length === 0){

			if (element.input.property === path.slice(-1)[0] && xor(element.input.path, path.slice(use_root, -1)).length === 0) {

				//console.log("Found: ", path, element);

				result = element;

				break;

			}/*else{
				
				//console.log(element.input.property, path.slice(-1)[0], element.input.path, path.slice(1,-1), xor(element.input.path, path.slice(1,-1)));
			}*/

		}

		return result;


	}

	replaceHeader(head) {

		let label = null;

		let index = null;

		let property = null;

		let path = null;

		if (this.elements[head] !== undefined) {

			label = this.elements[head].input.definition.label;

			index = this.elements[head].input.definition.rinfIndex;

			property = this.elements[head].input.property;

			if (this.elements[head].input.path !== undefined && this.elements[head].input.path.length > 0) {

				path = addPrefixes(this.root_element.input.property + ">" + this.elements[head].input.path.join(">") + ">" + this.elements[head].input.property);

			} else {

				path = addPrefixes(this.root_element.input.property + ">" + this.elements[head].input.property);

			}

		}

		// Corner case for root elements, can be moved to other place

		if (head === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

			property = "http://data.europa.eu/949/OperationalPoint";

			path = addPrefixes(this.root_element.input.property)

		}

		if (head === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

			property = "http://data.europa.eu/949/SectionOfLine";

			path = addPrefixes(this.root_element.input.property)

		}

		return { label: label, index: index, property: property, path: path.uri };

	}

	selectValue(element, dataKey, rowData) {

		if (element === undefined) {

			return "no data";

		}

		let cell_content = element.value;

		// URIs

		if (element.type === "uri") {

			let text = this.skosValues[element.value] || element.label || element.value;

			//console.log(text, element);

			if (dataKey === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

				if (this.headers.includes("2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e")) {

					({ value: text } = rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/operationalPoints/", "op:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			if (dataKey === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				if (this.headers.includes("442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78") &&
					this.headers.includes("ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7")) {

					let value1, value2;

					({ value: value1 } = rowData["442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78"]);
					({ value: value2 } = rowData["ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7"]);

					text = value1 + "-" + value2;


				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/sectionsOfLine/", "sol:");

				}

			}

			cell_content = {
				value: text,
				uri: element.value,
			};

		}


		// Booleans (color)

		if (element.type === "typed-literal" && element.dataType === "http://www.w3.org/2001/XMLSchema#boolean") {

			if (element.value === "1") {

				cell_content = true;

			} else {

				cell_content = false;

			}

		}


		// Empty formatting

		if (element.value === "N/A") {

			cell_content = "no data";
		}

		return cell_content;

	}

	insertInPlace(resultObject, tree, column, data) {

		let id_path = []

		for (let n in this.elements[column].input.path) {

			let step_id = sha256_sync("/" + this.root_element.input.property + "/" + this.elements[column].input.path.slice(n).join("/"));

			//console.log("stepid", step_id, n, this.elements[column].input.path.slice(n).join("/")); 

			id_path.push(step_id);

		}

		let fragment = id_path.reduce((obj, part) => { if (obj?.[part] === undefined) { obj[part] = {}; } return obj[part]; }, resultObject);

		//console.log("inplace", fragment, column, data);

		if (fragment[column] === undefined) {

			fragment[column] = {}

		}

		if (fragment[column]?.values === undefined) {

			fragment[column].values = [];

		}

		// Use LoDash function to check if element is already present, removing duplicates

		if (!some(fragment[column].values, data)) {

			fragment[column].values.push(data);

		}



	}

	traverseTree(tree, n = 0, path = []) {

		//console.log("T:", tree);

		let dataprop = [];

		let objectprop = [];

		for (let key of Object.keys(tree)) {

			if (key !== "id") {

				let new_path = [...path];

				new_path.push(key);

				//console.log("K:", key, n, new_path);

				let element = this.find_element_by_path(new_path)

				if (element) {

					if (element.input.definition.propertyType === "http://www.w3.org/2002/07/owl#DatatypeProperty") {

						// Data prop

						//console.log(element.id, element.input.path, element.input.property, "DP");

						dataprop.push(element.id);

					}

					if (element.input.definition.propertyType === "http://www.w3.org/2002/07/owl#ObjectProperty") {

						// Obj prop

						//console.log(element.id, element.input.path, element.input.property, "OP");

						objectprop.push(element.id);

					}

				}

				let datapropch, objectpropch;

				[datapropch, objectpropch] = this.traverseTree(tree[key], n + 1, new_path);

				dataprop = dataprop.concat(datapropch);

				objectprop = objectprop.concat(objectpropch);

			}

		}

		return [dataprop, objectprop];


	}



	/*insertDataInTree(object, column, row, cell){
		
		//console.log("Obj", object);
		//console.log("Col", column);
		//console.log("Row", row);
		
		if(cell !== undefined){
		
			//console.log(this.elements[column].input.path);
			
			let abs_path = [...this.elements[column].input.path];
			
			let base_obj = object;
			
			let prop = this.elements[column].input.property;
			
			for(let step of abs_path){
				
				let element = this.find_element_by_path(abs_path);
				
				//console.log("STP", step, element);
				
				base_obj = base_obj[row[element.id].value];
				
			}
			
			if (base_obj[prop] === undefined){
			
				base_obj[prop] = [];
				
			}
			
			if(!some(base_obj[prop], cell)){
			
				base_obj[prop].push(cell);
				
			}
			
		}

	}*/

	insertDataInTree(object, column, row, cell) {

		//console.log("Obj", object);		
		console.groupCollapsed(">> Col", column);
		//console.log("Row", row);

		if (cell !== undefined) {

			//console.log(this.elements[column].input.path);

			let abs_path = [...this.elements[column].input.path];

			//abs_path.push(this.elements[column].input.property);

			let base_obj = object;

			let current_path = []

			for (let step of abs_path) {

				current_path.push(step);

				//console.log("Path", current_path);

				//console.log("Step", step);

				//console.log("Base object", base_obj);

				let element = this.find_element_by_path(current_path, 0);

				//console.log("Element", element);

				let prop = this.elements[element.id].input.property;

				//console.log("Property", prop);


				if (base_obj[prop] === undefined) {

					//console.log("Array for prop", base_obj, prop);

					//base_obj[prop] = {};

				}

				base_obj = base_obj[prop];

				if (base_obj[row[element.id].value] === undefined) {

					//console.log("Object for instance", base_obj, row[element.id].value);

					//base_obj[row[element.id].value] = {};

				}

				base_obj = base_obj[row[element.id].value];

			}

			if (base_obj[this.elements[column].input.property] === undefined) {

				base_obj[this.elements[column].input.property] = [];

			}

			if (!some(base_obj[this.elements[column].input.property], cell)) {

				base_obj[this.elements[column].input.property].push(cell);

			}


		}

		console.groupEnd();


	}

	insertObjectInTree(object, column, row, cell) {

		//console.log("Obj", object);		
		console.groupCollapsed("Object prop", column);
		//console.log("Row", row);

		if (cell !== undefined) {

			//console.log(this.elements[column].input.path);

			let abs_path = [...this.elements[column].input.path];

			abs_path.push(this.elements[column].input.property);

			let base_obj = object;

			let current_path = []

			for (let step of abs_path) {

				current_path.push(step);

				//console.log("Path", current_path);

				//console.log("Step", step);

				//console.log("Base object", base_obj);

				let element = this.find_element_by_path(current_path, 0);

				//console.log("Element", element);

				let prop = this.elements[element.id].input.property;

				//console.log("Property", prop);


				if (base_obj[prop] === undefined) {

					//console.log("Array for prop", base_obj, prop);

					base_obj[prop] = {};

				}

				base_obj = base_obj[prop];

				if (base_obj[row[element.id].value] === undefined) {

					//console.log("Object for instance", base_obj, row[element.id].value);

					base_obj[row[element.id].value] = {};

				}

				base_obj = base_obj[row[element.id].value];

			}

			//console.log("Cell", base_obj, cell);

			Object.assign(base_obj, cell);


		}

		console.groupEnd();


	}

	async generateFile() {

		let exportObject = { head: [], data: {} };

		let tree = this.generate_property_tree(this.used_elements, this.root_element);

		//console.log(tree);

		let dataprops, objectprops;

		[dataprops, objectprops] = this.traverseTree(tree);



		for (let column of this.headers) {

			let head = { "key": column, "label": this.replaceHeader(column).label, "rinf_index": this.replaceHeader(column).index, "uri": this.replaceHeader(column).property };

			exportObject.head.push(head);

		}

		// Create root elements for entries


		exportObject.data[this.root_element.id] = {}

		for (let row of this.data.results.bindings) {

			let data_entry = {}

			for (let column of this.headers) {

				if (column === this.root_element.id) {

					//console.log(row[column]);

					exportObject.data[this.root_element.id][row[this.root_element.id].value] = {}

				}

			}


		}



		// Create structure of object properties

		for (let row of this.data.results.bindings) {

			for (let column of objectprops) {

				if (column !== this.root_element.id) {

					//console.log("Property", column, row[column]);

					let object = exportObject.data[this.root_element.id][row[this.root_element.id].value];

					this.insertObjectInTree(object, column, row, row[column]);

				}

			}

		}



		// Insert data in JSON structure

		for (let row of this.data.results.bindings) {

			for (let column of dataprops) {

				if (column !== this.root_element.id) {

					//console.log(row[column]);

					let object = exportObject.data[this.root_element.id][row[this.root_element.id].value];

					this.insertDataInTree(object, column, row, row[column]);

				}

			}

		}


		//console.log("Result", exportObject.data);

		//console.log(JSON.stringify(exportObject));

		const blob = new Blob([JSON.stringify(exportObject, null, 2)], { type: "application/json;charset=utf-8" });

		saveAs(blob, "search_results.json");

	}

}


export class JsonExportPlain {


	constructor(query, formDefinition, skosValues, data, sparqlQuery) {

		this.data = data;

		this.query = query;

		this.formDefinition = formDefinition;

		this.skosValues = skosValues;

		this.components = {

			MapResult: MapResult,
			NumericResult: NumericResult,
			NumericResultKm: NumericResultKm,
			DateResult: DateResult,
			TextResult: TextResult

		}

		//this.headers = Object.keys(this.data.results.bindings[0])

		this.headers = this.data.head.vars;

		for (let form of this.formDefinition) {

			if (form.object.value === this.query.rootObject) {

				this.elements = form.elements;

			}

		}

		this.root_element = null;

		for (let head of this.headers) {

			if (this.elements[head].id === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"
				|| this.elements[head].id === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				this.root_element = this.elements[head];

			}

		}



		this.used_elements = [];

		for (let head of this.headers) {

			if (this.elements[head] !== undefined && this.elements[head].id !== "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

				this.used_elements.push(this.elements[head]);

			}

		}

		//console.log(this.used_elements, this.root_element);

	}

	postProcessResults() {

		let newData;

		for (let element of this.headers) {

			if (this.elements[element].result in this.components) {

				//console.log(element, this.elements[element]);

				let result = new this.components[this.elements[element].result](this.elements[element]);

				newData = result.transformData(this.data);

			} else {

				//console.log("No component for ",element, this.elements[element]);

			}

		}

	}

	sortHeadResults = (a, b) => {

		try {

			let va = parseFloat(this.elements[a].input.ref) || 0;
			let vb = parseFloat(this.elements[b].input.ref) || 0;

			return va - vb;

		} catch (e) {

			return 0;

		}

	}

	replaceHeader(head) {

		let label = null;

		let index = null;

		let property = null;

		let path = null;

		if (this.elements[head] !== undefined) {

			label = this.elements[head].input.definition.label;

			index = this.elements[head].input.definition.rinfIndex;

			property = this.elements[head].input.property;

			if (this.elements[head].input.path !== undefined && this.elements[head].input.path.length > 0) {

				path = addPrefixes(this.root_element.input.property + ">" + this.elements[head].input.path.join(">") + ">" + this.elements[head].input.property);

			} else {

				path = addPrefixes(this.root_element.input.property + ">" + this.elements[head].input.property);

			}

		}

		// Corner case for root elements, can be moved to other place

		if (head === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

			property = "http://data.europa.eu/949/OperationalPoint";

			path = addPrefixes(this.root_element.input.property)

		}

		if (head === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

			property = "http://data.europa.eu/949/SectionOfLine";

			path = addPrefixes(this.root_element.input.property)

		}

		return { label: label, index: index, property: property, path: path.uri };

	}

	selectValue(element, dataKey, rowData) {

		if (element === undefined) {

			return "no data";

		}


		let cell_content = element.value;

		// URIs

		if (element.type === "uri") {

			let text = this.skosValues[element.value] || element.label || element.value;

			//console.log(text, element);

			if (dataKey === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

				if ("2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e" in rowData) {

					({ value: text } = rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/operationalPoints/", "op:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			if (dataKey === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				if ("442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78" in rowData &&
					"ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7" in rowData) {

					let value1, value2;

					({ value: value1 } = rowData["442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78"]);
					({ value: value2 } = rowData["ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7"]);

					text = value1 + "-" + value2;


				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/sectionsOfLine/", "sol:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			//value = DESCRIBE_URI + encodeURI(value)

			//cell_content = (
			//	<Tag color="grey" style={{ border: "dotted 1px #777" }}><a href={value} target="_blank">{text}</a></Tag>
			//);

			cell_content = {
				value: text,
				uri: element.value,
			};

		}


		// Booleans (color)

		if (element.type === "typed-literal" && element.dataType === "http://www.w3.org/2001/XMLSchema#boolean") {

			if (element.value === "1") {

				cell_content = true;

			} else {

				cell_content = false;

			}

		}


		// Empty formatting

		if (element.value === "N/A") {

			cell_content = "no data";
		}

		return cell_content;

	}

	async generateFile() {

		let exportObject = { head: [], data: [] };


		for (let column of this.headers) {

			let head = { "key": this.replaceHeader(column).path, "label": this.replaceHeader(column).label, "rinf_index": this.replaceHeader(column).index, "uri": this.replaceHeader(column).property };

			exportObject.head.push(head);

		}

		for (let row of this.data.results.bindings) {

			let data_entry = {}

			for (let column of this.headers) {

				data_entry[this.replaceHeader(column).path] = this.selectValue(row[column], column, row)

			}

			exportObject.data.push(data_entry);

		}


		const blob = new Blob([JSON.stringify(exportObject, null, 2)], { type: "application/json;charset=utf-8" });

		let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)

		saveAs(blob, "search_results_" + date + ".json");

	}

}
