import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "rsuite";

import eraLogoPath from "../../img/era-logo-new.png";
import { ERALogo } from "../../styles/Styles.js";
import { EraIcon } from "../../styles/Icon.js";

import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { UserMenu } from "../Authentication/UserMenu.js";
import { appRoleMap } from "../../azure/authConfig.js";

import { AuthContext } from "./Main.js";

import { NAV_ENABLED_APPS } from "../../config/config.js";

const AllowedTabsByRole = ({ activeRole }) => {
  const roleList = Array.from(appRoleMap.values());
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let datasets_manager = (
      <Nav.Item
        key="datasets"
        as={Link}
        to="/dataset-manager"
        icon={<EraIcon faName="book" margin="5px" />}
      >
        Dataset Manager
      </Nav.Item>
    );

    let notifications_manager = (
      <Nav.Item
        key="notifications"
        as={Link}
        to="/notifications-manager"
        icon={<EraIcon faName="envelope" margin="5px" />}
      >
        Notifications manager
      </Nav.Item>
    );

    const items = [];

    switch (activeRole?.role) {
      case "im": //IM
        items.push(datasets_manager);

        break;

      case "nre": //NRE
        items.push(datasets_manager);

        break;

      case "ru": //RU
        items.push(notifications_manager);

        break;

      default: //User
        break;
    }

    setMenuItems(items);
  }, [activeRole]);

  return (
    <>
      {menuItems.length != 0 && (
        <Nav.Menu title="Management" icon={<EraIcon faName="bars-progress" />}>
          {menuItems}
        </Nav.Menu>
      )}
    </>
  );
};

export default function NavHeader() {
  const [enabledComponents, setEnabledComponents] = useState([]);
  const [enabledExtras, setEnabledExtras] = useState([]);

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userRoles, setUserRoles] = useState();
  const [activeRole, setActiveRole] = useState();
  const [userRolesCompany, setUserRolesCompany] = useState();

  const { auth, authInfo, authRoles } = useContext(AuthContext);

  useEffect(() => {
    if (auth) {
      //console.log(auth);
      setUserInfo(authInfo);
      setUserRoles(authRoles?.available);
      setUserRolesCompany(authRoles?.company);
      setActiveRole(authRoles?.active);

      setIsAuthenticated(true);
    } else {
      //console.log(auth);
      setIsAuthenticated(false);
      setUserInfo(undefined);
      setUserRoles(undefined);
      setActiveRole(undefined);
    }
  }, [auth, authInfo, authRoles]);

  useEffect(() => {
    try {
      let enabledConfig = JSON.parse(atob(NAV_ENABLED_APPS));

      setEnabledComponents(enabledConfig.components);
      setEnabledExtras(enabledConfig.extras);
    } catch (error) {
      //console.log(error);

      //console.log("Using default config...");

      setEnabledComponents([
        "era-search",
        "era-compatibility-check",
        "era-map-explorer",
        "era-notifications",
        "era-dataset-manager",
        "era-endpoint", // To be separated
        "era-datastories", // To be separated
        "era-vocabulary",
      ]);

      setEnabledExtras([
        //"translation",
        "userLogin",
      ]);
    }
  }, []);

  return (
    <Navbar
      appearance="subtle"
      style={{ backgroundColor: "#f7f7fa", padding: "10px 5px" }}
    >
      <Nav as={"a"} href="/" style={{ height: "56px" }}>
        <ERALogo src={eraLogoPath} />
      </Nav>

      <Nav pullRight>
        <Nav.Menu title="Apps" icon={<EraIcon faName="screwdriver-wrench" />}>
          {enabledComponents.includes("era-search") && (
            <Nav.Item
              as={Link}
              to="/search"
              icon={<EraIcon faName="search" margin="5px" />}
            >
              Search Form
            </Nav.Item>
          )}

          {enabledComponents.includes("era-map-explorer") && (
            <Nav.Item
              as={Link}
              to={"/map-explorer"}
              icon={<EraIcon faName="map" margin="5px" />}
            >
              Map Explorer
            </Nav.Item>
          )}

          {enabledComponents.includes("era-compatibility-check") && (
            <Nav.Item
              as={Link}
              to={"/route-compatibility"}
              icon={<EraIcon faName="route" margin="5px" />}
            >
              Route Compatibility Check
            </Nav.Item>
          )}
        </Nav.Menu>

        <Nav.Menu title="Resources" icon={<EraIcon faName="swatchbook" />}>
          {enabledComponents.includes("era-datastories") && (
            <Nav.Item
              as={Link}
              to="/data-stories"
              icon={<EraIcon faName="magnifying-glass-chart" margin="5px" />}
            >
              Data Stories
            </Nav.Item>
          )}

          {enabledComponents.includes("era-vocabulary") && (
            <Nav.Item
              as={Link}
              to="/vocabulary"
              icon={<EraIcon faName="book" margin="5px" />}
            >
              Vocabulary
            </Nav.Item>
          )}

          {enabledComponents.includes("era-endpoint") && (
            <Nav.Item
              as={Link}
              to="/endpoint"
              icon={<EraIcon faName="plug" margin="5px" />}
            >
              Endpoint
            </Nav.Item>
          )}
        </Nav.Menu>

        {enabledExtras.includes("userLogin") && isAuthenticated && (
          <AllowedTabsByRole activeRole={activeRole} />
        )}

        {enabledExtras.includes("translation") &&
          {
            /*<Nav.Menu title="Language" icon={<EraIcon faName="language" />}>
							{Object.keys(availableLanguages).map((l) => (
								<Nav.Item key={l} onSelect={() => saveLanguage(l)}>
									{availableLanguages[l]}
								</Nav.Item>
							))}
							
						</Nav.Menu> */
          }}

        {enabledExtras.includes("userLogin") && (
          <UserMenu
            isAuthenticated={isAuthenticated}
            userInfo={userInfo}
            userRoles={userRoles}
            activeRole={activeRole}
            setRole={authRoles?.set}
            userRolesCompany={userRolesCompany}
          />
        )}
      </Nav>
    </Navbar>
  );
}
