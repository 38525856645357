import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Message } from "rsuite";


export const RCCDisclaimerModal = ({show, onClose}) => {
	
	const disclaimerText = (<>
			<p>While every effort is made to ensure that the route compatibility checks’ algorithm adequately generates a route and an associated report according to the specific TSIs, the EU Agency for Railways cannot be held responsible or accept any liability for possible omissions of relevant data or errors, nor for subsequent use of the route compatibility report. Only information provided by the EU railway infrastructure managers is deemed to be authentic.</p>
		</>)
	
	
	return (
	
		<Modal open={show} onClose={onClose} size={"lg"}>
			<Modal.Header>
				<Modal.Title><b>Disclaimer for compatibility results</b></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Message type="warning">
					{disclaimerText}
				</Message>
			</Modal.Body>
		</Modal>
		
	);
	
}
