import React, { useState, useEffect, Fragment } from "react";
import { InputPicker, IconButton, Tag, TagGroup } from "rsuite";
import { selectStyle } from "../../../styles/Styles.js";
import { getPhrase, getCurrentLanguage } from "../../../utils/Languages.js";

import axios from "axios";

import {
	SEARCH_HINT_API
} from "../../../config/config.js";

import {
	EraIcon
} from '../../../styles/Icon.js';


export const EraTag = (props) => {

	let {onClose, item} = props;

	let [fetching, setFetching] = React.useState(false);

	let [elements, setElements] = React.useState([]);
	
	let [selectedElements, setSelectedElements] = React.useState([]);
	

	return (

		<Tag
			closable={true}
			onClose={(x) => onClose(item)}
			size={"lg"}
			color={"blue"}
			>
		{item.label}
		</Tag>
		);

}
