
import { sha256_sync } from "../../utils/Misc.js";

import { era, rdfs, skos, addPrefixes, removePrefixes } from "../../../../utils/NameSpace.js";



export class ValidityDateEnd {

	constructor(element, value, root) {

		this.element = element;
		this.value = value;
		this.root = root;

		this.extra_vars = []

	}

	prepare_filter = async (elements, formDefinition, ontologyDefinition) => {

		let query = (await ontologyDefinition.exec("query", {
			data: { s: null, p: rdfs.subClassOf, o: era.InfrastructureObject }
		}));


		let dateClasses = ["http://data.europa.eu/949/Siding"]; //MUST FIX

		for (let result of query) {

			dateClasses.push(result.subject.value);

		}

		let dateClassesUsed = []


		for (let element of elements) {

			//console.log(element);

			if (element.element.input.definition.propertyType === "http://www.w3.org/2002/07/owl#ObjectProperty") {

				let targetClass = (await ontologyDefinition.exec("query", {
					data: { s: element.element.input.property, p: rdfs.range, o: null }
				}))[0].object.value;

				if (dateClasses.includes(targetClass) && !dateClassesUsed.includes(targetClass)) {

					//console.log("Valid filter for:", targetClass, "setting mandatory", element);

					dateClassesUsed.push(element.element.input.property);

					element.optional = false;

				}

			}

		}



	}

	build_filter = async (elements, formDefinition, ontologyDefinition) => {

		// 	FILTER(?d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787 <= xsd:date("2024-06-11"))

		//console.log("Valid filter", elements, formDefinition);

		// This ad-hoc filter uses the validity date properties to estimate if an entity is currently valid

		let filter = [];

		// Date only apply to subclasses of http://data.europa.eu/949/InfrastructureObject (maybe can be done programatically?)

		let query = (await ontologyDefinition.exec("query", {
			data: { s: null, p: rdfs.subClassOf, o: era.InfrastructureObject }
		}));

		let dateClasses = ["http://data.europa.eu/949/Siding"]; //MUST FIX

		for (let result of query) {

			dateClasses.push(result.subject.value);

		}

		//console.log(dateClasses);

		// Filter for root element

		let rootObject = "?" + sha256_sync("/" + this.root);
		//let validityEndDate = "?"+sha256_sync("/"+this.root+"/"+era.validityEndDate);

		let validityEndDate = "?" + this.element.id;

		let date = new Date(this.value).toISOString().slice(0, 10);

		filter.push(`
			# Currently valid filter for root element
			OPTIONAL{
				${rootObject} <http://data.europa.eu/949/validityEndDate> ${validityEndDate}.
			}

			FILTER (${validityEndDate} <= xsd:date("${date}"))`.replaceAll("\t\t\t", "")
		);

		this.extra_vars.push(validityEndDate);

		// Filter for child elements

		let dateClassesUsed = []

		for (let element of elements) {

			//console.log(element);

			element = element.element;

			if (element.input.definition.propertyType === "http://www.w3.org/2002/07/owl#ObjectProperty") {

				let targetClass = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: rdfs.range, o: null }
				}))[0].object.value;

				if (dateClasses.includes(targetClass) && !dateClassesUsed.includes(targetClass)) {

					//console.log("Valid filter for:", targetClass);

					dateClassesUsed.push(element.input.property);

					element.optional = false;

				} else {

					//console.log("Not valid filter for:", targetClass, dateClasses);

				}

			}

		}


		for (let dateClassUsed of dateClassesUsed) {

			//console.log(dateClassUsed);

			let classUsed = "?" + sha256_sync("/" + this.root + "/" + dateClassUsed);
			validityEndDate = "?" + sha256_sync("/" + this.root + "/" + dateClassUsed + "/" + era.validityEndDate);

			filter.push(`
			
			# Validity start date filter for ${dateClassUsed}
			
			OPTIONAL {
				${classUsed} <http://data.europa.eu/949/validityEndDate> ${validityEndDate}.
			}
	
			FILTER (${validityEndDate} <= xsd:date("${date}"))`.replaceAll("\t\t\t", "")

			);

		}



		//return [];

		return filter;

	}


	build_extra_patterns = () => {

		let patterns = [];

		return patterns;

	}


	build_extra_vars = (visible, value) => {

		//console.log("EXV: ", this.element, visible, value, this.root);

		if (visible && value !== undefined) {

			return "?" + this.element.id;

		} else {

			return "?" + sha256_sync("/" + this.root + "/" + era.validityEndDate);
		}
	}

}
