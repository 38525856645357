// Helper functions to generate URIs using short prefixes

const namespaces = {

era: "http://data.europa.eu/949/",
qudt: "http://qudt.org/schema/qudt/",
skos: "http://www.w3.org/2004/02/skos/core#",
rdfs: "http://www.w3.org/2000/01/rdf-schema#",
rdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
wgs: "http://www.w3.org/2003/01/geo/wgs84_pos#",
xsd: "http://www.w3.org/2001/XMLSchema#"

}

export const removePrefixes = (uri) => {

	let path = uri;
	let expanded = uri;

	if (uri !== undefined && uri !== null){

		for (let namespace of Object.entries(namespaces)){

			path = path.replace(namespace[0]+":", "")

			expanded = expanded.replace(namespace[0]+":", namespace[1]);

		}

	}

	return {expanded: expanded, path: path}

}

export const addPrefixes = (uri) => {
	
	let path = uri;
	let expanded = uri;

	if (uri !== undefined && uri !== null){

		for (let namespace of Object.entries(namespaces)){

			expanded = expanded.replaceAll(namespace[1], namespace[0]+":");

		}

	}

	return {uri: expanded}

}


const handler = {
	get : function (target, propertyName) {
		return target["uri"]+propertyName;
	}
}

export const era = new Proxy({uri:"http://data.europa.eu/949/"}, handler);
export const qudt = new Proxy({uri:"http://qudt.org/schema/qudt/"}, handler);

export const skos = new Proxy({ uri:"http://www.w3.org/2004/02/skos/core#" }, handler);
export const rdfs = new Proxy({uri:"http://www.w3.org/2000/01/rdf-schema#"}, handler);
export const rdf = new Proxy({uri:"http://www.w3.org/1999/02/22-rdf-syntax-ns#"}, handler);

export const wgs = new Proxy({ uri:"http://www.w3.org/2003/01/geo/wgs84_pos#" }, handler);
export const xsd = new Proxy({ uri:"http://www.w3.org/2001/XMLSchema#" }, handler);


