import React, {useEffect} from "react";
import { Button, IconButton, ButtonToolbar, Input, Modal, SelectPicker } from "rsuite";

import {
	EraIcon
} from "../../styles/Icon.js";


import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { sparql } from 'codemirror-lang-sparql';
import { darcula } from '@uiw/codemirror-theme-darcula';

export const QueryViewer = (props) => {
	
	const [modalOpen, setModalOpen] = React.useState(props.isOpen);
	const [modalData, setModalData] = React.useState();
	const [queryType, setQueryType] = React.useState("select");
	

	useEffect(() => {
		
		if (props.isOpen && props.data !== undefined){
			
			setQueryType("select");
			
			setModalData(prettyQuery(props.data["select"]));
			
		}
		
		setModalOpen(props.isOpen);

				
	}, [props]);
	
	
	const prettyQuery = (query) => {
		
		let result = query.replaceAll("\n\n", "\n")
		
		for (let i = 0; i < 10; i++){
			result = result.replaceAll("\n\n", "\n")
		}
		
		
		let lines = result.split("\n")
		
		result = "# SPARQL query generated by ERA Search App\n"
		
		let indent = 0;
		let tabs = "";
		let extra_break = "";
		
		let block_words = ["CONSTRUCT", "FROM", "WHERE", "SELECT", "OPTIONAL", "FILTER"]
		
		for (let line of lines){
			
			if (line.includes("}")){
				indent--;
			}
			
			tabs = "";
			
			for (let i = 0; i < indent; i++){
				
				tabs += "\t";
				
			}
			
			extra_break = "";
			
			for (let word of block_words){
				
				if(line.includes(word)){
					
					extra_break = "\n";
					
				}
				
			}
			
			
			result += extra_break+tabs+line+"\n";
			
			if (line.includes("{")){
				indent++;
			}
			
		}
		
		return result
		
	}
	
	
	const closeModal = () => {
		
		props.onClose();
		
	}
	
	const onChangeType = (type) => {
		
		setQueryType(type);
		
		setModalData(prettyQuery(props.data[type]));
				
	}
	
	const openYasgui = () => {
		
		let url = "/endpoint#query="+encodeURI(modalData)
		
		window.open(url, '_blank').focus();
	}
	
	const download = () => {
		
		const blob = new Blob([modalData], { type: "text/plain;charset=utf-8" });
		
		let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0,19)
		
		saveAs(blob, "query_"+date+".sparql");
		
	}
	
	const queryTypes = [
		
		{ label: "SELECT query", value: "select" },
		{ label: "CONSTRUCT query", value: "construct" }
		
	]


	return (
		<>
			<Modal open={modalOpen} onClose={closeModal} size={'full'}>
				<Modal.Header>
					<Modal.Title as={"h5"}>SPARQL query</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<p>This is the SPARQL query used to retrieve the data based on the search criteria.</p>
				
				<CodeMirror
					style={{padding: "15px 0px"}}
					  value={modalData}
					  height={"500px"}
					  theme={darcula}
					  extensions={[sparql()]}
					/>
				
				<ButtonToolbar>
				
				<SelectPicker 
					data={queryTypes}
					onSelect={onChangeType}
					defaultValue={"select"}
					cleanable={false}
					style={{ width: 200 }} 
				/>
				
				<div style={{flexGrow:1}}></div>
				<IconButton 
					onClick={download} 
					appearance={"primary"}
					color={"green"}
					placement={"right"}
					
					icon={<EraIcon faName={"file-export"} style={null} />}
				>
				Save query
				</IconButton>
				<IconButton 
					onClick={openYasgui} 
					appearance={"primary"}
					color={"orange"}
					placement={"right"}
					
					icon={<EraIcon faName={"play"} style={null} />}
				>
				Run on YASGUI
				</IconButton>
				</ButtonToolbar>
				</Modal.Body>
			</Modal>

		</>
	)



}

