import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Uploader,
  SelectPicker,
  Steps,
  Form,
  IconButton,
  Input,
  InputGroup,
  Modal,
  Pagination,
  Popover,
  Radio,
  RadioGroup,
  Table,
  Whisper,
  Dropdown,
  Tooltip,
  Tag,
  Container,
  Panel,
  List,
  ButtonToolbar,
  Progress,
  Message,
} from "rsuite";
import { Icon, More, Trash, Edit, Import } from "@rsuite/icons";
import {
  DatasetFileStatus,
  DatasetFileType,
  DatasetPipelines,
} from "./DatasetConstants.js";
import dateFormat from "dateformat";
const { Header, Title, Body, Footer } = Modal;
const { Column, HeaderCell, Cell } = Table;
import { saveAs } from "file-saver";

import { EraIcon } from "../../styles/Icon.js";

export const SearchBar = ({ onSearch }) => {
  function handleSearch(text) {
    onSearch(text);
  }

  return (
    <InputGroup>
      <Input placeholder="Search" onChange={handleSearch} />
      <InputGroup.Button onClick={handleSearch}>
        <EraIcon faName={"search"} style={null} />
      </InputGroup.Button>
    </InputGroup>
  );
};

export const DataPagination = ({ ...props }) => {
  return (
    <Pagination
      {...props}
      prev
      next
      first
      last
      ellipsis
      boundaryLinks
      maxButtons={5}
      size="xs"
      layout={["total", "-", "pager", "skip"]}
    />
  );
};

export const DeleteModal = ({ rowData, onDelete, showModal, setShowModal }) => {
  const [data, setData] = useState(rowData);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  function handleSubmit() {
    onDelete(data.id);
    setShowModal(false);
  }

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Header>
        <Title>Delete file</Title>
      </Header>
      <Body>
        <p>
          You are going to delete the following dataset file:{" "}
          <b> "{data?.fileName}" </b>
        </p>

        <p>This action can't be undone.</p>
      </Body>
      <Footer>
        <Button onClick={() => setShowModal(false)} appearance="subtle">
          Cancel
        </Button>

        <Button onClick={handleSubmit} color={"orange"} appearance="primary">
          Confirm
        </Button>
      </Footer>
    </Modal>
  );
};

export const PublishModal = ({
  rowData,
  onSubmit,
  showModal,
  setShowModal,
}) => {
  const [data, setData] = useState(rowData);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  function handleSubmit() {
    onSubmit(data.id);
    setShowModal(false);
  }

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Header>
        <Title>Publish Dataset</Title>
      </Header>
      <Body>
        You are going to Publish the following dataset "{data?.fileName}"
        Previous published datasets will be overrided.
      </Body>
      <Footer>
        <Button onClick={() => setShowModal(false)} appearance="subtle">
          Cancel
        </Button>
        <Button onClick={handleSubmit} appearance="primary">
          Publish
        </Button>
      </Footer>
    </Modal>
  );
};

export const RenameModal = ({ rowData, onUpdate, showModal, setShowModal }) => {
  const [newName, setNewName] = useState("");
  const [data, setData] = useState(rowData);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  function handleSubmit() {
    if (newName.trim().length !== 0) {
      onUpdate(data?.id, newName);
      setShowModal(false);
    }
  }

  function handleOnchangeName(name) {
    setNewName(name);
  }

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Header>
          <Title>Rename Files</Title>
        </Header>
        <Body>
          Enter new name for the file "{data?.fileName}"
          <Input
            type="text"
            placeholder={"New name"}
            onChange={handleOnchangeName}
          />
        </Body>
        <Footer>
          <Button onClick={() => setShowModal(false)} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={handleSubmit} appearance="primary">
            Confirm
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export const UploadDatasetModal = ({ dataList, onUpload }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDuplicatedModal, setShowDuplicatedModal] = useState(false);
  const [datasetFileType, setDatasetFileType] = useState(
    DatasetFileType.XmlFull
  );
  const [file, setFile] = useState();
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [acceptedExtensions, setAcceptedExtensions] = useState();
  const [extraInfo, setExtraInfo] = useState();

  const [fileList, setFileList] = useState([]);

  let DatasetFileTypes = [
    {
      label: "Full XML dataset",
      value: "XML_FULL",
      type: "Full datasets",
      acceptedExtensions: "text/xml, application/xml, .xml, .zip",
    },
    {
      label: "Full RDF dataset",
      value: "RDF_FULL",
      type: "Full datasets",
      acceptedExtensions: "application/n-triples, .nt, .zip",
    },
    {
      label: "Partial RDF dataset (insert)",
      value: "RDF_PARTIAL_INSERT",
      type: "Partial datasets",
      acceptedExtensions: "application/n-triples, .nt, .zip",
    },
    {
      label: "Partial RDF dataset (update)",
      value: "RDF_PARTIAL_UPDATE",
      type: "Partial datasets",
      acceptedExtensions: ".zip",
    },
    {
      label: "Partial RDF dataset (delete)",
      value: "RDF_PARTIAL_DELETE",
      type: "Partial datasets",
      acceptedExtensions: "application/n-triples, .nt, .zip",
    },
    {
      label: "Full RDF dataset, version 3.1.x (microlevel)",
      value: "RDF_FULL_3_1",
      type: "Full datasets",
      acceptedExtensions: "application/n-triples, .nt, .zip",
    },
    {
      label: "Partial RDF dataset (insert), version 3.1.x (microlevel)",
      value: "RDF_PARTIAL_INSERT_3_1",
      type: "Partial datasets",
      acceptedExtensions: "application/n-triples, .nt, .zip",
    },
    {
      label: "Partial RDF dataset (update), version 3.1.x (microlevel)",
      value: "RDF_PARTIAL_UPDATE_3_1",
      type: "Partial datasets",
      acceptedExtensions: ".zip",
    },
    {
      label: "Partial RDF dataset (delete), version 3.1.x (microlevel)",
      value: "RDF_PARTIAL_DELETE_3_1",
      type: "Partial datasets",
      acceptedExtensions: "application/n-triples, .nt, .zip",
    },
  ];

  DatasetFileTypes[0].extraInfo = "A full dataset file in the RINF XML format.";
  DatasetFileTypes[1].extraInfo =
    "A full dataset file in the RINF RDF format and serialized as N-Triples.";
  DatasetFileTypes[2].extraInfo =
    "A partial dataset file in the RINF RDF format and serialized as N-Triples.";
  DatasetFileTypes[3].extraInfo =
    "A partial dataset file in the RINF RDF format and serialized as N-Triples. Data must be compresed as ZIP including two files named INSERT.nt for the new triple set to be inserted and DELETE.nt for the old triple set to be removed.";
  DatasetFileTypes[4].extraInfo =
    "A partial dataset file in the RINF RDF format and serialized as N-Triples.";

  function handleOnChangeFile(files) {
    //console.log(files);

    if (files.length != 0) {
      setFile(files[files.length - 1].blobFile);
      setFileList([files[files.length - 1]]);
      setSubmitDisabled(false);
    } else {
      setFileList([]);
      setSubmitDisabled(true);
    }
  }

  const onCleanup = () => {
    setShowModal(false);
    setFileList([]);
    setUploadDisabled(true);
    setSubmitDisabled(true);
  };

  function handleClickSubmit() {
    if (file) {
      let existDuplicated = dataList.find(
        (item) => item.fileName === file?.name
      );

      if (existDuplicated) {
        setShowDuplicatedModal(true);
      } else {
        onUpload(file, datasetFileType);
        onCleanup();
      }
    }
  }

  const handleClickSubmitDuplicated = () => {
    onUpload(file, datasetFileType);
    setShowDuplicatedModal(false);
    onCleanup();
  };

  const handleClickCancel = () => {
    setShowModal(false);
    onCleanup();
  };

  const handleTypeChange = (type, item) => {
    setExtraInfo(item.extraInfo);
    setDatasetFileType(type);
    setAcceptedExtensions(item.acceptedExtensions);
    setUploadDisabled(false);
  };

  const AlertUploadDuplicated = ({
    fileName,
    onSubmit,
    showModal,
    setShowModal,
  }) => {
    function handleSubmit() {
      onSubmit();
      setShowModal(false);
    }

    return (
      <>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Header>
            <Title>Duplicated File</Title>
          </Header>
          <Body>
            A file with the same name <b>"{fileName}"</b> already exists. Do you
            want to upload it anyway?
          </Body>
          <Footer>
            <Button onClick={() => setShowModal(false)} appearance="subtle">
              Cancel
            </Button>
            <Button onClick={handleSubmit} appearance="primary">
              Confirm
            </Button>
          </Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      <AlertUploadDuplicated
        fileName={file?.name}
        showModal={showDuplicatedModal}
        setShowModal={setShowDuplicatedModal}
        onSubmit={handleClickSubmitDuplicated}
      />

      <IconButton
        icon={<EraIcon faName={"upload"} style={null} />}
        appearance="primary"
        color={"green"}
        onClick={() => setShowModal(true)}
        block
      >
        Upload
      </IconButton>

      {setShowModal && (
        <Modal open={showModal} onClose={handleClickCancel}>
          <Header>
            <Title>Upload Files</Title>
          </Header>
          <Body>
            <SelectPicker
              label={"Dataset type"}
              placeholder={"select the dataset type to begin"}
              data={DatasetFileTypes}
              block={true}
              groupBy={"type"}
              onSelect={handleTypeChange}
              searchable={false}
              style={{ marginTop: "15px" }}
              cleanable={false}
            />

            {extraInfo && (
              <Message showIcon type="info" style={{ marginTop: "25px" }}>
                {extraInfo}
              </Message>
            )}

            <Uploader
              fileList={fileList}
              disabled={uploadDisabled}
              accept={acceptedExtensions}
              action={""}
              style={{ marginTop: "25px" }}
              onChange={handleOnChangeFile}
              autoUpload={false}
            >
              <IconButton icon={<EraIcon faName={"paperclip"} style={null} />}>
                Select dataset file...
              </IconButton>
            </Uploader>
          </Body>
          <Footer>
            <Button onClick={handleClickCancel} appearance="subtle">
              Cancel
            </Button>
            <IconButton
              icon={<EraIcon faName={"upload"} style={null} />}
              disabled={submitDisabled}
              onClick={handleClickSubmit}
              appearance="primary"
              color={"green"}
            >
              Upload dataset
            </IconButton>
          </Footer>
        </Modal>
      )}
    </>
  );
};

export const UploadDocumentsModal = ({ onUpload }) => {
  const [showModal, setShowModal] = useState(false);
  const [fileList, setFileList] = useState();
  const files = fileList ? [...fileList] : [];

  function handleOnChangeFile(event) {
    setFileList(event.target.files);
  }

  function handleClickSubmit() {
    if (fileList) {
      onUpload(fileList);
      setShowModal(false);
      setFileList(null);
    }
  }

  function handleClickCancel() {
    setShowModal(false);
    setFileList(null);
  }

  return (
    <>
      <Button appearance="default" onClick={() => setShowModal(true)}>
        Upload
      </Button>
      <Modal open={showModal} onClose={handleClickCancel}>
        <Header>
          <Title>Upload Files</Title>
        </Header>
        <Body>
          <input type="file" onChange={handleOnChangeFile} multiple />
          <ul>
            {files.map((file, i) => (
              <li key={i}>
                {file.name} - {file.type}
              </li>
            ))}
          </ul>
        </Body>
        <Footer>
          <Button onClick={handleClickCancel} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={handleClickSubmit} appearance="primary">
            Submit
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export const PreconditionsModal = ({ onConfirm, showModal, setShowModal }) => {
  const confirm = () => {
    setShowModal(false);

    onConfirm();
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Header>
        <Title>Unable to execute task</Title>
      </Header>
      <Body>
        <Message showIcon type="warning" header="Warning">
          This step requires previous tasks in this pipeline, which failed.
          Please, review the generated error reports in previous steps and fix
          the problems.
        </Message>
      </Body>
      <Footer>
        <Button onClick={() => setShowModal(false)} appearance="subtle">
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

export const PipelineActions = ({
  rowData,
  onUpdate,
  onDownloadArtifact,
  ...props
}) => {
  const [filename, setFilename] = useState(rowData.fileName);
  const [type, setType] = useState(rowData.type);
  const [status, setStatus] = useState(rowData.pipeline);

  useEffect(() => {
    setFilename(rowData.fileName);
    setStatus(rowData.pipeline);
    setType(rowData.type);
  }, [rowData]);

  let globalStatus = -3;

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreconditionsModal, setShowPreconditionsModal] = useState(false);

  const [steps, setSteps] = useState([]);
  const [stepInfo, setStepInfo] = useState();
  const [step, setStep] = useState();

  const [iconStyle, setIconStyle] = useState({
    color: "gray",
    move: false,
    icon: "gear",
  });

  useEffect(() => {
    setSteps(getSteps());

    if (step) {
      updateStepInfo(step);
    }
  }, [status]);

  const showPipeline = () => {
    onStep(DatasetPipelines[type][0]);

    setShowModal(true);
  };

  const onTrigger = (change_request, step, check_preconditions) => {
    let current_steps = DatasetPipelines[type].map((x) => {
      return x.id;
    });

    let preconditions_meet = true;

    if (step.preconditions !== undefined && check_preconditions) {
      for (let precondition of step.preconditions) {
        //console.log(precondition);

        if (
          current_steps.includes(precondition.step) &&
          !precondition.validResults.includes(status[precondition.step].state)
        ) {
          preconditions_meet = false;

          break;
        }
      }
    }

    if (preconditions_meet || !step.preconditions_enforced) {
      onUpdate(rowData?.id, change_request);

      //console.log("Publish")
    } else {
      setShowPreconditionsModal(true);
    }
  };

  const onClose = () => {
    setShowModal(false);
    //setStep(undefined);
  };

  const onStep = (step) => {
    setStep(step);
    updateStepInfo(step);
  };

  const onDownloadReport = (report, step) => {
    const blob = new Blob([report], { type: "text/plain;charset=utf-8" });

    let date = new Date()
      .toISOString()
      .replaceAll(":", "_")
      .replaceAll("-", "_")
      .slice(0, 19);

    saveAs(blob, "pipeline_report_" + step.id + "_" + date + ".txt");
  };

  const onDownloadArtifactHandle = (artifact, step) => {
    // Can be reimplemented in a different way, using legacy for the moment, i don't like using S3 presigned URLs...

    /*const blob = new Blob([artifact], { type: "application/n-triples;charset=utf-8" });
    	
        let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0,19)

        saveAs(blob, "pipeline_report_"+step.id+"_"+date+".nt");*/

    //onDownloadArtifact(artifact, name);

    onDownloadArtifact(artifact);
  };

  const updateStepInfo = (step) => {
    const stateCases = {
      "-3": {
        text: "Waiting for user action",
        color: "",
        stepStatus: { step: 0, status: "wait" },
      },
      "-2": {
        text: "Not applicable step",
        color: "",
        stepStatus: { step: 0, status: "wait" },
      },
      "-1": {
        text: "Finished with errors",
        color: "red",
        stepStatus: { step: 2, status: "error" },
      },
      0: {
        text: "Scheduled for running",
        color: "",
        stepStatus: { step: 0, status: "process" },
      },
      1: {
        text: "Running",
        color: "blue",
        stepStatus: { step: 1, status: "process" },
      },
      2: {
        text: "Finished successfully",
        color: "green",
        stepStatus: { step: 2, status: "finish" },
      },
    };

    let scheduleDate;

    if (status[step.id]?.scheduleDate && status[step.id].scheduleDate != 0) {
      scheduleDate = {
        exact: dateFormat(
          new Date(status[step.id].scheduleDate),
          "dd/mm/yyyy - HH:MM:ss"
        ),
        aprox: moment(new Date(status[step.id].scheduleDate))
          .fromNow()
          .toString(),
      };
    } else {
      scheduleDate = { exact: "Pending", aprox: "Pending" };
    }

    let startDate;

    if (status[step.id]?.startDate && status[step.id].startDate != 0) {
      startDate = {
        exact: dateFormat(
          new Date(status[step.id].startDate),
          "dd/mm/yyyy - HH:MM:ss"
        ),
        aprox: moment(new Date(status[step.id].startDate)).fromNow().toString(),
      };
    } else {
      startDate = { exact: "Pending", aprox: "Pending" };
    }

    let endDate;

    if (status[step.id]?.endDate && status[step.id].endDate != 0) {
      endDate = {
        exact: dateFormat(
          new Date(status[step.id].endDate),
          "dd/mm/yyyy - HH:MM:ss"
        ),
        aprox: moment(new Date(status[step.id].endDate)).fromNow().toString(),
      };
    } else {
      endDate = { exact: "Pending", aprox: "Pending" };
    }

    let execute_button_disabled =
      status[step.id].state != -3 || globalStatus == 0 || globalStatus == 1;

    let report_button_disabled = !(
      status[step.id].state == 2 || status[step.id].state == -1
    );

    //let download_artifacts_disabled = status[step.id].state != 2;

    let download_artifacts_disabled = !(
      status[step.id]["artifacts"] != undefined &&
      status[step.id]["artifacts"][step.artifactRef] != undefined
    );

    //console.log(status[step.id])

    setStepInfo(
      <Container>
        <ButtonToolbar style={{ padding: "0px", margin: "5px 0px" }}>
          <IconButton
            disabled={execute_button_disabled}
            icon={<EraIcon faName={"play"} style={null} />}
            onClick={() => onTrigger(step.trigger, step, true)}
          >
            Execute task
          </IconButton>
          {step.reportDownload && (
            <IconButton
              disabled={report_button_disabled}
              icon={<EraIcon faName={"download"} style={null} />}
              onClick={() => onDownloadReport(status[step.id].msg, step)}
            >
              Download report
            </IconButton>
          )}

          {step.artifacts && (
            <IconButton
              disabled={download_artifacts_disabled}
              icon={<EraIcon faName={"download"} style={null} />}
              onClick={() =>
                onDownloadArtifactHandle(
                  status[step.id].artifacts[step.artifactRef],
                  step
                )
              }
            >
              Download artifact: {step.artifactsName}
            </IconButton>
          )}
        </ButtonToolbar>

        <Panel
          header={<b>{step.name}</b>}
          bordered
          style={{ padding: "5px", margin: "15px 0px" }}
        >
          <List>
            <List.Item>
              Current state:{" "}
              <Tag color={stateCases[status[step.id].state].color}>
                {stateCases[status[step.id].state].text}
              </Tag>{" "}
            </List.Item>

            <Steps
              current={stateCases[status[step.id].state].stepStatus.step}
              currentStatus={
                stateCases[status[step.id].state].stepStatus.status
              }
              style={{ margin: "25px 0px" }}
              small
            >
              <Steps.Item
                key={"1"}
                title={"Scheduled"}
                description={
                  <Whisper
                    trigger="hover"
                    placement={"top"}
                    controlId={`control-id-top`}
                    speaker={<Tooltip>{scheduleDate.exact}</Tooltip>}
                  >
                    <Tag>{scheduleDate.aprox}</Tag>
                  </Whisper>
                }
              />
              <Steps.Item
                key={"2"}
                title={"Started"}
                description={
                  <Whisper
                    trigger="hover"
                    placement={"top"}
                    controlId={`control-id-top`}
                    speaker={<Tooltip>{startDate.exact}</Tooltip>}
                  >
                    <Tag>{startDate.aprox}</Tag>
                  </Whisper>
                }
              />
              <Steps.Item
                key={"3"}
                title={"Finished"}
                description={
                  <Whisper
                    trigger="hover"
                    placement={"top"}
                    controlId={`control-id-top`}
                    speaker={<Tooltip>{endDate.exact}</Tooltip>}
                  >
                    <Tag>{endDate.aprox}</Tag>
                  </Whisper>
                }
              />
            </Steps>

            {step.progress &&
              status[step.id].progress != 100 &&
              status[step.id].progress != 0 && (
                <List.Item>
                  Progress:{" "}
                  <Progress.Line
                    percent={status[step.id].progress}
                    status="active"
                  />{" "}
                </List.Item>
              )}
          </List>
        </Panel>

        {step.report &&
          (status[step.id].state == 2 || status[step.id].state == -1) && (
            <Panel
              header={"Task report"}
              bordered
              style={{ padding: "5px", margin: "15px 0px" }}
            >
              <Input
                as="textarea"
                rows={6}
                placeholder=""
                value={status[step.id].msg}
              />
            </Panel>
          )}
      </Container>
    );
  };

  const getSteps = () => {
    let steps = [];

    let globalStatusCalc = -3;

    const numericStatusMap = {
      "-3": "wait",
      "-1": "error",
      0: "process",
      1: "process",
      2: "finish",
    };

    for (let step of DatasetPipelines[type]) {
      let stepId = steps.length;
      let stepStatus = status[step.id].state;

      //console.log(stepStatus);

      if (stepStatus != -3 && stepStatus != -2) {
        globalStatusCalc = stepStatus;
      }

      steps.push(
        <Steps.Item
          key={stepId}
          title={step.name}
          onClick={() => onStep(step)}
          status={numericStatusMap[stepStatus]}
          style={{ cursor: "pointer" }}
        />
      );
    }

    let color;
    let move = false;
    let icon = "gear";

    if (globalStatusCalc == 0) {
      color = "gray";
      icon = "clock";
    }

    if (globalStatusCalc == 1) {
      color = "blue";
      icon = "spinner";
      move = true;
    }

    if (globalStatusCalc == -3 || globalStatusCalc == -2) {
      color = "grey";
      icon = "user-gear";
    }

    if (globalStatusCalc == -1) {
      color = "red";
      icon = "circle-xmark";
    }

    if (globalStatusCalc == 2) {
      color = "green";
      icon = "circle-check";
    }

    setIconStyle({ color: color, move: move, icon: icon });

    //console.log({color: color, move: move}, globalStatusCalc);

    globalStatus = globalStatusCalc;

    return steps;
  };

  return (
    <>
      <PreconditionsModal
        onConfirm={() => {
          onTrigger(step.trigger, step, false);
        }}
        showModal={showPreconditionsModal}
        setShowModal={setShowPreconditionsModal}
      />

      <Whisper
        trigger="hover"
        placement={"top"}
        controlId={`control-id-top`}
        speaker={<Tooltip>Dataset pipeline</Tooltip>}
      >
        <IconButton
          icon={
            <EraIcon
              faName={iconStyle.icon}
              style={null}
              pulse={iconStyle.move}
            />
          }
          appearance="default"
          style={{ color: iconStyle.color, marginLeft: "30px" }}
          onClick={showPipeline}
        ></IconButton>
      </Whisper>

      {setShowModal && (
        <Modal open={showModal} onClose={onClose} size={"full"}>
          <Header>
            <h5>Dataset pipeline: {filename}</h5>
          </Header>
          <Body>
            <Panel bordered style={{ padding: "15px 0px", margin: "15px 0px" }}>
              <Steps>{steps}</Steps>
            </Panel>

            {step && stepInfo}
          </Body>
        </Modal>
      )}
    </>
  );
};

export const ActionCell = ({
  dataType,
  rowData,
  onDelete,
  onDownload,
  onDownloadArtifact,
  onRename,
  onUpdate,
  ...props
}) => {
  const [data, setData] = useState(rowData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  useEffect(() => {
    setData(rowData);
    //console.log(rowData)
  }, [rowData]);

  function handleOnDelete(id) {
    onDelete(id);
    setShowDeleteModal(false);
  }
  function handleOnRename(id, newName) {
    onRename(id, newName);
    setShowRenameModal(false);
  }

  return (
    <>
      <Cell {...props} style={{ padding: "2px" }}>
        <Whisper
          trigger="hover"
          placement={"top"}
          controlId={`control-id-top`}
          speaker={<Tooltip>Download</Tooltip>}
        >
          <IconButton
            appearance="subtle"
            icon={<EraIcon faName={"file-arrow-down"} style={null} />}
            onClick={() => onDownload(data?.id)}
          />
        </Whisper>

        <Whisper
          trigger="hover"
          placement={"top"}
          controlId={`control-id-top`}
          speaker={<Tooltip>Rename</Tooltip>}
        >
          <IconButton
            appearance="subtle"
            icon={<EraIcon faName={"pen-to-square"} style={null} />}
            onClick={() => setShowRenameModal(true)}
          />
        </Whisper>

        <Whisper
          trigger="hover"
          placement={"top"}
          controlId={`control-id-top`}
          speaker={<Tooltip>Delete</Tooltip>}
        >
          <IconButton
            appearance="subtle"
            icon={<EraIcon faName={"trash-can"} style={null} />}
            onClick={() => setShowDeleteModal(true)}
          />
        </Whisper>

        <PipelineActions
          rowData={data}
          onUpdate={onUpdate}
          onDownloadArtifact={onDownloadArtifact}
          {...props}
        />
      </Cell>

      <DeleteModal
        rowData={data}
        onDelete={handleOnDelete}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
      <RenameModal
        rowData={data}
        onUpdate={handleOnRename}
        setShowModal={setShowRenameModal}
        showModal={showRenameModal}
      />
    </>
  );
};

export const DataTable = ({
  dataList,
  dataType,
  isLoading,
  onDelete,
  onDownload,
  onRename,
  onSelectPipeline,
}) => {
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [tableData, setTableData] = useState();

  useEffect(() => {
    setTableData(sortData(dataList, "dateCreated", "desc"));
  }, [dataList]);

  function handleSortColumn(sortColumn, sortType) {
    setSortColumn(sortColumn);
    setSortType(sortType);
    setTableData(sortData(tableData, sortColumn, sortType));
  }

  function sortData(data, column, type) {
    if (!data) return;

    if (!column || !type) {
      return data.slice();
    }

    return data.slice().sort((a, b) => {
      let x = a[column] || "";
      let y = b[column] || "";

      if (column === "uploadDate") {
        x = x ? new Date(x).getTime() : x;
        y = y ? new Date(y).getTime() : y;
      }

      if (x === y) {
        return 0;
      }

      // Return negative value for ascending order
      if (type === "asc") {
        return x < y ? -1 : 1;
      }

      // Return negative value for descending order
      if (type === "desc") {
        return y < x ? -1 : 1;
      }
    });
  }

  function handleOnDownload(id) {
    onDownload(dataType, id);
  }
  function handleOnRdfDownload(s3Uri) {
    onDownload(dataType, null, s3Uri);
  }
  function handleOnDelete(id) {
    onDelete(dataType, id);
  }
  function handleOnRename(id, newName) {
    onRename(dataType, id, newName);
  }
  function handleOnSelectPipeline(id, pipelineStatus) {
    onSelectPipeline(id, pipelineStatus);
  }

  function humanFileSize(size) {
    let sizeText;

    if (size < 1024) {
      sizeText = size + " B";
    }

    if (size >= 1024 && size < 1024 * 1024) {
      sizeText = (size / 1024).toFixed(2) + " KiB";
    }

    if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
      sizeText = (size / (1024 * 1024)).toFixed(2) + " MiB";
    }

    if (size >= 1024 * 1024 * 1024) {
      sizeText = (size / (1024 * 1024 * 1024)).toFixed(2) + " GiB";
    }

    return sizeText;
  }

  return (
    <>
      <Table
        loading={isLoading}
        autoHeight={true}
        data={tableData}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        //rowHeight={52}
      >
        <Column flexGrow={2} align="left" sortable resizable>
          <HeaderCell>Owner</HeaderCell>
          <Cell dataKey="ownerName">
            {(rowData) => rowData.owner?.fullname}
          </Cell>
        </Column>

        <Column align="left" flexGrow={1} sortable resizable>
          <HeaderCell>Dataset type</HeaderCell>
          <Cell dataKey="type" />
        </Column>

        <Column align="left" flexGrow={2} sortable resizable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="fileName" />
        </Column>

        <Column flexGrow={1} align="left" sortable resizable>
          <HeaderCell>Size</HeaderCell>
          <Cell dataKey="size">{(rowData) => humanFileSize(rowData.size)}</Cell>
        </Column>

        <Column flexGrow={2} align="left" sortable>
          <HeaderCell>Upload date</HeaderCell>
          <Cell dataKey="dateCreated">
            {(rowData) => {
              if (rowData.dateCreated) {
                const date = new Date(rowData.dateCreated);
                return (
                  dateFormat(date, "dd/mm/yyyy - HH:MM:ss") +
                  " (~" +
                  moment(new Date(date)).fromNow() +
                  ")"
                );
              }
            }}
          </Cell>
        </Column>

        <Column flexGrow={2} align="left" sortable>
          <HeaderCell>Publishing date</HeaderCell>
          <Cell dataKey="datePublished">
            {(rowData) => {
              if (rowData.datePublished) {
                const date = new Date(rowData.datePublished);
                return (
                  dateFormat(date, "dd/mm/yyyy - HH:MM:ss") +
                  " (~" +
                  moment(new Date(date)).fromNow() +
                  ")"
                );
              }
            }}
          </Cell>
        </Column>

        <Column width={200} align="center">
          <HeaderCell>Actions</HeaderCell>
          <ActionCell
            onDownload={handleOnDownload}
            onDelete={handleOnDelete}
            onRename={handleOnRename}
            onDownloadArtifact={handleOnRdfDownload}
            onUpdate={handleOnSelectPipeline}
            dataType={dataType}
          />
        </Column>
      </Table>
    </>
  );
};
