import React, { useState, useEffect, Fragment } from "react";
import { InputPicker, IconButton, Tag, TagGroup } from "rsuite";
import { selectStyle } from "../../../styles/Styles.js";
import { getPhrase, getCurrentLanguage } from "../../../utils/Languages.js";

import { EraTag } from "./EraTag.js";

import axios from "axios";

import {
	NOTIFICATIONS_SEARCH_HINT_API
} from "../../../config/config.js";

import {
	EraIcon
} from '../../../styles/Icon.js';


export const EraPicker = (props) => {

	let {prefix, type, placeholder, onClean, onSelectFinal, ...rest} = props;

	let [fetching, setFetching] = React.useState(false);

	let [elements, setElements] = React.useState([]);
	
	let [selectedElements, setSelectedElements] = React.useState([]);
	
	let debounce;

	const renderMenu = (menu) => {
		
		if (fetching) {
			return (
				<p style={{ padding: 15, color: '#666'}}>
					<EraIcon faName="spinner" spin />{getPhrase('loading', getCurrentLanguage())}...
				</p>
			);
			
		}else{

			if(elements.length == 0){
				return (
					<p style={{ padding: 15, color: '#666'}}>
						Type a search query to begin...
					</p>
				);

			}else{
			
				return menu;

			}
		}
	}


	const onSearchQuery = async (query, event) => {

		debounce = query;

		if(query.length > 2){

			setFetching(true);
			
			let response = await axios.post(NOTIFICATIONS_SEARCH_HINT_API, { "type": type, "query": query, "n_results": 20});
			let elementsRes = response.data.result;
			
			if(debounce.length <= query.length){
				setElements(elementsRes);
				setFetching(false);
				
			}

		}

	}

	const renderSelected = (value, item, prefix) => {

		let shown_value;

		if (item && item.label){

			shown_value = item.label;

		}else{

			shown_value = value;

		}

		return (
			<div>
				Type to start a different search...
			</div>
		)
	}
	
	const onSelect = (value, item) => {
		
		//console.log(value, item);
		
		const nextSelected = [...selectedElements];
		
		let dup = false;
		
		for(let element of nextSelected){
			
			if(element.value === value){
				
				dup = true;
				
			}
			
		}
		
		if(!dup){
		
			nextSelected.push(item);
			
			setSelectedElements(nextSelected);
			
			onSelectFinal(nextSelected);
			
			//console.log(selectedElements);
			
		}
		
	}
	
	const onRemoveSelect = (item) => {
		
		//console.log(item);
				
		const nextSelected =  [...selectedElements];
		
		nextSelected.pop(item);
		
		setSelectedElements(nextSelected);
		
		//console.log(selectedElements);
		
		if(nextSelected.length == 0){
			
			onClean();
			
		}else{
		
			onSelectFinal(nextSelected);
			
		}
		
	}


	return (

		<>
			<InputPicker

				cleanable={true}
			
				style={selectStyle}
				placeholder={placeholder}
				
				onSearch={onSearchQuery}
				
				onSelect={onSelect}

				data={elements}
				
				renderValue={(value, item) => renderSelected(value, item, prefix)}
				renderMenu = {renderMenu}

				

			></InputPicker>
			
			{selectedElements.length > 0 && (<h6 style={{marginTop: "15px"}}>Selected:</h6>)}
			
			<TagGroup>{selectedElements && selectedElements.map((item, index) => {return(<EraTag item={item} onClose={onRemoveSelect} key={index}></EraTag>)})}</TagGroup>
		</>
		);

}
