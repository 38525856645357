import React from "react";

import {
	CheckPicker
} from "rsuite";

import { DecoratedInput } from "./DecoratedInput.js";

import { ResultSelectorFunction } from "./ResultSelectorFunction.js";

import { AbstractInput } from "./AbstractInput.js";


export class PickerInput extends AbstractInput {

	constructor(props) {

		super(props);

		//console.log("Picker input:", props);

		// Default value

		this.emptyValue = { options: this.state.value.options, selected: [] };

		if (this.state.value === null) {

			this.state.value = { options: [], selected: [] };

		} else {

			//console.log("Picker input:", this.state.value);

		}

	}

	selectElements = (elements) => {

		if (elements.length > 0) {

			let newValue = this.state.value;

			newValue.selected = elements;

			this.setValue(newValue);

		} else {

			this.cleanup({ options: this.state.value.options, selected: [] });

		}

	}


	render() {

		return (

			<DecoratedInput id={this.id} label={this.label} selector={

				<ResultSelectorFunction
					id={this.id}
					visible={this.state.visible}
					onChange={this.setVisible}
				></ResultSelectorFunction>
			}>

				<CheckPicker
					sticky
					style={{ width: "100%", padding: "0px" }}
					cleanable={true}
					data={this.state.value.options}
					onChange={this.selectElements}
					value={this.state.value.selected}
				/>
			</DecoratedInput>
		);

	}

}
