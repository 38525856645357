import axios from "axios";

import {
	API_ENDPOINT
} from "../config/config.js";


export async function details(track, vehicle) {
	
	let report;
		
	//console.log(tracks, vehicle);

	let uri = API_ENDPOINT+'/details';

	//let uri = 'https://era.linkeddata.es/api/details';

	let parameters = {
			"track_uri": track,
			"vehicle_uri": vehicle
		};

	console.log("[API] "+uri)

	let t0 = new Date()
	
	let response = await axios.post(uri, parameters);

	console.log('Compatibility report took (API)', new Date() - t0, 'ms');

	report = response.data;

    return report;
}

export async function route(op_start, op_end, via) {
	
	let report;
		
	//console.log(tracks, vehicle);

	let uri = API_ENDPOINT+'/details';

	//let uri = 'https://era.linkeddata.es/api/details';

	let parameters = {
			"track_uri": track,
			"vehicle_uri": vehicle
		};

	console.log("[API] "+uri)

	let t0 = new Date()
	
	let response = await axios.post(uri, parameters);

	console.log('Compatibility report took (API)', new Date() - t0, 'ms');

	report = response.data;

    return report;
}

export async function compatibility(tracks, vehicles) {
	
	let report;
		
	//console.log(tracks, vehicle);

	let uri = API_ENDPOINT+'/details';

	//let uri = 'https://era.linkeddata.es/api/details';

	let parameters = {
			"track_uri": track,
			"vehicle_uri": vehicle
		};

	console.log("[API] "+uri)

	let t0 = new Date()
	
	let response = await axios.post(uri, parameters);

	console.log('Compatibility report took (API)', new Date() - t0, 'ms');

	report = response.data;

    return report;
}
