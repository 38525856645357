import React from "react";

import {
	SelectPicker
} from "rsuite";

import { DecoratedInput } from "./DecoratedInput.js";

import { ResultSelectorFunction } from "./ResultSelectorFunction.js";

import { AbstractInput } from "./AbstractInput.js";


export class SelectInput extends AbstractInput {

	constructor(props) {

		super(props);

		//console.log("Object selector:", this.state.value);

		// Default value

		if (this.state.value === null) {

			this.state.value = { options: [], selected: [] };

		}

	}

	selectElements = (elements) => {

		let newValue = this.state.value;

		newValue.selected = elements;

		this.setValue(newValue);

	}


	render() {

		return (

			<DecoratedInput id={this.id} label={this.label} selector={null}>

				<SelectPicker
					style={{ width: "100%", padding: "0px" }}
					cleanable={true}
					data={this.state.value.options}
					onChange={this.selectElements}
					value={this.state.value.selected}
				/>

			</DecoratedInput>
		);

	}

}
