import React, { Component, useState, useEffect } from "react";

import { useCookieState } from "use-cookie-state";

import { useCookies } from "react-cookie";

import { Icon } from "@rsuite/icons";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import {
  Container,
  Divider,
  Button,
  Input,
  SelectPicker,
  DatePicker,
  InputNumber,
  InputGroup,
  Checkbox,
  CheckPicker,
  Panel,
  FlexboxGrid,
  PanelGroup,
  TagGroup,
  Tag,
  Loader,
  Modal,
  ButtonToolbar,
  IconButton,
  Notification,
  Message,
  useToaster,
} from "rsuite";

import { EraIcon } from "../../styles/Icon.js";

// Lang

import { getCurrentLanguage, getPhrase } from "../../utils/Languages.js";

// Dev

import QUDT from "./semantic/units.nt";

// Permalink

import queryString from "query-string";

// Main elements

import { SearchForm } from "./SearchForm.js";
import { SearchSummary } from "./SearchSummary.js";
import { QueryBuilder } from "./QueryBuilder.js";
import { QueryViewer } from "./QueryViewer.js";
import { SearchResult } from "./SearchResult.js";
import { ActionButtons } from "./MainComponents/ActionButtons.js";
import { ResultButtons } from "./MainComponents/ResultButtons.js";
import { Export } from "./Export.js";

// Web workers for background computing and requests

import WebworkerPromise from "webworker-promise";
import { loadFormDefinition } from "./FormDefinitionLoader.js";

// Form YAML definition

import formDefinitionFile from "./FormDefinition.yml";

// SPARQL queries

import load_ontology_query from "./queries/load_ontology.sparql";
import load_country_SKOS_query from "./queries/load_SKOS_countries.sparql";

//import { format } from "sparql-formatter";

import {
  SPARQL_ENDPOINT,
  NAMED_KG_ERA_VOCABULARY,
  NAMED_KG_ERA_SKOS,
  NAMED_KG_ERA_RINF,
} from "../../config/config.js";

//import "../../styles/Override.css";

import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";

import { Translation } from "react-i18next";

export default class Search extends Component {
  constructor(props) {
    super(props);

    //console.log(props.t);

    this.t = props.t;

    this.state = {
      formDefinition: {},

      status: {
        loaded: false,
        searched: false,
        searching: false,
        exporting: false,
        error: false,
      },

      searchQuery: { query: { visible: {}, value: {} }, rootObject: null },
      searchResults: {},

      resultsView: "table",

      toast: null,
    };

    this.toastStyle = {
      backgroundColor: "#rgb(250,250,250)",
      border: "1px solid rgb(187, 187, 187)",
    };

    this.ref = {};

    this.ref.searchLoader = React.createRef();
    this.ref.searchResult = React.createRef();

    (this.state.language = window.localStorage.getItem("language") || "en"),
      (this.store = new WebworkerPromise(
        new Worker(new URL("./workers/DataStore.worker.js", import.meta.url))
      ));
    this.executor = new WebworkerPromise(
      new Worker(new URL("./workers/QueryExecuter.worker.js", import.meta.url))
    );
  }

  // Component livecycle functions

  async componentDidMount() {
    await this.initialize();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { language, currentActivity, justLoadedParams } = this.state;

    const storedLanguage = getCurrentLanguage();

    if (language !== storedLanguage) {
      this.setState({ language: storedLanguage });

      await this.initialize();
    }
  }

  async initialize() {
    this.setState({
      status: { loaded: false, searching: false, searched: false },
    });

    if (
      !(
        (await this.loadOntology()) &&
        (await this.loadOntologyExternal()) &&
        (await this.loadCountrySKOS())
      )
    ) {
      //console.log("ERROR INITIALIZING APP!");

      this.setState({ status: { loaded: false, error: true } });

      return false;
    }

    //console.log("Loading form definition...")

    let definition = await loadFormDefinition(formDefinitionFile, this.store);

    ({ formDefinition: this.formDefinition, skosValues: this.skosValues } =
      definition);

    // Element modification tracking for debug (may 2024)

    //console.log(this.formDefinition[0].elements["d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787"].value);

    /*let debugValue = new Proxy(this.formDefinition[0].elements["d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787"], {
		  set: function (target, key, value) {
			  console.trace();
			  //console.log(`${key} set to ${value}`);
			  target[key] = value;
			  return true;
		  }
		});*/

    //this.formDefinition[0].elements["d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787"] = debugValue;

    //console.log("Done loading form definition!");

    //console.log("Form definition:", this.formDefinition);

    this.QueryBuilder = new QueryBuilder(this.formDefinition, this.store);

    if (this.loadPermalink()) {
      await this.onSearch();
    } else {
      this.setState({
        status: { loaded: true, searching: false, searched: false },
      });
    }
  }

  // Permalink logic

  loadPermalink = () => {
    try {
      if (this.props.location.hash !== "" && this.props.location.hash !== "#") {
        let searchQuery = JSON.parse(
          decodeURIComponent(
            escape(window.atob(this.props.location.hash.replace("#", "")))
          )
        );

        //console.log("[PERMALINK]:", searchQuery);

        if (this.QueryBuilder.validate_search_parameters(searchQuery)) {
          this.setState({ searchQuery: searchQuery });

          return true;
        } else {
          toast.info("The permalink provided is no longer valid.", {
            toastId: "onWrongPermalink",
            style: this.toastStyle,
          });

          //console.log(toast);
        }
      }
    } catch (e) {
      toast.info("The permalink provided is no longer valid.", {
        toastId: "onWrongPermalink",
        style: this.toastStyle,
      });

      //console.log(e);
    }

    return false;
  };

  createPermalink = (state) => {
    const query = window.btoa(
      unescape(encodeURIComponent(JSON.stringify(state)))
    );

    //console.log("[PERMALINK]:", query, state);

    window.location.hash = query;
  };

  // State interface for children components

  onSearchFormChange = async (query, rootObject) => {
    //console.log("[MAIN] Query updated:", query);

    this.setState({ searchQuery: { query: query, rootObject: rootObject } });

    this.setState({
      status: { loaded: true, searching: false, searched: false },
    });

    this.createPermalink({ query: query, rootObject: rootObject });

    //await this.search();
  };

  // Reset form values to default

  onClear = () => {
    let emptyQuery = { query: { visible: {}, value: {} }, rootObject: null };

    this.setState({ searchQuery: emptyQuery });

    this.setState({
      status: { loaded: true, searching: false, searched: false },
    });

    window.location.hash = "";

    toast.info("All search values reset to default", {
      toastId: "onClear",
      style: this.toastStyle,
    });
  };

  // Query build and execution

  onSearch = async () => {
    this.setState({
      status: { loaded: true, searching: true, searched: false },
    });

    if (this.ref.searchResult.current) {
      this.ref.searchResult.current.scrollIntoView({ behavior: "smooth" });
    }

    //console.log(this.state.searchQuery);

    let [select_query, construct_query] =
      await this.QueryBuilder.generate_query(
        this.state.searchQuery.query,
        this.state.searchQuery.rootObject
      );

    //console.log(this.formDefinition[0].elements["d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787"].value);

    this.setState({
      sparqlQuery: { select: select_query, construct: construct_query },
    });

    const result = await this.executor.exec("select_query", {
      data: { endpoint: SPARQL_ENDPOINT, query: select_query },
    });

    //console.log(result, result?.bindings?.length)

    if (result !== null && result !== undefined && result !== "") {
      this.setState({ searchResults: result });

      this.setState({
        status: { loaded: true, searching: false, searched: true },
      });

      if (this.ref.searchResult.current) {
        this.ref.searchResult.current.scrollIntoView({ behavior: "smooth" });
      }

      //console.log(this.formDefinition[0].elements["d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787"].value);
    } else {
      this.setState({
        status: { loaded: true, searching: false, searched: false },
      });

      toast.info("The served responded with an error, please try later.", {
        toastId: "onClear",
        style: this.toastStyle,
      });
    }
  };

  // Result representation

  onSparql = () => {
    this.setState({ status: { ...this.state.status, viewSparqlQuery: true } });
  };

  onSparqlClose = () => {
    this.setState({ status: { ...this.state.status, viewSparqlQuery: false } });
  };

  // Export

  onExport = () => {
    //toast.info("Exporting search results...", {style: this.toastStyle});

    this.setState({ status: { ...this.state.status, exporting: true } });
  };

  onExportClose = () => {
    this.setState({ status: { ...this.state.status, exporting: false } });
  };

  // Load ontology and SKOS

  async loadCountrySKOS() {
    //console.log("Loading countries SKOS");

    let result;

    try {
      result = await this.store.exec("load_by_query", {
        data: { endpoint: SPARQL_ENDPOINT, query: load_country_SKOS_query },
      });

      return result;
    } catch (e) {
      return false;
    }
  }

  async loadOntology() {
    let result;

    try {
      let query = load_ontology_query
        .replaceAll("${{NAMED_KG_ERA_VOCABULARY}}", NAMED_KG_ERA_VOCABULARY)
        .replaceAll("${{NAMED_KG_ERA_SKOS}}", NAMED_KG_ERA_SKOS);

      result = await this.store.exec("load_by_query", {
        data: { endpoint: SPARQL_ENDPOINT, query: query },
      });

      return result;
    } catch (e) {
      return false;
    }
  }

  async loadOntologyExternal() {
    let result;

    try {
      result = await this.store.exec("load_by_data", { data: QUDT });

      return result;
    } catch (e) {
      return false;
    }
  }

  render() {
    let language = this.state.language;

    return (
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
          style={{ marginTop: "100px" }}
        />

        {/* MODALS */}

        {this.state.status.exporting && (
          <Export
            language={language}
            sparqlQuery={this.state.sparqlQuery}
            query={this.state.searchQuery}
            formDefinition={this.formDefinition}
            skosValues={this.skosValues}
            data={this.state.searchResults}
            isOpen={this.state.status.exporting}
            onClose={this.onExportClose}
          ></Export>
        )}

        {this.state.status.viewSparqlQuery && (
          <QueryViewer
            isOpen={this.state.status.viewSparqlQuery}
            onClose={this.onSparqlClose}
            data={this.state.sparqlQuery}
          ></QueryViewer>
        )}

        {!this.state.status.loaded && (
          <Panel
            style={{
              margin: "35vh 2rem 35vh 2rem",
              borderRadius: "0px",
              borderTop: "2px solid #bbb",
              borderBottom: "2px solid #bbb",
            }}
          >
            {!this.state.status.error && (
              <Loader
                style={{ width: "200px", marginLeft: "calc(50% - 100px)" }}
                vertical
                content="Loading form definition..."
                size="md"
              />
            )}
            {this.state.status.error && (
              <Message type="error">
                An error was found loading the App resources, please try again
                later.
              </Message>
            )}
          </Panel>
        )}

        {this.state.status.loaded && (
          <SearchForm
            formDefinition={this.formDefinition}
            language={this.state.language}
            formValues={this.state.searchQuery}
            onChange={this.onSearchFormChange}
            //t={this.t}
          ></SearchForm>
        )}

        {this.state.status.loaded &&
          this.state.searchQuery.rootObject !== null && (
            <SearchSummary
              formDefinition={this.formDefinition}
              language={this.state.language}
              formValues={this.state.searchQuery}
              skosValues={this.skosValues}
            />
          )}

        {this.state.status.loaded && (
          <ActionButtons
            onSearch={this.onSearch}
            onClear={this.onClear}
            onExport={this.onExport}
            onSparql={this.onSparql}
            search_disabled={
              this.state.status.loading ||
              this.state.status.searching ||
              this.state.searchQuery.rootObject === null
            }
            export_disabled={!this.state.status.searched}
            search_working={this.state.status.searching}
            export_working={this.state.status.exporting}
            language={this.state.language}
          ></ActionButtons>
        )}

        {this.state.status.searching && (
          <Panel
            style={{
              margin: "2rem 2rem 20rem 2rem",
              borderRadius: "0px",
              borderTop: "2px solid #bbb",
              borderBottom: "2px solid #bbb",
            }}
          >
            <Loader
              style={{ width: "200px", marginLeft: "calc(50% - 100px)" }}
              vertical
              content="Performing search query..."
              size="md"
            />
          </Panel>
        )}

        <div ref={this.ref.searchLoader} style={{ display: "hidden" }}>
          {" "}
        </div>

        {this.state.status.searched && (
          <Panel
            style={{
              margin: "0rem 2rem",
              borderRadius: "0px",
              borderTop: "2px solid #bbb",
            }}
            header={<h3>{getPhrase("searchResult", language)}</h3>}
          >
            <SearchResult
              language={language}
              query={this.state.searchQuery}
              formDefinition={this.formDefinition}
              skosValues={this.skosValues}
              data={this.state.searchResults}
            />
          </Panel>
        )}

        <div ref={this.ref.searchResult} style={{ display: "hidden" }}>
          {" "}
        </div>
      </Container>
    );
  }
}
