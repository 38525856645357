

export class DateQuery {

	constructor(element, value) {

		//console.log("DateQuery:", element, value);

		this.element = element;

		this.value = value;

	}

	build_filter = () => {

		let filter = [];

		let date = new Date(this.value).toISOString().slice(0, 10);;

		if (this.value.range === true) {

			//filter.push(`FILTER(regex(?${this.element.id}, "${this.value.text.replace('"', '\"')}", "ix" ))`);

		} else {

			filter.push(`FILTER(?${this.element.id} = xsd:date("${date}"))`);

		}

		return filter;

	}

	build_extra_patterns = () => {

		let patterns = [];

		return patterns;

	}

	build_extra_vars = () => {

		let vars = [];

		return vars;

	}

}
