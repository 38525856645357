import React from "react";
import {
	Button,
	Container
} from "rsuite";

import {
	marker
} from '../../../styles/Styles.js';

import ReactMapboxGl, {
	ZoomControl,
	Marker,
	Layer,
	Feature
} from "react-mapbox-gl";

const MapBox = ReactMapboxGl({
	accessToken:
		"pk.eyJ1Ijoic3VzaGlsZ2hhbWJpciIsImEiOiJjazUyZmNvcWExM2ZrM2VwN2I5amVkYnF5In0.76xcCe3feYPHsDo8eXAguw"
});

export const CreateSubscriptionGeographicalRegion = (props) => {

	const [point1, setPoint1] = React.useState();
	const [point2, setPoint2] = React.useState();


	const zoom = [5];

	const lineStyle = {
		"line-color": ['get', 'color'],
		"line-width": 2,
		"line-dasharray": [1, 1]
	}

	/*if(this.state.value === null){

		this.state.value = {type: "box", lngLat: []}; // 2 points

	}else{

		this.center = this.state.value.lngLat;

	}*/

	const [lngLat, setLngLat] = React.useState([]);

	const [center, setCenter] = React.useState([4.360854, 50.859658]);
	const [box, setBox] = React.useState([]);
	const [markers, setMarkers] = React.useState([]);


	/*React.useEffect(() => {
		
		//setOpen(props.open);
		
		//console.log(props);
		
	}, [props]);*/

	const updateMap = () => {

		let markers = [];
		let lines = [];

		//let oldState = this.state.mapFeatures;


		for (let element of lngLat) {

			markers.push(<Feature key={element} coordinates={element} />);

		}

		if (lngLat.length == 2) {

			let lngLat1 = lngLat[0];
			let lngLat2 = lngLat[1];

			let maxLng = Math.max(lngLat2[0], lngLat1[0]);
			let minLng = Math.min(lngLat2[0], lngLat1[0]);
			let maxLat = Math.max(lngLat2[1], lngLat1[1]);
			let minLat = Math.min(lngLat2[1], lngLat1[1]);

			let boxLngLat1 = [maxLng, maxLat];
			let boxLngLat2 = [maxLng, minLat];
			let boxLngLat4 = [minLng, maxLat];
			let boxLngLat3 = [minLng, minLat];

			lines.push(<Feature key={[boxLngLat1, boxLngLat2]} coordinates={[boxLngLat1, boxLngLat2]} />);
			lines.push(<Feature key={[boxLngLat2, boxLngLat3]} coordinates={[boxLngLat2, boxLngLat3]} />);
			lines.push(<Feature key={[boxLngLat3, boxLngLat4]} coordinates={[boxLngLat3, boxLngLat4]} />);
			lines.push(<Feature key={[boxLngLat4, boxLngLat1]} coordinates={[boxLngLat4, boxLngLat1]} />);

		}

		//console.log("MAP update", lines, markers);

		setBox(lines);
		setMarkers(markers);

	}


	const mapClick = (map, event) => {

		//console.log(event.lngLat);

		let lngLatClick = [event.lngLat.lng, event.lngLat.lat];

		if (lngLat.length == 0) { // First point

			let tmp = lngLat;

			tmp.push(lngLatClick);

			setLngLat(tmp);

			updateMap();

			return;

		}

		if (lngLat.length == 1) { // Second point

			let tmp = lngLat;

			tmp.push(lngLatClick);

			setLngLat(tmp);

			updateMap();

			props.onChange(tmp);

			return;


		}

		if (lngLat.length == 2) { // Reset

			setLngLat([lngLatClick]);

			updateMap();



			return;

		}

	}


	return (



		<MapBox
			containerStyle={{ height: "100%", width: '100%' }}
			style="mapbox://styles/mapbox/light-v10"
			center={center}
			zoom={zoom}
			antiAlias={false}
			//onStyleLoad={this.updateMap}
			onClick={mapClick}
		>

			<Layer
				type="line"
				paint={lineStyle}
			>
				{box}
			</Layer>


			<Layer
				type="symbol"
				layout={{ "icon-image": "marker" }}
				images={['marker', marker]}
			>
				{markers}
			</Layer>
			<ZoomControl position={'top-right'} />
		</MapBox>


	);

}
