import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Header,
  Content,
  Footer,
  Panel,
  Loader,
  Message,
  Tag,
  Timeline,
} from "rsuite";

import "rsuite/dist/rsuite.min.css";
import "./index.css";

import NavHeader from "./Header.js";
import NavFooter from "./Footer.js";

import { getUserInfo, getUserRoles } from "../../azure/MSGraphService.js";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import {
  ToastContainer,
  toast,
  Slide,
  Zoom,
  Flip,
  Bounce,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  NAMED_KG_RINF,
  NAMED_KG_ERATV,
  NAMED_KG_ERA_VOCABULARY,
  NAMED_KG_ERA_SKOS,
  NAMED_KG_ERA_SHACL,
  SPARQL_ENDPOINT,
  BASE_URI,
} from "../../config/config.js";

// Apps as components

import Landing from "../Landing/Main.js";
import { Vocabulary } from "../Vocabulary/Main.js";
import DataStories from "../DataStories/Main.js";
import Endpoint from "../Endpoint/Main.js";
import Search from "../Search/Main.js";
import RouteCompatibility from "../RouteCompatibility/Main.js";
import { MapExplorer } from "../MapExplorer/Main.js";
import { Describe } from "../Describe/Main.js";

import { NotificationsManager } from "../NotificationsManager/Main.js";
import { DatasetManagement } from "../DatasetManagement/Main.js";
//import { TestComponent } from "../TestComponent/Main.js";

// Azure provider

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./../../azure/authConfig.js";

const msalInstance = new PublicClientApplication(msalConfig);

// Other context providers

export const AuthContext = createContext(null);
export const TranslationContext = createContext(null);
export const ThemeContext = createContext(null);
export const LocationContext = createContext(null);
export const ToolsContext = createContext(null);

// App root component

function DebugAzure() {
  const { auth, setRole, auth_debug } = useContext(AuthContext);

  return (
    <Container>
      <h3>Azure debug data</h3>
      <pre>{JSON.stringify(auth, null, "  ")}</pre>
      <pre>{JSON.stringify(auth_debug, null, "  ")}</pre>
    </Container>
  );
}

function Root() {
  //console.log("Initializing app");

  // Webpack populated
  const bundle = ENV;

  console.groupCollapsed("Bundle information:");

  for (let v of Object.keys(bundle)) {
    console.info(v + ": " + bundle[v]);
  }

  console.groupEnd();

  console.groupCollapsed("Env information:");

  console.info("Named graphs: ", [
    NAMED_KG_RINF,
    NAMED_KG_ERATV,
    NAMED_KG_ERA_VOCABULARY,
    NAMED_KG_ERA_SKOS,
    NAMED_KG_ERA_SHACL,
  ]);

  console.info("SPARQL endpoint:", SPARQL_ENDPOINT);

  console.groupEnd();

  return (
    <MsalProvider instance={msalInstance}>
      <ContextsWrapper />
    </MsalProvider>
  );
}

// Context wrapper

function ContextsWrapper() {
  // Azure

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // Auth / role

  const [auth, setAuth] = useState();
  const [auth_debug, setAuthDebug] = useState();

  // Translation

  const [translation, setTranslation] = useState();

  // Theme

  const [theme, setTheme] = useState();

  // Tools

  const notify = (notification_type, notification_text) => {
    const method = {
      success: toast.success,
      info: toast.info,
      warn: toast.warn,
      error: toast.error,
    };

    console.log(notification_type, notification_text);

    if (method[notification_type] !== undefined) {
      method[notification_type](notification_text, {
        position: "top-center",
        autoClose: 10000,
        pauseOnFocusLoss: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
  };

  // Location

  const setRole = (role) => {
    const newAuth = { ...auth };

    //console.log("Role change", newAuth.info.activeRole, "->", role);

    sessionStorage.setItem("activeRole", role);

    newAuth.info.activeRole = role;

    setAuth(newAuth);
  };

  const fetchUserInfo = async (setAuth) => {
    //console.log(inProgress);

    if (inProgress == "none") {
      if (isAuthenticated) {
        //console.log("User auth");

        try {
          let userInfoRequest = await getUserInfo(instance);

          let userRolesRequest = await getUserRoles(instance);

          let activeRole = userRolesRequest.data.value[0]?.appRoleId;

          if (sessionStorage.getItem("activeRole") !== null) {
            activeRole = sessionStorage.getItem("activeRole");
          }

          setAuth({
            auth: true,
            info: {
              userInfo: userInfoRequest.data,
              userRoles: userRolesRequest.data,
              activeRole: activeRole,
            },
          });

          setAuthDebug({});
        } catch (error) {
          //console.log("Auth error: ", error);

          // Handle cleanup for some token expiration cases

          const accounts = instance.getAllAccounts();

          if (accounts.length > 0) {
            instance.logoutRedirect({
              onRedirectNavigate: (url) => {
                return false;
              },
            });
          }

          setAuth({ auth: false });
        }

        //console.log(userRolesRequest);
      } else {
        //console.log("User not auth");

        setAuth({ auth: false });
      }
    }

    return auth;
  };

  useEffect(() => {
    fetchUserInfo(setAuth);
  }, [isAuthenticated, inProgress]);

  // Pending MSAL lib update to test intertab auth with logut events

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.ACCOUNT_REMOVED) {
        const result = message.payload;
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setRole, auth_debug }}>
      <TranslationContext.Provider value={{ translation, setTranslation }}>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <ToolsContext.Provider value={{ notify }}>
            <Router>
              <App />
            </Router>
          </ToolsContext.Provider>
        </ThemeContext.Provider>
      </TranslationContext.Provider>
    </AuthContext.Provider>
  );
}

// App main elements

function App() {
  const debugMode = localStorage.getItem("debugMode") === "1";
  const [ready, setReady] = useState();
  const [maintenance, setMaintenance] = useState();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [maintenanceInfo, setMaintenanceInfo] = useState({});

  // There is a new endpoint that will return the next maintenance date and reason
  // For example {"current_time":"2024-09-04T11:10:46Z","next_maintenance_event":{"end_time":"2023-03-01T10:00:00Z","reason":"Server reboot","start_time":"2023-03-01T08:00:00Z"}}

  const apiEndpoint = BASE_URI + "/api/maintenance";

  const fetchMaintenanceStatus = async () => {
    try {
      const response = await fetch(apiEndpoint);
      if (!response.ok) {
        throw new Error("Network error");
      }
      const data = await response.json();

      //console.log(data)

      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  useEffect(() => {
    let intervalId;

    const checkMaintenanceStatus = async () => {
      const maintenanceData = await fetchMaintenanceStatus();

      if (
        maintenanceData &&
        maintenanceData.next_maintenance_event &&
        !debugMode
      ) {
        let lang = navigator.language || "de-DE";
        let formatter = new Intl.DateTimeFormat(lang, {
          dateStyle: "short",
          timeStyle: "long",
        });

        const startTime = new Date(
          maintenanceData.next_maintenance_event.start_time
        );
        const endTime = new Date(
          maintenanceData.next_maintenance_event.end_time
        );
        const currentTime = new Date(maintenanceData.current_time);

        setMaintenanceInfo({
          start_time: formatter.format(startTime),
          end_time: formatter.format(endTime),
          reason: maintenanceData.next_maintenance_event.reason,
        });

        if (currentTime >= startTime && currentTime <= endTime) {
          setMaintenance(true);
        } else {
          setMaintenance(false);
        }
      } else {
        setMaintenance(false);
      }

      intervalId = setTimeout(checkMaintenanceStatus, 60 * 1000); // 1 minute
    };

    checkMaintenanceStatus();

    return () => {
      clearTimeout(intervalId);
    };
  }, []);

  useEffect(() => {
    if (auth !== undefined) {
      setReady(true);
    }
  }, [auth]);

  const prevMaintenance = useRef(maintenance);

  useEffect(() => {
    if (
      (maintenance === true && prevMaintenance.current === false) ||
      (maintenance === false && prevMaintenance.current === true)
    ) {
      window.location.reload();
    }
    prevMaintenance.current = maintenance;
  }, [maintenance]);

  return (
    <>
      <ToastContainer />
      {maintenance && (
        <Container>
          <Content style={{ height: "100vh" }}>
            <Panel style={{ margin: "calc(50vh - (300px/2)) 2rem" }}>
              <Message
                showIcon
                type="warning"
                header={<h5>Services are under maintenance</h5>}
              >
                <br />
                <p>
                  <b>Reason:</b> {maintenanceInfo.reason}
                </p>
                <br />
                <Timeline>
                  <Timeline.Item>
                    {maintenanceInfo.start_time}{" "}
                    <b>Maintenance planned start</b>
                  </Timeline.Item>
                  <Timeline.Item>
                    {maintenanceInfo.end_time} <b>Maintenance planned end</b>
                  </Timeline.Item>
                </Timeline>
                <br />
                <p>
                  <i>
                    This information is merely indicative, the time required may
                    be shorter or require extension.
                  </i>
                </p>
              </Message>
            </Panel>
          </Content>
        </Container>
      )}

      {ready && maintenance === false && (
        <Container>
          <Header>
            <NavHeader />
          </Header>
          <Content style={{ minHeight: "calc(100vh - 150px)" }}>
            <LocationContext.Provider value={{ location }}>
              <Switch>
                <Route exact path="/" component={Landing}></Route>
                <Route exact path="/debug-azure" component={DebugAzure}></Route>
                {/*<Route exact_path="/test-component" component={TestComponent}></Route>*/}
                <Route exact path="/describe" component={Describe}></Route>
                <Route
                  exact
                  path="/data-stories"
                  component={DataStories}
                ></Route>
                <Route exact path="/vocabulary" component={Vocabulary}></Route>
                <Route exact path="/endpoint" component={Endpoint}></Route>
                <Route exact path="/search" component={Search}></Route>
                <Route
                  exact
                  path="/map-explorer"
                  component={MapExplorer}
                ></Route>
                <Route
                  exact
                  path="/route-compatibility"
                  component={RouteCompatibility}
                ></Route>
                <Route
                  exact
                  path="/notifications-manager"
                  component={NotificationsManager}
                ></Route>
                <Route
                  exact
                  path="/dataset-manager"
                  component={DatasetManagement}
                ></Route>
                <Redirect to="/" />
              </Switch>
            </LocationContext.Provider>
          </Content>
          <Footer>
            <NavFooter />
          </Footer>
        </Container>
      )}

      {!ready && !maintenance && (
        <Container>
          <Content style={{ height: "100vh" }}>
            <Panel
              style={{
                margin: "calc(50vh - (126px/2)) 2rem",
                borderRadius: "0px",
                borderTop: "2px solid #bbb",
                borderBottom: "2px solid #bbb",
              }}
            >
              <Loader
                style={{ width: "200px", marginLeft: "calc(50% - 100px)" }}
                vertical
                content="Initializing app..."
                size="md"
              />
            </Panel>
          </Content>
        </Container>
      )}
    </>
  );
}

export default Root;
