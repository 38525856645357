import { saveAs } from "file-saver";
import { some, xor } from "lodash";

import { SPARQL_ENDPOINT, NAMED_KG_RINF } from "../../../config/config.js";

import WebworkerPromise from "webworker-promise";

import OPFullQuery from "../queries/all_parameters_op.sparql";
import SoLFullQuery from "../queries/all_parameters_sol.sparql";

import JSZip from "jszip";

// We use the list of OP or SoL to export all the information related

const readme =
  "### RINF EXPORT ###\n\n" +
  "This data export is available as a compressed zip file with several files that contain parts of the data, so that you can merge them in your own device.\n" +
  "Note that merging NTriples files can be done by concatenating all files together, but merging JSON-LD and RDF/XML files needs some further processing.\n" +
  "For example, you can use CLI tools like 'riot' provided by Jena: https://jena.apache.org/documentation/io/ or libraries as rdflib for Python https://rdflib.readthedocs.io/en/stable/intro_to_parsing.html\n";

class BaseExport {
  constructor(data_elements) {
    this.executor = new WebworkerPromise(
      new Worker(
        new URL("../workers/QueryExecuterExport.worker.js", import.meta.url)
      )
    );

    this.tasks = [];

    for (let data of data_elements) {
      let task = { entity_list: data.elements };

      if (data.type === "http://data.europa.eu/949/OperationalPoint") {
        task.query = OPFullQuery;
        task.query = task.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);
        task.entity_name = "operational_points";
      }

      if (data.type === "http://data.europa.eu/949/SectionOfLine") {
        task.query = SoLFullQuery;
        task.query = task.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);
        task.entity_name = "sections_of_line";
      }

      this.tasks.push(task);
    }
  }

  cancel() {
    this.cancelled = true;
  }

  async processTask(task, zipfile) {
    let part_size = 1000;
    let done = 0;
    let retry_count = 0;

    while (done < task.entity_list.length && !this.cancelled) {
      if (retry_count >= 3) {
        console.error("Unable to fetch required data, retry exceeded.");
        return false;
      }

      let entity_part = task.entity_list.slice(done, done + part_size);
      let query_part = "<" + entity_part.join("> <") + ">";
      let query = task.query.replace("${{entity_list}}", query_part);

      try {
        let result = await this.executor.exec("construct_query", {
          data: { endpoint: SPARQL_ENDPOINT, query: query, format: this.mime },
        });

        if (result !== null) {
          if (typeof result === "object") {
            result = JSON.stringify(result, null, 2);
          }
          zipfile.file(
            "parts/" +
              task.entity_name +
              "_" +
              done +
              "_to_" +
              (done + part_size) +
              this.extension,
            result
          );

          //full_data.push(result);

          done += part_size;
          retry_count = 0;
        } else {
          retry_count += 1;
        }
      } catch (error) {
        console.error("Unable to fetch required data", error);
        retry_count += 1;
      }
    }

    return true;
  }

  async generateFile() {
    let zipfile = new JSZip();

    zipfile.file("README.txt", readme);

    let task_result;

    for (let task of this.tasks) {
      task_result = await this.processTask(task, zipfile);

      if (!task_result) {
        return false;
      }
    }

    if (!this.cancelled) {
      let promise;

      if (JSZip.support.uint8array) {
        promise = zipfile.generateAsync({ type: "uint8array" });
      } else {
        promise = zipfile.generateAsync({ type: "string" });
      }

      const blob = new Blob([await promise], { type: "application/zip" });

      let date = new Date()
        .toISOString()
        .replaceAll(":", "_")
        .replaceAll("-", "_")
        .slice(0, 19);

      saveAs(
        blob,
        "rinf_export_" +
          this.origin +
          "_" +
          this.format_name +
          "_" +
          date +
          ".zip"
      );

      return true;
    } else {
      return false;
    }
  }
}

export class NTFullExport extends BaseExport {
  constructor(data_elements) {
    super(data_elements);
    this.format_name = "ntriples";
    this.origin = "map-explorer";
    this.extension = ".nt";
    this.mime = "application/n-triples";
  }
}

export class RDFFullExport extends BaseExport {
  constructor(data_elements) {
    super(data_elements);
    this.format_name = "rdf-xml";
    this.origin = "map-explorer";
    this.extension = ".rdf";
    this.mime = "application/rdf+xml";
  }
}

export class JSONLDFullExport extends BaseExport {
  constructor(data_elements) {
    super(data_elements);
    this.format_name = "json-ld";
    this.origin = "map-explorer";
    this.extension = ".jsonld";
    this.mime = "application/ld+json";
  }
}
