import React, {useEffect} from "react";
import {
	Input,
	FlexboxGrid,
	Toggle,
	ButtonGroup,
	Button
} from "rsuite";

import { DecoratedInput } from "./DecoratedInput.js";

import { ResultSelectorFunction } from "./ResultSelectorFunction.js";


export const BooleanInputFunction = (props) => {
	
	const [id, setId] = React.useState(props.id);
	const [label, setLabel] = React.useState(props.label);
	const [value, setValue] = React.useState(props.value || undefined);
	const [visible, setVisible] = React.useState(props.visible || false);
	const [invisible, setInvisible] = React.useState(props.invisible || false);

	
	useEffect(() => {
		
		if (props.visible != visible){
		
			setVisible(props.visible);
			
		}
		
	}, [props.visible]);

	const onChangeVisible = (newVisible) => {
		
		setVisible(newVisible)

		props.onChange(id, {value: value, visible: newVisible}, "visibility");
		
	}

	const onChangeValue = (newValue) => {
		
		setValue(newValue);
		
		if(newValue === undefined){

			props.onClean(id, newValue);
			
		}else{
			
			props.onChange(id, {value: newValue, visible: visible}, "value");
			
		}
		
	}

	return(

			<DecoratedInput id={id} label={label} invisible = {invisible} selector={

				<ResultSelectorFunction
					id={id}
					visible={visible}
					onChange={onChangeVisible}
				>
				</ResultSelectorFunction>
				
				}>

				 <ButtonGroup justified>
				  
					<Button
						appearance={value === true ? "primary" : "ghost"}
						onClick={() => {onChangeValue(true)}}
						color="green"
					>
						True
					</Button>
					
					<Button
						appearance={value === false ? "primary" : "ghost"}
						onClick={() => {onChangeValue(false)}}
						color="red"
					>
						False
					</Button>
					
					<Button
						appearance={value === undefined ? "primary" : "ghost"}
						onClick={() => {onChangeValue(undefined)}}
						color="blue"
					>
						Not defined
					</Button>
					
				  </ButtonGroup>
				  
			</DecoratedInput>
	);

	

}
