import React, { useEffect, useState } from "react";
import moment from 'moment';
import {
    Button,
    Uploader,
    SelectPicker,
    Steps,
    Form, IconButton,
    Input,
    InputGroup,
    Modal,
    Pagination, Popover, Radio,
    RadioGroup,
    Table, Whisper,
    Dropdown, Tooltip,
    Tag, Container, Panel, List, ButtonToolbar, Progress,
    Message
} from "rsuite";
import { Icon, More, Trash, Edit, Import } from "@rsuite/icons";
import { DatasetFileStatus, DatasetFileType, DatasetPipelines } from "../DatasetConstants.js";
import dateFormat from "dateformat";
const { Header, Title, Body, Footer } = Modal;
const { Column, HeaderCell, Cell, } = Table;
import { saveAs } from "file-saver";

import { EraIcon } from "../../../styles/Icon.js";



export const DeleteModal = ({ rowData, onDelete, showModal, setShowModal }) => {
    const [data, setData] = useState(rowData)

    useEffect(() => {
        setData(rowData)
    }, [rowData])

    function handleSubmit() {
        onDelete(data.id)
        setShowModal(false)
    }

    return (
        <Modal open={showModal}>
            <Header>
                <Title>Delete file</Title>
            </Header>
            <Body>
                <p>You are going to delete the following dataset file: <b> "{data?.fileName}" </b></p>

                <p>This action can't be undone.</p>

            </Body>
            <Footer>

                <Button onClick={() => setShowModal(false)} appearance="subtle">
                    Cancel
                </Button>

                <Button onClick={handleSubmit} color={"orange"} appearance="primary">
                    Confirm
                </Button>
            </Footer>
        </Modal>
    )
}

export const PublishModal = ({ rowData, onSubmit, showModal, setShowModal }) => {
    const [data, setData] = useState(rowData)

    useEffect(() => {
        setData(rowData)
    }, [rowData])

    function handleSubmit() {
        onSubmit(data.id)
        setShowModal(false)
    }

    return (
        <Modal open={showModal}>
            <Header>
                <Title>Publish Dataset</Title>
            </Header>
            <Body>
                You are going to Publish the following dataset

                "{data?.fileName}"

                Previous published datasets will be overrided.

            </Body>
            <Footer>
                <Button onClick={handleSubmit} appearance="primary">
                    Publish
                </Button>
                <Button onClick={() => setShowModal(false)} appearance="subtle">
                    Cancel
                </Button>
            </Footer>
        </Modal>
    )
}

export const RenameModal = ({ rowData, onUpdate, showModal, setShowModal }) => {
    const [newName, setNewName] = useState("")
    const [data, setData] = useState(rowData)

    useEffect(() => {
        setData(rowData)
    }, [rowData])

    function handleSubmit() {
        if (newName.trim().length !== 0) {
            onUpdate(data?.id, newName)
            setShowModal(false)
        }
    }

    function handleOnchangeName(name) {
        setNewName(name)
    }

    return (
        <>
            <Modal open={showModal}>
                <Header>
                    <Title>Rename Files</Title>
                </Header>
                <Body>
                    Enter new name for the file "{data?.fileName}"
                    <Input type="text" placeholder={"New name"}
                        onChange={handleOnchangeName}
                    />
                </Body>
                <Footer>
                    <Button onClick={handleSubmit} appearance="primary">
                        Confirm
                    </Button>
                    <Button onClick={() => setShowModal(false)} appearance="subtle">
                        Cancel
                    </Button>
                </Footer>
            </Modal>
        </>
    )
}


export const ActionCell = ({ dataType, rowData, onDelete, onDownload, onDownloadArtifact, onRename, onUpdate, ...props }) => {
    const [data, setData] = useState(rowData)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showRenameModal, setShowRenameModal] = useState(false)

    useEffect(() => {
        setData(rowData)
        //console.log(rowData)
    }, [rowData])

    function handleOnDelete(id) {
        onDelete(id)
        setShowDeleteModal(false)
    }
    function handleOnRename(id, newName) {
        onRename(id, newName)
        setShowRenameModal(false)
    }

    return (
        <>
            <Cell {...props} style={{ "padding": "2px" }}>


                <Whisper trigger="hover" placement={"top"} controlId={`control-id-top`}
                    speaker={<Tooltip>Download</Tooltip>}
                >

                    <IconButton appearance="subtle" icon={<EraIcon faName={"file-arrow-down"} style={null} />}
                        onClick={() => onDownload(data?.id)}
                    />
                </Whisper>

                <Whisper trigger="hover" placement={"top"} controlId={`control-id-top`}
                    speaker={<Tooltip>Rename</Tooltip>}
                >
                    <IconButton appearance="subtle" icon={<EraIcon faName={"pen-to-square"} style={null} />}
                        onClick={() => setShowRenameModal(true)}
                    />
                </Whisper>

                <Whisper trigger="hover" placement={"top"} controlId={`control-id-top`}
                    speaker={<Tooltip>Delete</Tooltip>}
                >
                    <IconButton appearance="subtle" icon={<EraIcon faName={"trash-can"} style={null} />}
                        onClick={() => setShowDeleteModal(true)}
                    />
                </Whisper>

                {/*<PipelineActions rowData={data} onUpdate={onUpdate} onDownloadArtifact={onDownloadArtifact} {...props}/>*/}

            </Cell>

            <DeleteModal rowData={data} onDelete={handleOnDelete}
                showModal={showDeleteModal} setShowModal={setShowDeleteModal}
            />
            <RenameModal rowData={data} onUpdate={handleOnRename}
                setShowModal={setShowRenameModal} showModal={showRenameModal}
            />
        </>
    );
};

export const DocumentsTable = ({ dataList, dataType, isLoading, onDelete, onDownload, onRename, onSelectPipeline }) => {
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [tableData, setTableData] = useState()

    useEffect(() => {
        setTableData(sortData(dataList, "dateCreated", "desc"))
    }, [dataList])

    function handleSortColumn(sortColumn, sortType) {
        setSortColumn(sortColumn)
        setSortType(sortType)
        setTableData(sortData(tableData, sortColumn, sortType))
    }

    function sortData(data, column, type) {
        if (!data) return

        if (!column || !type) {
            return data.slice();
        }

        return data.slice().sort((a, b) => {
            let x = a[column] || '';
            let y = b[column] || '';

            if (column === 'uploadDate') {
                x = x ? new Date(x).getTime() : x;
                y = y ? new Date(y).getTime() : y;
            }

            if (x === y) {
                return 0;
            }

            // Return negative value for ascending order
            if (type === 'asc') {
                return x < y ? -1 : 1;
            }

            // Return negative value for descending order
            if (type === 'desc') {
                return y < x ? -1 : 1;
            }
        });
    }

    function handleOnDownload(id) {
        onDownload(dataType, id)
    }
    function handleOnRdfDownload(s3Uri) {
        onDownload(dataType, null, s3Uri)
    }
    function handleOnDelete(id) {
        onDelete(dataType, id)
    }
    function handleOnRename(id, newName) {
        onRename(dataType, id, newName)
    }
    function handleOnSelectPipeline(id, pipelineStatus) {
        onSelectPipeline(id, pipelineStatus)
    }

    function humanFileSize(size) {

        let sizeText;

        if (size < 1024) {

            sizeText = size + " B";

        }

        if (size >= 1024 && size < (1024 * 1024)) {

            sizeText = (size / 1024).toFixed(2) + " KiB";

        }

        if (size >= (1024 * 1024) && size < (1024 * 1024 * 1024)) {

            sizeText = (size / (1024 * 1024)).toFixed(2) + " MiB";

        }

        if (size >= (1024 * 1024 * 1024)) {

            sizeText = (size / (1024 * 1024 * 1024)).toFixed(2) + " GiB";

        }

        return sizeText;

    }

    return (
        <>
            <Table
                loading={isLoading}
                autoHeight={true}
                data={tableData}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
            //rowHeight={52}
            >
                <Column flexGrow={2} align="left" sortable resizable>
                    <HeaderCell>Owner</HeaderCell>
                    <Cell dataKey="ownerName">
                        {
                            rowData => rowData.owner?.fullname
                        }
                    </Cell>
                </Column>




                <Column align="left" flexGrow={2} sortable resizable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="fileName" />
                </Column>

                <Column flexGrow={1} align="left" sortable resizable>
                    <HeaderCell>Size</HeaderCell>
                    <Cell dataKey="size">
                        {rowData => humanFileSize(rowData.size)}
                    </Cell>
                </Column>


                <Column flexGrow={2} align="left" sortable>
                    <HeaderCell>Upload date</HeaderCell>
                    <Cell dataKey="dateCreated">
                        {
                            rowData => {
                                if (rowData.dateCreated) {
                                    const date = new Date(rowData.dateCreated)
                                    return dateFormat(date, "dd/mm/yyyy - HH:MM:ss") + " (~" + moment(new Date(date)).fromNow() + ")";
                                }
                            }
                        }
                    </Cell>
                </Column>


                <Column width={200} align="center">
                    <HeaderCell>Actions</HeaderCell>
                    <ActionCell onDownload={handleOnDownload}
                        onDelete={handleOnDelete}
                        onRename={handleOnRename}
                        onDownloadArtifact={handleOnRdfDownload}
                        onUpdate={handleOnSelectPipeline}
                        dataType={dataType}
                    />
                </Column>

            </Table>

        </>
    )
}
