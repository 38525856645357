export class DateResult {
  constructor(element) {
    //console.log("DateResult:", element);

    this.element = element;

    // Pending proper reimplementation
  }

  transformData = (data) => {
    if (
      data.head.vars.includes(
        "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc"
      )
    ) {
      this.replaceId = {
        "7c96eb02cc9750d69a916f29575b273c86ce9518d958d287c9731997c4a6aaea":
          "21420099c867f513bf40f455b773c760230a25b15c96793eea00b35f4422c52c",

        "8e2de3335023201ffa4d803767bdf12790c58f4f241554c544bde8bf7b8455a4":
          "cb4697de428633203cbc8d23b25a0e14f766a9f827a75041ed91a00bef71cc3c",
      };
    } else {
      this.replaceId = {
        "7c96eb02cc9750d69a916f29575b273c86ce9518d958d287c9731997c4a6aaea":
          "d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787",

        "8e2de3335023201ffa4d803767bdf12790c58f4f241554c544bde8bf7b8455a4":
          "57619f8a7cd2a6cda338074ef730b7346dde511d0422eb4a614e2bc4eec8dfae",
      };
    }

    try {
      let lang = navigator.language || "de-DE";
      let formatter = new Intl.DateTimeFormat(lang);
      let newHead = [];

      /*

cb4697de428633203cbc8d23b25a0e14f766a9f827a75041ed91a00bef71cc3c (s)
21420099c867f513bf40f455b773c760230a25b15c96793eea00b35f4422c52c (e)

	  */

      for (let k of data.head.vars) {
        if (
          k ==
            "d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787" ||
          k ==
            "21420099c867f513bf40f455b773c760230a25b15c96793eea00b35f4422c52c"
        ) {
          newHead.push(
            "7c96eb02cc9750d69a916f29575b273c86ce9518d958d287c9731997c4a6aaea"
          );
        } else {
          if (
            k ==
              "57619f8a7cd2a6cda338074ef730b7346dde511d0422eb4a614e2bc4eec8dfae" ||
            k ==
              "cb4697de428633203cbc8d23b25a0e14f766a9f827a75041ed91a00bef71cc3c"
          ) {
            newHead.push(
              "8e2de3335023201ffa4d803767bdf12790c58f4f241554c544bde8bf7b8455a4"
            );
          } else {
            newHead.push(k);
          }
        }
      }

      data.head.vars = newHead;

      for (let row of data.results.bindings) {
        if (this.replaceId[this.element.id] && !row[this.element.id]) {
          let fixedId = this.replaceId[this.element.id];

          let raw_value = row[fixedId].value.split("-");

          let value;

          if (raw_value[0] == 0 && raw_value[1] == 0 && raw_value[2] == 0) {
            value = "-";
          } else {
            value = formatter.format(
              new Date(raw_value[0], raw_value[1] - 1, raw_value[2])
            );
          }

          row[this.element.id] = { type: "date", value: value };
        } else {
          if (row[this.element.id]) {
            let raw_value = row[this.element.id].value.split("-");
            let value;

            if (raw_value.length == 3) {
              if (raw_value[0] == 0 && raw_value[1] == 0 && raw_value[2] == 0) {
                value = "-";
              } else {
                value = formatter.format(
                  new Date(raw_value[0], raw_value[1] - 1, raw_value[2])
                );
              }

              row[this.element.id] = { type: "date", value: value };
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  };
}
