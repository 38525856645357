import React, { useEffect } from "react";

import {
	IconButton,
} from "rsuite";

import {
	EraIcon
} from "../../../styles/Icon.js";


export const ResultSelectorFunction = (props) => {


	const [visible, setVisible] = React.useState(props.visible);

	const [icon, setIcon] = React.useState({ icon: "eye", appearance: "primary" });


	useEffect(() => {

		//console.log("External modify of visibility", props.id, props.visible);

		setVisible(props.visible);
		onChangeAppearance(props.visible);

	}, [props.visible]);


	const onChangeAppearance = (visible) => {

		if (visible) {

			setIcon({ icon: "eye", appearance: "primary" });

		} else {

			setIcon({ icon: "eye-slash", appearance: "subtle" });

		}


	}

	const onToggle = () => {

		let newState = !visible;

		setVisible(newState);

		onChangeAppearance(newState);

		props.onChange(newState);

	}

	return (

		<IconButton circle
			style={{ marginLeft: "15px" }}
			size={"xs"}
			appearance={icon.appearance}
			onMouseDown={onToggle}
			icon={
				<EraIcon faName={icon.icon} size={"1.5em"} />
			}

		/>

	);

}
