import React, { Component, Fragment } from "react";
import { Layer, Feature } from "react-mapbox-gl";
import { routeStyle } from "../../styles/Styles.js";
import { mnIcon } from '../../styles/Styles.js';
import Utils from "../../utils/Utils.js";

export class RoutesLayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openRoute: {},
			openRouteStyles: [],
			routeFeatures: []
		};
		this.routeFilter = new Set();
	}

	parseRoute = (fromLoc, toLoc, route) => {
		let linePath = [];
		const [min, max] = [0, route.path.length]
		//console.log(min, max, route)

		for (let i = min; i < max; i++) {
			const stop = route.path[i];
			if (stop.lngLat) {
				linePath.push(stop.lngLat);
				if (route.renderNodes) {
					this.routeFilter.add(stop.lngLat.join('_'));
				}
			}
		}
		return linePath;
	}

	generateRoute = (route) => {


		const routeFeatures = [];
		const opFeatures = [];

		if (route.path) {

			//console.log("Rendered features for map");


			let op1, op2, track;

			let linePath = this.parseRoute(route.path[0].lngLat, route.path[route.path.length - 1].lngLat, route);

			//console.log("Path", linePath);

			routeFeatures.push(
				<Feature
					key={`route-feature-0`}
					coordinates={linePath}>
				</Feature>
			);

			for (let i = 1; i < route.path.length; i += 2) { // Iterate over tracks

				op1 = route.path[i - 1]
				op2 = route.path[i + 1]

				track = route.path[i]

				opFeatures.push(
					<Feature
						key={`route-feature-op1-${i}`}
						coordinates={op1.lngLat}>
					</Feature>
				);

				opFeatures.push(
					<Feature
						key={`route-feature-op2-${i}`}
						coordinates={op2.lngLat}>
					</Feature>
				);

			}

		}

		return { routeFeatures, opFeatures };

	};


	componentDidMount() {

		let { routeFeatures, opFeatures } = this.generateRoute(this.props.route);

		this.setState({ routeFeatures: routeFeatures, opFeatures: opFeatures });

	}

	componentDidUpdate(prevProps) {

		if (JSON.stringify(prevProps.route) !== JSON.stringify(this.props.route)) {

			let { routeFeatures, opFeatures } = this.generateRoute(this.props.route);

			this.setState({ routeFeatures: routeFeatures, opFeatures: opFeatures });

		}
	}

	render() {


		const { opFeatures, routeFeatures } = this.state;

		return (
			<Fragment>
				<Layer key={'open-route-tracks'} type="line" paint={routeStyle}>{routeFeatures}</Layer>
				<Layer key={'open-route-ops'} type="symbol" layout={{ 'icon-image': 'mn-icon' }} images={['mn-icon', mnIcon]}>
					{opFeatures}
				</Layer>
			</Fragment>
		);
	}
}
