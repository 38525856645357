

export class BooleanQuery {

	constructor(element, value) {

		//console.log("NumericQuery:", element, value);

		this.element = element;

		this.value = value;

	}

	build_filter = () => {

		// 2 cases, exact and range

		let filter = [];

		filter.push(`FILTER(?${this.element.id} = "${this.value}"^^xsd:boolean)`);

		return filter;

	}

	build_extra_patterns = () => {

		let patterns = [];

		return patterns;

	}

	build_extra_vars = () => {

		let vars = [];

		return vars;

	}

}
