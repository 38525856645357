import React, { useEffect } from "react";

import {
	InputNumber,
	FlexboxGrid,
	Toggle
} from "rsuite";

import { DecoratedInput } from "./DecoratedInput.js";

import { ResultSelectorFunction } from "./ResultSelectorFunction.js";

import { AbstractInput } from "./AbstractInput.js";


export class NumericInput extends AbstractInput {

	constructor(props) {

		super(props);

		//console.log("Numeric input:", props, this.state);

		this.emptyValue = { from: "", to: "", range: false }

		if (this.state.value === null) {

			this.state.value = { ...this.emptyValue };

		}

		this.unit = props.unit;

		if (this.unit !== undefined) {

			this.postfix_unit = this.unit.symbol;

		}

	}

	// Can be exact or range

	onTypeChange = () => {

		let oldValue = this.state.value;

		oldValue.range = !oldValue.range;

		if (this.state.value.from !== this.emptyValue.from) {

			this.setValue(oldValue);

		} else {

			this.cleanup({ from: "", to: "", range: this.state.value.range });

		}


	}

	setValueFrom = (value) => {

		let oldValue = this.state.value;

		oldValue.from = value;

		if (value === this.emptyValue.from && this.state.value.to === this.emptyValue.to) {

			//console.log("Cleanup", value);

			this.cleanup(this.emptyValue);

		} else {

			this.setValue(oldValue);

		}

	}

	setValueTo = (value) => {

		let oldValue = this.state.value;

		oldValue.to = value;

		if (value === this.emptyValue.to && this.state.value.from === this.emptyValue.from) {

			//console.log("Cleanup", value);

			this.cleanup(this.emptyValue);

		} else {

			this.setValue(oldValue);

		}

	}

	render() {

		return (

			<DecoratedInput id={this.id} label={this.label} unit={this.unit} selector={
				<ResultSelectorFunction
					id={this.id}
					visible={this.state.visible}
					onChange={this.setVisible}
				></ResultSelectorFunction>
			}>

				<FlexboxGrid
					justify="space-between"
					align="middle"
					style={{ paddingLeft: "0rem" }}
				>

					<FlexboxGrid.Item colspan={this.state.value.range ? 10 : 20}>
						<InputNumber
							postfix={this.postfix_unit}
							prefix={this.state.value.range ? "from" : "exactly"}
							onChange={this.setValueFrom}
							value={this.state.value.from}

						/>
					</FlexboxGrid.Item>


					{this.state.value.range && (
						<FlexboxGrid.Item colspan={10}>
							<InputNumber
								postfix={this.postfix_unit}
								prefix="to"
								onChange={this.setValueTo}
								value={this.state.value.to}
							/>
						</FlexboxGrid.Item>
					)}

					<FlexboxGrid.Item colspan={3}>
						<Toggle
							size={"lg"}
							style={{ width: "95%" }}
							checkedChildren={"between"}
							unCheckedChildren={"exactly"}
							value={this.state.value.range}
							onChange={this.onTypeChange}
							checked={this.state.value.range}
						/>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			</DecoratedInput>
		);

	}

}
