import React, { useState, useEffect, useCallback, useRef } from "react";
import Map, {
  Layer,
  Source,
  FillLayer,
  Popup,
  AttributionControl,
  NavigationControl,
  useControl,
} from "react-map-gl";
import {
  MapDisclaimer,
  MapDisclaimerModal,
} from "./MapComponents/MapDisclaimer.js";
import { openDB, deleteDB, wrap, unwrap } from "idb";
import {
  Container,
  Panel,
  Message,
  Loader,
  Button,
  IconButton,
  Tooltip,
  Whisper,
  ButtonToolbar,
  ButtonGroup,
  Dropdown,
  Navbar,
  Nav,
} from "rsuite";

import { ToastContainer, toast } from "react-toastify";

import "./Styles.css";

import {
  SPARQL_ENDPOINT,
  NAMED_KG_RINF,
  NAMED_KG_ERA_VOCABULARY,
  NAMED_KG_ERA_SKOS,
} from "../../config/config.js";

const locationVars = {
  op: "7f39b757e191637f73203093c178a5e9efa2d0d977979ba3cf9aa09e8a764b94",
  opStart: {
    lat: "60ec1ba6cd5c276d4069d1ba00c4127a7339c07ed038e05a8398b2ace0e05d1e_lat",
    lng: "60ec1ba6cd5c276d4069d1ba00c4127a7339c07ed038e05a8398b2ace0e05d1e_lng",
  },
  opEnd: {
    lat: "68de027727d5508346f687b553d1b69beb11765d01b030b5f685d43a3b3e744c_lat",
    lng: "68de027727d5508346f687b553d1b69beb11765d01b030b5f685d43a3b3e744c_lng",
  },
};

import { EraIcon } from "../../styles/Icon.js";

const mapBoxToken =
  "pk.eyJ1Ijoic3VzaGlsZ2hhbWJpciIsImEiOiJjazUyZmNvcWExM2ZrM2VwN2I5amVkYnF5In0.76xcCe3feYPHsDo8eXAguw";

import WebworkerPromise from "webworker-promise";

import fetch_information_query from "./queries/fetch_information.sparql";
import load_ontology_query from "./queries/load_ontology.sparql";
import load_country_SKOS_query from "./queries/load_SKOS_countries.sparql";

import { SoLLayer, OPLayer, SelectLayer } from "./Layers.js";

import { ViewDetails } from "./ViewDetails.js";

const query_executor = new WebworkerPromise(
  new Worker(new URL("./workers/QueryExecuter.worker.js", import.meta.url))
);
const store_executor = new WebworkerPromise(
  new Worker(new URL("./workers/DataStore.worker.js", import.meta.url))
);

export const MapExplorer = (props) => {
  const [data, setData] = useState();

  const [dataReady, setDataReady] = useState(false);
  const [metadataReady, setMetadataReady] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [error, setError] = useState(false);

  const cacheExpireInterval = 60 * 60 * 1000; // 1h

  const [zoom, setZoom] = useState([3]);
  const [bounds, setBounds] = useState([
    [-9.4182, 30.9416],
    [36.1258, 68.4489],
  ]);
  const [cursor, setCursor] = useState();

  const [popup, setPopup] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const [details, setDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  const [layers, setLayers] = useState({});

  const [showSoL, setShowSoL] = useState(true);
  const [showOP, setShowOP] = useState(true);
  //	const [showSelection, setShowSelection] = useState(false);

  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/light-v9");

  const [filter, setFilter] = useState({});
  const [filteredFeaturesOP, setFilteredFeaturesOP] = useState([]);
  const [filteredFeaturesSoL, setFilteredFeaturesSoL] = useState([]);

  const [interactive, setInteractive] = useState(["op_data", "sol_data"]);
  const [selection, setSelection] = useState(false);

  const [selectPolygon, setSelectPolygon] = useState({
    points: [],
    valid: false,
  });

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  //const mode = React.useState(new DrawPolygonMode());

  useEffect(() => {
    requestData();
    requestMetadata();
  }, [requestData]);

  const requestMetadata = useCallback(async () => {
    // www.opengis.net/ont/geosparql
    // http://www.w3.org/2003/01/geo/wgs84_pos
    // https://raw.githubusercontent.com/w3c/omn/master/omnlib/import/geo.ttl
    // https://www.w3.org/2000/01/rdf-schema:

    /*try {

			await store_executor.exec("load_by_uri", { data: { uri: "https://qudt.org/vocab/unit/", format: "ttl" } });

		} catch (e) {
			//console.log("Error loading RDFS ontology");
			//console.log(e);
		}

		try {

			await store_executor.exec("load_by_uri", { data: { uri: "https://raw.githubusercontent.com/dbpedia/DataId-Ontology/master/imports/rdfs.ttl", format: "ttl" } });

		} catch (e) {
			//console.log("Error loading RDFS ontology");
			//console.log(e);
		}

		try {

			await store_executor.exec("load_by_uri", { data: { uri: "https://opengeospatial.github.io/ogc-geosparql/geosparql10/geo.ttl", format: "ttl" } });

		} catch (e) {
			//console.log("Error loading GeoSPARQL ontology");
			//console.log(e);
		}

		try {

			await store_executor.exec("load_by_uri", { data: { uri: "https://raw.githubusercontent.com/w3c/omn/master/omnlib/import/geo.ttl", format: "ttl" } });

		} catch (e) {
			//console.log("Error loading WGS84 ontology");
			//console.log(e);
		}*/

    try {
      let query = load_ontology_query
        .replaceAll("${{NAMED_KG_ERA_VOCABULARY}}", NAMED_KG_ERA_VOCABULARY)
        .replaceAll("${{NAMED_KG_ERA_SKOS}}", NAMED_KG_ERA_SKOS);

      let ontology_loaded = await store_executor.exec("load_by_query", {
        data: { endpoint: SPARQL_ENDPOINT, query: load_country_SKOS_query },
      });
      let countries_loaded = await store_executor.exec("load_by_query", {
        data: { endpoint: SPARQL_ENDPOINT, query: query },
      });

      if (ontology_loaded && countries_loaded) {
        setMetadataReady(true);
      } else {
        setError(true);
      }
    } catch (e) {
      console.error("Error loading ontology information");
      setError(true);
    }
  }, []);

  const requestData = useCallback(async () => {
    /* Policy:
     * - Initial load: results are returned ASAP and cached in the background
     * - Later load: returned from cache until invalidated
     */

    let cachedData = null;
    let results;

    try {
      cachedData = JSON.parse(localStorage.getItem("data"));

      //console.log(cachedData, ((new Date()).valueOf() - cachedData.timestamp));
    } catch (error) {
      console.error("Unable to read localStorage");
    }

    if (
      cachedData == null ||
      (cachedData != null &&
        new Date().valueOf() - cachedData.timestamp > cacheExpireInterval)
    ) {
      //console.log("Fetching information from triple store...");

      try {
        let query = fetch_information_query.replaceAll(
          "${{NAMED_KG_RINF}}",
          NAMED_KG_RINF
        );

        results = await query_executor.exec("load_topology", {
          data: { endpoint: SPARQL_ENDPOINT, query: query },
        });

        //console.log("Loaded", results.length, "entities");

        if (results != null && results?.length != 0) {
          setData(results);
          setDataReady(true);
          await query_executor.exec("store_cached_topology", {
            data: { results: results },
          });
          localStorage.setItem(
            "data",
            JSON.stringify({ timestamp: new Date().valueOf() })
          );
        } else {
          setError(true);
        }
      } catch (error) {
        console.error("Unable to load topology!", error);
      }

      //console.log("Stored successfully");
    } else {
      //console.log("Fetching information from local cache");

      /*let db = await openDB("cache");
		
			const tx = db.transaction("data", "readonly");
			const personObj = tx.objectStore("data");
			results = await personObj.getAll();*/

      results = await query_executor.exec("load_cached_topology");

      //console.log("Loaded", results.length, "entities");

      setData(results);
      setDataReady(true);
    }
  }, []);

  const onHoverEnter = (event) => {
    if (event.features && event.features.length != 0) {
      let feature = event.features[0];

      setCursor("pointer");

      if (feature.properties.type === "sol") {
        let coordinates = JSON.parse(feature.properties.coordinates);

        let popupContent = <p>{feature.properties.label}</p>;

        let midPoint = {
          lng:
            (parseFloat(coordinates[0][0]) + parseFloat(coordinates[1][0])) / 2,
          lat:
            (parseFloat(coordinates[0][1]) + parseFloat(coordinates[1][1])) / 2,
        };

        let mousePoint = event.lngLat;

        setPopup({ content: popupContent, position: mousePoint });

        setShowPopup(true);
      }

      if (feature.properties.type === "op") {
        let coordinates = JSON.parse(feature.properties.coordinates);

        let popupContent = <p>{feature.properties.label}</p>;

        let point = {
          lng: parseFloat(coordinates[0]),
          lat: parseFloat(coordinates[1]),
        };

        let mousePoint = event.lngLat;

        setPopup({ content: popupContent, position: point });

        setShowPopup(true);
      }
    }
  };

  const onHoverLeave = (event) => {
    setShowPopup(false);

    setCursor("default");
  };

  const onMouseDown = (event) => {};

  const onMouseUp = (event) => {
    if (event.features && event.features.length != 0) {
      let features = event.features;
    }
  };

  const onClick = (event) => {
    //console.log(event);

    if (!selection) {
      if (event.features && event.features.length != 0) {
        let features = event.features;

        setDetails({ features: features });

        setShowDetails(true);
      }
    } else {
      const newPoints = [...selectPolygon.points];

      let valid = false;

      newPoints.push(event.lngLat);

      if (newPoints.length >= 3) {
        valid = true;
      }

      setSelectPolygon({ points: newPoints, valid: valid });

      //console.log("Point:", event);
      //console.log({points: newPoints, valid: valid});
    }
  };

  const onZoomEnd = (event) => {
    //console.log(event.viewState.zoom);

    setZoom(event.viewState.zoom);
  };

  const onLoad = (event) => {
    //console.log(event);

    setMapReady(true);
  };

  const disclaimer = () => {
    //console.log("Disclaimer");
  };

  const onShowDisclaimer = () => {
    setShowDisclaimer(true);
  };

  const onHideDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const mapRef = useRef();

  const onSelectArea = () => {
    setInteractive(["select_data"]);
    setSelection(true);

    setCursor("crosshair");
  };

  const onFinishSelectArea = () => {
    setInteractive(["op_data", "sol_data"]);
    setSelection(false);
  };

  const onCleanSelectArea = () => {
    setSelectPolygon({ points: [], valid: false });
  };

  const onCancelSelectArea = () => {
    setInteractive(["op_data", "sol_data"]);
    setSelectPolygon({ points: [], valid: false });
    setSelection(false);
    setCursor("default");
  };

  const onFilterOPs = (features) => {
    //console.log(features, showOP);

    if (showOP) {
      setFilteredFeaturesOP(features);
    } else {
      setFilteredFeaturesOP([]);
    }
  };

  const onFilterSoLs = (features) => {
    if (showSoL) {
      setFilteredFeaturesSoL(features);
    } else {
      setFilteredFeaturesSoL([]);
    }
  };

  const onViewSelectArea = () => {
    let filteredFeatures = [];

    if (showOP) {
      filteredFeatures = filteredFeatures.concat(filteredFeaturesOP);
    }

    if (showSoL) {
      filteredFeatures = filteredFeatures.concat(filteredFeaturesSoL);
    }
    setDetails({ features: filteredFeatures });
    setShowDetails(true);
  };

  return (
    <>
      {!(dataReady && metadataReady) && (
        <Container style={{ height: "calc(100vh - 144px)" }}>
          <Panel
            style={{
              overflow: "visible",
              margin: "35vh 2rem 35vh 2rem",
              borderRadius: "0px",
              borderTop: "2px solid #bbb",
              borderBottom: "2px solid #bbb",
            }}
          >
            {!error && (
              <Loader
                style={{ width: "200px", marginLeft: "calc(50% - 125px)" }}
                vertical
                content="Loading topology information."
                size="md"
              />
            )}
            {error && (
              <Message type="error">
                An error was found loading the topology information, please try
                again later.
              </Message>
            )}
          </Panel>
        </Container>
      )}

      <MapDisclaimerModal show={showDisclaimer} onClose={onHideDisclaimer} />

      {dataReady && metadataReady && (
        <Container style={{ height: "calc(100vh - 144px)" }}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="light"
            style={{ marginTop: "100px" }}
          />

          <ButtonToolbar style={{ padding: "10px 45px" }}>
            <Dropdown title="Infrastructure" appearance={"ghost"}>
              <Dropdown.Item
                onClick={() => {
                  setShowOP(true);
                  setShowSoL(true);
                }}
                active={showSoL && showOP}
              >
                View all
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setShowOP(true);
                  setShowSoL(false);
                }}
                active={!showSoL && showOP}
              >
                Operational Points
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setShowOP(false);
                  setShowSoL(true);
                }}
                active={showSoL && !showOP}
              >
                Sections of Line
              </Dropdown.Item>
            </Dropdown>

            <Dropdown
              title="Base map"
              appearance={"ghost"}
              style={{ paddingRight: "25px" }}
            >
              <Dropdown.Item
                onClick={() => {
                  setMapStyle("mapbox://styles/mapbox/light-v9");
                }}
                active={mapStyle == "mapbox://styles/mapbox/light-v9"}
              >
                Light
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setMapStyle("mapbox://styles/mapbox/dark-v9");
                }}
                active={mapStyle == "mapbox://styles/mapbox/dark-v9"}
              >
                Dark
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setMapStyle("mapbox://styles/mapbox/streets-v12");
                }}
                active={mapStyle == "mapbox://styles/mapbox/streets-v12"}
              >
                Streets
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setMapStyle("mapbox://styles/mapbox/satellite-v9");
                }}
                active={mapStyle == "mapbox://styles/mapbox/satellite-v9"}
              >
                Satelite
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setMapStyle("mapbox://styles/mapbox/navigation-day-v1");
                }}
                active={mapStyle == "mapbox://styles/mapbox/navigation-day-v1"}
              >
                Navigation
              </Dropdown.Item>
            </Dropdown>

            {!selection && (
              <IconButton
                appearance={"ghost"}
                onClick={onSelectArea}
                color={"green"}
                icon={<EraIcon faName="object-group" style={null} />}
              >
                Select area
              </IconButton>
            )}

            <ButtonGroup>
              {selection && false && (
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Select <b>at least 3 points</b> in the map to define a
                      selection polygon
                    </Tooltip>
                  }
                >
                  <IconButton
                    appearance={"ghost"}
                    onClick={onFinishSelectArea}
                    disabled={!selectPolygon.valid}
                    color={"green"}
                    icon={<EraIcon faName="check" style={null} />}
                  >
                    Finish selection
                  </IconButton>
                </Whisper>
              )}

              {selection && (
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={<Tooltip>Clean selected polygon points</Tooltip>}
                >
                  <IconButton
                    appearance={"ghost"}
                    onClick={onCleanSelectArea}
                    disabled={selectPolygon.points.length == 0}
                    color={"yellow"}
                    icon={<EraIcon faName="broom" style={null} />}
                  >
                    Clean selection
                  </IconButton>
                </Whisper>
              )}

              {selection && (
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={<Tooltip>Cancel the area selection</Tooltip>}
                >
                  <IconButton
                    appearance={"ghost"}
                    onClick={onCancelSelectArea}
                    color={"red"}
                    icon={<EraIcon faName="xmark" style={null} />}
                  >
                    Cancel selection
                  </IconButton>
                </Whisper>
              )}
            </ButtonGroup>

            {selection && (
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    Select <b>at least 3 points</b> in the map to define a
                    selection polygon
                  </Tooltip>
                }
              >
                <IconButton
                  appearance={"ghost"}
                  onClick={onViewSelectArea}
                  disabled={
                    !(
                      selectPolygon.valid &&
                      filteredFeaturesSoL.length + filteredFeaturesOP.length > 0
                    )
                  }
                  color={"blue"}
                  icon={<EraIcon faName="eye" style={null} />}
                >
                  View elements
                </IconButton>
              </Whisper>
            )}
          </ButtonToolbar>

          <Map
            ref={mapRef}
            initialViewState={{ bounds: bounds }}
            mapStyle={mapStyle}
            mapboxAccessToken={mapBoxToken}
            interactiveLayerIds={interactive}
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            //onMouseDown={onMouseDown}
            //onMouseUp={onMouseUp}
            onClick={onClick}
            onZoomEnd={onZoomEnd}
            onLoad={onLoad}
            cursor={cursor}
          >
            {mapReady && (
              <>
                {showSoL && (
                  <SoLLayer
                    data={data}
                    zoom={zoom}
                    filter={selectPolygon}
                    onFilter={onFilterSoLs}
                  />
                )}
                {showOP && (
                  <OPLayer
                    data={data}
                    zoom={zoom}
                    filter={selectPolygon}
                    onFilter={onFilterOPs}
                  />
                )}
                {selection && <SelectLayer data={selectPolygon} />}
              </>
            )}

            {showPopup && (
              <Popup
                longitude={popup.position.lng}
                latitude={popup.position.lat}
                offset={15}
                closeOnMove
                closeButton={false}
                onClose={() => {
                  setShowPopup(false);
                }}
                //ref={popupRef}
              >
                {popup.content}
              </Popup>
            )}

            <NavigationControl position="top-left" />

            <MapDisclaimer map={mapRef} onClick={onShowDisclaimer} />
          </Map>
        </Container>
      )}

      {showDetails && (
        <ViewDetails
          features={details.features}
          query_executor={query_executor}
          store_executor={store_executor}
          onClose={() => setShowDetails(false)}
        />
      )}
    </>
  );
};
