import React from "react";
import { Container, IconButton, Divider, Notification, Tag } from "rsuite";
import { RoutesPermalinkContainer } from "../../styles/Styles.js";
import queryString from "query-string";

import {
	EraIcon
} from "../../styles/Icon.js";

function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(function () {
	//Notification.info("Permalink copied to clipboard", 3000);
  });
}

export const loadPermalinkInfo = (httpQuery) => {
		
		const {
			fromId,
			fromLoc,
			fromLabel,
			toId,
			toLoc,
			toLabel,
			via,
			compatibilityVehicleType,
			compatibilityVehicleTypeLabel,
		} = queryString.parse(httpQuery);

		let newState = {};

		if (fromId && toId) {

			let fromLngLat = [parseFloat(fromLoc.split(",")[0]), parseFloat(fromLoc.split(",")[1])]
			let toLngLat = [parseFloat(toLoc.split(",")[0]), parseFloat(toLoc.split(",")[1])]

			newState.from = { id: fromId, label: fromLabel || fromId.replace("http://data.europa.eu/949/functionalInfrastructure/operationalPoints/", ""), lngLat: fromLngLat };
			newState.to = { id: toId, label: toLabel || toId.replace("http://data.europa.eu/949/functionalInfrastructure/operationalPoints/", ""), lngLat: toLngLat };

		}

		if(compatibilityVehicleType){

			newState.compatibilityVehicleType = { id: compatibilityVehicleType, label: compatibilityVehicleTypeLabel || compatibilityVehicleType.replace("http://data.europa.eu/949/vehicleTypes/", "")};

		}

		
		if (via) {

			try {

				newState.via = JSON.parse(via);

			} catch(error){

				//console.log(error);

			}

		}

		return newState;

}



export function RoutesPermalink({
	from,
	to,
	via,
	compatibilityVehicleType,
	}){

	const link = window.location.origin + window.location.pathname;

	if (from.id && to.id) {
	
		const params = queryString.stringify({
			fromId: from.id,
			fromLoc: `${from.lngLat[0]},${from.lngLat[1]}`,
			fromLabel: from.label,
			toId: to.id,
			toLoc: `${to.lngLat[0]},${to.lngLat[1]}`,
			toLabel: to.label,
			via: JSON.stringify(via),
			compatibilityVehicleType: compatibilityVehicleType.id,
			compatibilityVehicleTypeLabel: compatibilityVehicleType.label
		});

	history.replaceState({ link, params }, "", link + "?" + params);

	return (
	<Container>
		<Divider />
		<RoutesPermalinkContainer>
		
			<IconButton
					icon={<EraIcon faName={"link"} style={null} />}
					onClick={() => copyToClipboard(link + "?" + params)}
			>
				<a href={link + "?" + params}>Copy permalink</a>
			</IconButton>

		</RoutesPermalinkContainer>
		<Divider />

	  </Container>
	);
  }else{

	  history.replaceState({ link }, "", link);

	}

  return null;
}
