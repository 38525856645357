export const DatasetFileStatus = {
  Uploaded: "UPLOADED",
  ValidatingRinf: "VALIDATING_RINF",
  ValidatedRinfOk: "VALIDATED_RINF_OK",
  ValidatedRinfFail: "VALIDATED_RINF_FAIL",
  GeneratingKg: "GENERATING_KG",
  GeneratedKgOk: "GENERATED_KG_OK",
  GeneratedKgFail: "GENERATED_KG_FAIL",
  MergingPartial: "MERGING_PARTIAL",
  MergedPartialOk: "MERGED_PARTIAL_OK",
  MergedPartialFail: "MERGED_PARTIAL_FAIL",
  ValidatingShacl: "VALIDATING_SHACL",
  ValidatedShaclOk: "VALIDATED_SHACL_OK",
  ValidatedShaclFail: "VALIDATED_SHACL_FAIL",
  PublishingKg: "PUBLISHING_KG",
  PublishedKgOk: "PUBLISHED_KG_OK",
  PublishedKgFail: "PUBLISHED_KG_FAIL",
  Unpublished: "UNPUBLISHED",
};

export const DatasetFileType = {
  XmlFull: "XML_FULL",
  RdfFull: "RDF_FULL",
  RdfPartialDelete: "RDF_PARTIAL_DELETE",
  RdfPartialInsert: "RDF_PARTIAL_INSERT",
  RdfPartialUpdate: "RDF_PARTIAL_UPDATE",
  RdfFull_3_1: "RDF_FULL_3_1",
  RdfPartialDelete_3_1: "RDF_PARTIAL_DELETE_3_1",
  RdfPartialInsert_3_1: "RDF_PARTIAL_INSERT_3_1",
  RdfPartialUpdate_3_1: "RDF_PARTIAL_UPDATE_3_1",
};

export const PipelineStates = {
  WaitingPrev: 2,
  Ok: 1,
  Pending: 0,
  Fail: -1,
  Skip: -2,
};

export const PipelineSteps = {
  PartialMerge: {
    id: "partialMerge",
    name: "Partial dataset merge",
    description:
      "This step takes the uploaded partial dataset and merge it with the existing Knowledge Graph.",
    trigger: DatasetFileStatus.MergingPartial,
    report: true,
    artifacts: false, // ?
  },

  RINFValidation: {
    id: "initialValidation",
    name: "RINF validation",
    description:
      "This step validates the XML provided before it's processed to create the Knowledge Graph.",
    trigger: DatasetFileStatus.ValidatingRinf,
    report: true,
    reportDownload: false,
    artifacts: true,
    artifactsName: "whole report",
    artifactRef: "report",
  },

  KGGeneration: {
    id: "kgGeneration",
    name: "KG generation",
    description:
      "This step generates the Knowledge Graph from existing legacy data formats.",
    trigger: DatasetFileStatus.GeneratingKg,
    progress: true,
    report: false,
    artifacts: true,
    artifactsName: "knowledge graph",
    artifactRef: "kg_zip",
    preconditions: [{ step: "initialValidation", validResults: [2] }],
  },

  SHACLValidation: {
    id: "shaclValidation",
    name: "SHACL validation",
    description: "This step validates the RDF generated or provided.",
    trigger: DatasetFileStatus.ValidatingShacl,
    report: true,
    reportDownload: false,
    artifacts: true,
    artifactsName: "whole report",
    artifactRef: "report",
    preconditions: [
      { step: "kgGeneration", validResults: [2] },
      { step: "partialMerge", validResults: [2] },
    ],
  },

  Publishing: {
    id: "publish",
    name: "KG publishing",
    description:
      "This step publish the new Knowledge Graph to the Triple Store server.",
    trigger: DatasetFileStatus.PublishingKg,
    report: true,
    artifacts: false,
    preconditions: [
      { step: "initialValidation", validResults: [2] },
      { step: "shaclValidation", validResults: [2] },
    ],
    preconditions_enforced: true,
  },
};

export const DatasetPipelines = {
  XML_FULL: [
    PipelineSteps.RINFValidation,
    PipelineSteps.KGGeneration,
    PipelineSteps.SHACLValidation,
    PipelineSteps.Publishing,
  ],
  RDF_FULL: [PipelineSteps.SHACLValidation, PipelineSteps.Publishing],
  RDF_PARTIAL_DELETE: [
    PipelineSteps.PartialMerge,
    PipelineSteps.SHACLValidation,
    PipelineSteps.Publishing,
  ],
  RDF_PARTIAL_INSERT: [
    PipelineSteps.PartialMerge,
    PipelineSteps.SHACLValidation,
    PipelineSteps.Publishing,
  ],
  RDF_PARTIAL_UPDATE: [
    PipelineSteps.PartialMerge,
    PipelineSteps.SHACLValidation,
    PipelineSteps.Publishing,
  ],
  RDF_FULL_3_1: [PipelineSteps.SHACLValidation, PipelineSteps.Publishing],
  RDF_PARTIAL_DELETE_3_1: [
    PipelineSteps.PartialMerge,
    PipelineSteps.SHACLValidation,
    PipelineSteps.Publishing,
  ],
  RDF_PARTIAL_INSERT_3_1: [
    PipelineSteps.PartialMerge,
    PipelineSteps.SHACLValidation,
    PipelineSteps.Publishing,
  ],
  RDF_PARTIAL_UPDATE_3_1: [
    PipelineSteps.PartialMerge,
    PipelineSteps.SHACLValidation,
    PipelineSteps.Publishing,
  ],
};
