import { apiRequest, msalConfig } from "../../azure/authConfig.js";
import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { RINF_API_BASEURL } from "../../config/config.js";
import React from "react";

export default {
  fetchDataAssets,
  uploadDocuments,
  uploadDataset,
  updateDatasetStatus,
  downloadDataAsset,
  deleteDataAsset,
  renameDataAsset,
};

async function acquireToken(instance) {
  return await instance.acquireTokenSilent({
    ...apiRequest,
    account: instance.getAllAccounts()[0],
  });
}

async function fetchDataAssets(instance, dataAssetType) {
  const token = await acquireToken(instance);

  return axios({
    method: "GET",
    url: RINF_API_BASEURL + "/data-assets/" + dataAssetType,
    headers: {
      Authorization: `${token.tokenType} ${token.accessToken}`,
    },
  });
}

async function uploadDocuments(instance, fileList) {
  const token = await acquireToken(instance);
  const formData = new FormData();

  for (const file of fileList) {
    console.log(file.name);
    let utf8_name = `UTF-8''${encodeURIComponent(file.name)}`;
    let headers = {
      filename: file.name,
      headers: {
        "Content-Disposition": `form-data; name="file"; filename*=${utf8_name}`,
      },
    };
    formData.append("files", file, file.name);
  }

  return axios({
    method: "POST",
    url: RINF_API_BASEURL + "/data-assets/documents",
    headers: {
      Authorization: `${token.tokenType} ${token.accessToken}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

async function uploadDataset(instance, file, fileType) {
  const token = await acquireToken(instance);
  const formData = new FormData();
  formData.append("file", file);

  return axios({
    method: "POST",
    url: RINF_API_BASEURL + "/data-assets/datasets",
    headers: {
      Authorization: `${token.tokenType} ${token.accessToken}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    params: {
      fileType: fileType,
    },
  });
}

async function updateDatasetStatus(instance, id, status) {
  const token = await acquireToken(instance);

  return axios({
    method: "PUT",
    url: RINF_API_BASEURL + "/data-assets/datasets/" + id,
    headers: {
      Authorization: `${token.tokenType} ${token.accessToken}`,
    },
    params: {
      status: status,
    },
  });
}

async function deleteDataAsset(instance, dataAssetType, id) {
  const token = await acquireToken(instance);

  return axios({
    method: "DELETE",
    url: RINF_API_BASEURL + "/data-assets/" + dataAssetType + "/" + id,
    headers: {
      Authorization: `${token.tokenType} ${token.accessToken}`,
    },
  });
}

async function downloadDataAsset(instance, dataAssetType, id, s3Uri) {
  const token = await acquireToken(instance);

  let chain = s3Uri ? "" : "/" + id;

  return axios({
    method: "GET",
    url:
      RINF_API_BASEURL + "/data-assets/" + dataAssetType + chain + "/download",
    headers: {
      Authorization: `${token.tokenType} ${token.accessToken}`,
      "Response-Type": "Blob",
      "Content-Type": "multipart/form-data",
    },
    ...(s3Uri ? { params: { s3Uri: s3Uri } } : []),
  });
}

async function renameDataAsset(instance, type, id, newName) {
  const token = await acquireToken(instance);

  return axios({
    method: "PUT",
    url: RINF_API_BASEURL + "/data-assets/" + type + "/" + id + "/rename",
    headers: {
      Authorization: `${token.tokenType} ${token.accessToken}`,
      "Content-Type": "multipart/form-data",
    },
    params: {
      newName: newName,
    },
  });
}
