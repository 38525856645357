import Utils from "../utils/Utils.js";
import { GEOSPARQL, WGS84 } from '../utils/NameSpaces.js';

export const assembleRoute2 = (route) => {

    const locations = [];
    const steps = new Map();
    const tracks = [];
    let length = 0;

	for(let node of route.path){

		//console.log(node);

		if (node.implementation.type == "http://data.europa.eu/949/OperationalPoint") { // OP

			steps.set(node.op, { ... node})
			locations.push(["", [node.lngLat[0], node.lngLat[1]]]);

		}

		if (node.implementation.type == "http://data.europa.eu/949/Track") { // Track

			let track = {"id": node.track, ...node}

			tracks.push(track);
			length += node.length;

		}

	}
		
    
    return { steps, tracks, length, locations };
};
