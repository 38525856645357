import { Notification } from "rsuite";
import { saveAs } from "file-saver";
import convert from "xml-js";
import ExcelJS from "exceljs";


import { MapResult } from "./../SearchResultComponents/MapResult.js";
import { NumericResult } from "./../SearchResultComponents/NumericResult.js";
import { NumericResultKm } from "./../SearchResultComponents/NumericResultKm.js";

import { DateResult } from "./../SearchResultComponents/DateResult.js";
import { TextResult } from "./../SearchResultComponents/TextResult.js";

export class ExcelExport {


	constructor(query, formDefinition, skosValues, data, sparqlQuery) {



		this.data = data;

		this.query = query;

		this.formDefinition = formDefinition;

		this.skosValues = skosValues;

		this.metadata = {

			"Permalink": {
				text: window.location.href,
				hyperlink: window.location.href
			},
			"Creation date": new Date().toISOString(),
			"Browser": window.navigator.userAgent

		}

		this.components = {

			MapResult: MapResult,
			NumericResult: NumericResult,
			NumericResultKm: NumericResultKm,
			DateResult: DateResult,
			TextResult: TextResult

		}

		//this.headers = Object.keys(this.data.results.bindings[0])

		this.headers = this.data.head.vars;

		for (let form of this.formDefinition) {

			if (form.object.value === this.query.rootObject) {

				this.elements = form.elements;

			}

		}

	}

	postProcessResults() {

		let newData;

		for (let element of this.headers) {

			if (this.elements[element].result in this.components) {

				//console.log(element, this.elements[element]);

				let result = new this.components[this.elements[element].result](this.elements[element]);

				newData = result.transformData(this.data);

			} else {

				//console.log("No component for ",element, this.elements[element]);

			}

		}

	}

	sortHeadResults = (a, b) => {

		try {

			let va = parseFloat(this.elements[a].input.ref) || 0;
			let vb = parseFloat(this.elements[b].input.ref) || 0;

			return va - vb;

		} catch (e) {

			return 0;

		}

	}

	replaceHeader(head) {

		let label = "<missing label>";

		if (this.elements[head] !== undefined) {

			label = this.elements[head].input.definition.label;

		}

		return label;

	}

	selectValue(element, dataKey, rowData) {

		if (element === undefined) {

			return "no data";

		}


		let cell_content = element.value;

		// URIs

		if (element.type === "uri") {

			let text = this.skosValues[element.value] || element.label || element.value;

			//console.log(text, element);

			if (dataKey === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

				if ("2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e" in rowData) {

					({ value: text } = rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/operationalPoints/", "op:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			if (dataKey === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				if ("442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78" in rowData &&
					"ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7" in rowData) {

					let value1, value2;

					({ value: value1 } = rowData["442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78"]);
					({ value: value2 } = rowData["ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7"]);

					text = value1 + "-" + value2;


				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/sectionsOfLine/", "sol:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			//value = DESCRIBE_URI + encodeURI(value)

			//cell_content = (
			//	<Tag color="grey" style={{ border: "dotted 1px #777" }}><a href={value} target="_blank">{text}</a></Tag>
			//);

			cell_content = {
				text: text,
				hyperlink: element.value,
				tooltip: element.value
			};

		}


		// Booleans (color)

		if (element.type === "typed-literal" && element.dataType === "http://www.w3.org/2001/XMLSchema#boolean") {

			if (element.value === "1") {

				cell_content = "True";

			} else {

				cell_content = "False";

			}

		}


		// Empty formatting

		if (element.value === "N/A") {

			cell_content = "no data";
		}

		return cell_content;

	}

	async generateFile() {

		//console.log(this.skosValues);

		const workbook = new ExcelJS.Workbook();

		workbook.properties.date1904 = true;
		workbook.calcProperties.fullCalcOnLoad = true;

		workbook.creator = 'ERA Search form';
		workbook.lastModifiedBy = 'ERA Search form';

		workbook.created = new Date();

		const worksheet = workbook.addWorksheet("Search results");

		const worksheet_extra = workbook.addWorksheet("Metadata");


		worksheet.columns = []

		worksheet.getRow(1).font = { bold: true }

		//console.log(this.data);

		let excel_headers = [];


		// Prepare data columns

		let data_columns = {};

		for (let column of this.headers) {

			data_columns[column] = [];
		}

		for (let row of this.data.results.bindings) {

			for (let column of this.headers) {

				data_columns[column].push(this.selectValue(row[column], column, row));

			}

		}

		// Set column headers

		for (let column of this.headers) {

			let excel_header = { header: this.replaceHeader(column), key: column, width: 48 };

			//console.log(excel_header);

			excel_headers.push(excel_header);


		}

		worksheet.columns = excel_headers;


		// Insert values

		for (let column of this.headers) {

			let column_data = [this.replaceHeader(column), ...data_columns[column]];

			//console.log(column_data);

			let excel_column = worksheet.getColumn(column);

			excel_column.values = column_data;


		}

		// Extra worksheet

		//console.log(this.metadata);


		worksheet_extra.columns = [
			{ key: "key", width: 48 },
			{ key: "value", width: 128 }
		]

		worksheet_extra.getRow(1).font = { bold: true }


		let keys = ["Key"]

		for (let key of Object.keys(this.metadata)) {

			keys.push(key)

		}

		worksheet_extra.getColumn("key").values = keys;


		let values = ["Value"]

		for (let key of Object.keys(this.metadata)) {

			values.push(this.metadata[key])

		}

		worksheet_extra.getColumn("value").values = values;


		// Save document data

		const buffer = await workbook.xlsx.writeBuffer();

		const blob = new Blob([buffer], { type: "application/xlsx;charset=utf-8" });

		let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)


		saveAs(blob, "search_results_" + date + ".xlsx");

	}

}
