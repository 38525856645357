import React, { useEffect } from "react";
import {
	ButtonGroup,
	Button,
	Panel,
	Stack,
	Modal,
	SelectPicker,
	Input,
	Tag,
	Container
} from "rsuite";

import {
	CreateSubscriptionGeographicalRegion
} from "./CreateSubscriptionGeographicalRegion.js";


import {
	EraPicker
} from "./EraPicker.js";


export const CreateSubscription = (props) => {

	const [open, setOpen] = React.useState(props.open);

	const [type, setType] = React.useState();

	const [value, setValue] = React.useState();

	const [ready, setReady] = React.useState(false);

	useEffect(() => {

		setOpen(props.open);

		setType(undefined);

		setReady(false);

	}, [props]);

	// Temporal, must reuse SKOS

	const typeList = [
		{ "label": "Country subscription", "value": "country" },
		{ "label": "Geographical zone subscription", "value": "geographical-zone" },
		{ "label": "National Line subscription", "value": "national-line" },
		{ "label": "Section of Line subscription", "value": "sol" },
		{ "label": "Operational Point subscription", "value": "op" },
		{ "label": "Infrastructure Manager subscription", "value": "im" }

		//{"label": "Route subscription", "value": "route"}
	]


	const countryList = [
		{ "value": "http://publications.europa.eu/resource/authority/country/BEL", "label": "Belgium" },
		{ "value": "http://publications.europa.eu/resource/authority/country/DEU", "label": "Germany" },
		{ "value": "http://publications.europa.eu/resource/authority/country/DNK", "label": "Denmark" },
		{ "value": "http://publications.europa.eu/resource/authority/country/FRA", "label": "France" },
		{ "value": "http://publications.europa.eu/resource/authority/country/GBR", "label": "United Kingdom" },
		{ "value": "http://publications.europa.eu/resource/authority/country/GRC", "label": "Greece" },
		{ "value": "http://publications.europa.eu/resource/authority/country/HRV", "label": "Croatia" },
		{ "value": "http://publications.europa.eu/resource/authority/country/HUN", "label": "Hungary" },
		{ "value": "http://publications.europa.eu/resource/authority/country/ITA", "label": "Italy" },
		{ "value": "http://publications.europa.eu/resource/authority/country/LTU", "label": "Lithuania" },
		{ "value": "http://publications.europa.eu/resource/authority/country/NLD", "label": "Netherlands" },
		{ "value": "http://publications.europa.eu/resource/authority/country/POL", "label": "Poland" },
		{ "value": "http://publications.europa.eu/resource/authority/country/PRT", "label": "Portugal" },
		{ "value": "http://publications.europa.eu/resource/authority/country/SWE", "label": "Sweden" },
		{ "value": "http://publications.europa.eu/resource/authority/country/AUT", "label": "Austria" },
		{ "value": "http://publications.europa.eu/resource/authority/country/BGR", "label": "Bulgaria" },
		{ "value": "http://publications.europa.eu/resource/authority/country/ESP", "label": "Spain" },
		{ "value": "http://publications.europa.eu/resource/authority/country/CZE", "label": "Czechia" },
		{ "value": "http://publications.europa.eu/resource/authority/country/EST", "label": "Estonia" },
		{ "value": "http://publications.europa.eu/resource/authority/country/FIN", "label": "Finland" },
		{ "value": "http://publications.europa.eu/resource/authority/country/LVA", "label": "Latvia" },
		{ "value": "http://publications.europa.eu/resource/authority/country/SVK", "label": "Slovakia" },
		{ "value": "http://publications.europa.eu/resource/authority/country/SVN", "label": "Slovenia" },
		{ "value": "http://publications.europa.eu/resource/authority/country/CHE", "label": "Switzerland" },
		{ "value": "http://publications.europa.eu/resource/authority/country/LUX", "label": "Luxembourg" },
		{ "value": "http://publications.europa.eu/resource/authority/country/ROU", "label": "Romania" },
		{ "value": "http://publications.europa.eu/resource/authority/country/NOR", "label": "Norway" }
	];

	countryList.sort((a, b) => a.label.localeCompare(b.label));

	const onClose = () => {

		props.onClose();

	}

	const onCreate = () => {

		props.onCreate(type, value);

		props.onClose();

	}

	const onSetType = (type) => {

		//console.log(type)

		setType(type);

	}


	const onUnset = () => {

		setReady(false);

	}


	const onSetValueCountry = (value, item) => {

		//console.log(value, item)

		setReady(true);

		setValue(item);

	}

	const onSetValueSoL = (elements) => {

		setReady(true);

		const result = [];

		for (let element of elements) {

			//console.log(element);

			result.push({ "value": element["value"], "label": element["label"] })

		}

		setValue(result);
	}

	const onSetValueOP = (elements) => {

		setReady(true);

		const result = [];

		for (let element of elements) {

			//console.log(element);

			result.push({ "value": element["value"], "label": element["label"] })

		}

		setValue(result);


	}

	const onSetValueNationalLine = (elements) => {

		setReady(true);

		const result = [];

		for (let element of elements) {

			//console.log(element);

			result.push({ "value": element["value"], "label": element["label"] })

		}

		setValue(result);


	}

	const onSetValueGeo = (value) => {

		//console.log(value);

		setReady(true);

		setValue(value);

	}


	const onSearchQuery = async (query, event) => {

		debounce = query;

		if (query.length > 2) {

			setFetching(true);

			let response = await axios.post(SEARCH_HINT_API, { "type": picker_type, "query": query, "n_results": 6 });
			let elementsRes = response.data.result;

			if (debounce.length <= query.length) {
				setElements(elementsRes);
				setFetching(false);

				//console.log(">", debounce, query, elementsRes);

			}

			//console.log(debounce, query, elementsRes);
		}

	}


	return (

		<Modal size={"lg"} open={open} onClose={onClose}>
			<Modal.Header>
				<Modal.Title>Create subscription</Modal.Title>
			</Modal.Header>
			<Modal.Body>

				<Container style={{ "marginBottom": "10px", height: "300px" }}>

					<SelectPicker placeholder={"Select the subscription type"} data={typeList} block onSelect={onSetType} style={{ "marginBottom": "10px" }} />

					{type == "country" && (

						<SelectPicker placeholder={"Select the country"} data={countryList} block onSelect={onSetValueCountry} onClean={onUnset} style={{ "marginBottom": "10px" }} />

					)

					}

					{type == "sol" && (

						<EraPicker placeholder={"Start typing to search for Sections of Line"} onSelectFinal={onSetValueSoL} onClean={onUnset} type={"sections-of-line"} />

					)

					}

					{type == "op" && (

						<EraPicker placeholder={"Start typing to search for Operational Points"} onSelectFinal={onSetValueOP} onClean={onUnset} type={"operational-points"} />

					)

					}

					{type == "national-line" && (

						<EraPicker placeholder={"Start typing to search for National Lines"} onSelectFinal={onSetValueNationalLine} onClean={onUnset} type={"national-lines"} />

					)

					}

					{type == "im" && (

						<EraPicker placeholder={"Start typing to search for Infrastructure Managers"} onSelectFinal={onSetValueNationalLine} onClean={onUnset} type={"ims"} />

					)

					}

					{type == "geographical-zone" && (

						<CreateSubscriptionGeographicalRegion onChange={onSetValueGeo} onUnset={onUnset} />

					)

					}

					{type == "route" && (

						<Input placeholder={"MSG"} data={countryList} onChange={onSetValueRoute} style={{ "marginBottom": "10px" }} />

					)

					}

				</Container>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onClose} appearance="subtle">Cancel</Button>
				<Button onClick={onCreate} appearance="primary" color={"green"} disabled={!ready}>Create</Button>
			</Modal.Footer>
		</Modal>

	);

}
