import { LogLevel } from "@azure/msal-browser";
import {
  CLIENT_ID,
  REDIRECT_URI,
  RINF_API_APP_ID_URI,
  TENANT_ID,
} from "../config/config.js";

/**
 * Service principal object ID associated to the application, this is used to filer queries in MSGraph
 * @type {string}
 */

export const resourceId = "f807e863-d866-4d95-9a5c-bf8db7b45d55";

/**
 * Map containing the available roles in the application and their IDs in Azure
 * The order in which the roles are inserted is important for later use
 * @type {Map<string, string>}
 */
export const appRoleMap = new Map([
  ["8c47c7ae-e07f-4817-ac3a-ffdd8dc14bb3", "IM"],
  ["2a84bd8d-25de-4295-b7fc-45b04cc4d833", "NRE"],
  ["cb5ade0e-c1ad-4246-a9c7-adcb7879ddff", "RRU"],
]);

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: "https://login.microsoftonline.com/" + TENANT_ID,
    redirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const apiRequest = {
  scopes: [RINF_API_APP_ID_URI + "/DataAssets.Manage"],
  extraQueryParameters: {
    claims: {
      optionalClaims: {
        idToken: [{ name: "ctry", source: null }],
        accessToken: [{ name: "ctry", source: null }],
      },
    },
  },
};
export const msGraphRequest = {
  scopes: ["User.Read"],
};
