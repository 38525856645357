import React from "react";
export const UnauthenticatedLayout = () => {

    return (
        <div>
            Sign in to see this page or &nbsp;
            <a href="https://srm-portal.powerappsportals.com/contact-us/#UserManagement" target="_blank" rel="noopener noreferrer">
                contact us
            </a>
            &nbsp; for support.
        </div>
    )
}