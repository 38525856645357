import React, { Component } from "react";
import { Container, Panel, Table, Button, IconButton, ButtonToolbar, Input, Modal } from "rsuite";
const { Column, HeaderCell, Cell } = Table;
import { saveAs } from "file-saver";

import {
	EraIcon
} from "../../styles/Icon.js";


export const Vocabulary = () => {

	return(
	
	<Container style={{padding: "0px"}}>
	
		<iframe style={{border: "0px", height: "calc(100vh - 144px)"}} src="/era-vocabulary/"/>
	
	</Container>
	)

}
