import Root from "./components/App/Main.js";
import React, { createContext } from "react";
import ReactDOM from "react-dom";

ReactDOM.render(<Root />, document.getElementById("root"));


// This error is result of a poor implementation by W3C draft, see https://github.com/w3c/csswg-drafts/issues/5488
// Removed from webpack overlay and redirected here to console

window.addEventListener('error', function (e) {
  if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
    console.debug(e)
    e.stopImmediatePropagation();
    e.preventDefault();
  }
});

