import axios from "axios";

import { namedNode, literal, blankNode } from '@rdfjs/data-model';

import GraphStore from "@graphy/memory.dataset.fast";
import NTParser, { NTripleReader } from '@graphy/content.nt.read';

import registerWebworker from 'webworker-promise/lib/register.js';

const graph = GraphStore();
const host = registerWebworker();




// Load a remote resource in the local triple store

host.operation("load_by_query", async (msg, emit) => {

	//console.log("Querying data from ", msg.data.endpoint);

	return await load_data_query(msg.data.endpoint, msg.data.query);

});

host.operation("load_by_data", async (msg, emit) => {

	//console.log("Querying data from ", msg.data.endpoint);

	return await load_data(msg.data);

});


// Query the local memory store

host.operation("query", async (msg, emit) => {

	//console.log("Local query", msg.data);

	return query_graph(msg.data);

});

const parseNTdata = (data, graph) => {

	return new Promise((resolve, reject) => {

		NTParser(data, { data(quad) { graph.add(quad); }, eof() { resolve() } });

	});

}


const load_data_query = async (endpoint, query) => {

	const instance = axios.create();

	instance.defaults.timeout = 15000;

	try {

		const headers = {
			'Accept': 'application/n-triples,*/*;q=0.9',

		}

		let n = 0;

		let data = new URLSearchParams();
		data.append("query", query);

		let response = await instance({
			method: "post",
			url: endpoint,
			data: data,
			headers: headers
		});

		let new_triples = graph.size;

		await parseNTdata(response.data, graph);

		new_triples = graph.size - new_triples;

		if (new_triples != 0) {

			return true;

		} else {

			//console.log("Imported empty set!");

			return false;

		}

	} catch (e) {

		//console.log(e);

		return false;

	}

}

const load_data = async (data) => {

	try {

		let new_triples = graph.size;

		await parseNTdata(data, graph);

		new_triples = graph.size - new_triples;

		if (new_triples != 0) {

			return true;

		} else {

			//console.log("Imported empty set!");

			return false;

		}

	} catch (e) {

		//console.log(e);

		return false;

	}

}


function query_graph(bgp) {

	let { s: subject, p: predicate, o: object } = bgp;

	let results = [];

	// Replace prefixes

	subject = (subject != null) ? subject.replace("era:", "http://data.europa.eu/949/") : null;
	predicate = (predicate != null) ? predicate.replace("era:", "http://data.europa.eu/949/") : null;
	object = (object != null) ? object.replace("era:", "http://data.europa.eu/949/") : null;


	// Create the proper objects

	subject = (subject != null) ? namedNode(subject) : null;
	predicate = (predicate != null) ? namedNode(predicate) : null;
	object = (object != null) ? (object.startsWith("http") ? namedNode(object) : literal(object)) : null;

	let result_set = graph.match(subject, predicate, object);

	for (let result of result_set) {

		results.push(result);

	}

	return results;

}
