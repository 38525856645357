import {msalConfig, msGraphRequest, resourceId} from "./authConfig.js";
import axios from "axios";


const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/"

async function acquireToken(msalInstance) {
    return await msalInstance.acquireTokenSilent({
        ...msGraphRequest,
        account: msalInstance.getAllAccounts()[0]
    })
}

export async function getUserInfo(msalInstance) {
	
	let token;
	
	try{
	
		token = await acquireToken(msalInstance);
		
	}catch(error){
		
		//console.log("Login error, cleaning up auth...");
		
		throw "Login error";
	}

    return axios({
        method: 'GET',
        url: GRAPH_ENDPOINT + "/me",
        headers: {
            'Authorization': `${token.tokenType} ${token.accessToken}`
        },
    });
}
export async function getUserRoles(msalInstance) {
	
	let token;
	
	try{
	
		token = await acquireToken(msalInstance);
		
	}catch(error){
		
		//console.log("Login error, cleaning up auth...");
		
		throw "Login error";
	}

    return axios({
        method: 'GET',
        url: GRAPH_ENDPOINT + "/me/appRoleAssignments?$filter=resourceId eq " + resourceId,
        headers: {
            'Authorization': `${token.tokenType} ${token.accessToken}`
        },
    });
}

