
import { sha256_sync } from "../../utils/Misc.js";

import { era, rdfs, skos, addPrefixes, removePrefixes } from "../../../../utils/NameSpace.js";



export class CurrentValid {

	constructor(element, value, root) {

		this.element = element;
		this.value = value;
		this.root = root;

	}

	prepare_filter = async (elements, formDefinition, ontologyDefinition) => {

		let query = (await ontologyDefinition.exec("query", {
			data: { s: null, p: rdfs.subClassOf, o: era.InfrastructureObject }
		}));


		let dateClasses = ["http://data.europa.eu/949/Siding"]; //MUST FIX

		for (let result of query) {

			dateClasses.push(result.subject.value);

		}

		let dateClassesUsed = []


		for (let element of elements) {

			//console.log(element);

			if (element.element.input.definition.propertyType === "http://www.w3.org/2002/07/owl#ObjectProperty") {

				let targetClass = (await ontologyDefinition.exec("query", {
					data: { s: element.element.input.property, p: rdfs.range, o: null }
				}))[0].object.value;

				if (dateClasses.includes(targetClass) && !dateClassesUsed.includes(targetClass)) {

					//console.log("Valid filter for:", targetClass, "setting mandatory", element);

					dateClassesUsed.push(element.element.input.property);

					element.optional = false;

				}

			}

		}



	}

	build_filter = async (elements, formDefinition, ontologyDefinition) => {

		//console.log("Valid filter", elements, formDefinition);

		// This ad-hoc filter uses the validity date properties to estimate if an entity is currently valid

		let filter = [];

		// Date only apply to subclasses of http://data.europa.eu/949/InfrastructureObject (maybe can be done programatically?)

		let query = (await ontologyDefinition.exec("query", {
			data: { s: null, p: rdfs.subClassOf, o: era.InfrastructureObject }
		}));

		let dateClasses = ["http://data.europa.eu/949/Siding"]; //MUST FIX

		for (let result of query) {

			dateClasses.push(result.subject.value);

		}

		//console.log(dateClasses);

		// Filter for root element

		let rootObject = "?" + sha256_sync("/" + this.root);
		let validityStartDate = "?" + sha256_sync("/" + this.root + "/" + era.validityStartDate);
		let validityEndDate = "?" + sha256_sync("/" + this.root + "/" + era.validityEndDate);

		let validityVarName = "?validityDateType_root";

		let valid_fragment;

		if (this.value === true) {

			valid_fragment = "('case1-novaliditydates','case2-noStart-ValidEnd','case3-start-NoEnd','case4-insideStartEnd')";

		} else {

			valid_fragment = "('case5-notValid-past','case6-notValid-future','case7-notValid')";

		}

		filter.push(`
			# Currently valid filter for root element
			OPTIONAL{
				${rootObject} <http://data.europa.eu/949/validityStartDate> ${validityStartDate}.
				${rootObject} <http://data.europa.eu/949/validityEndDate> ${validityEndDate}.
			}
	
			BIND (
			  COALESCE(
				IF(!BOUND(${validityStartDate}) && !BOUND(${validityEndDate}), 'case1-novaliditydates', 
				IF(!BOUND(${validityStartDate}) && ${validityEndDate} >= NOW(), 'case2-noStart-ValidEnd',
				IF(${validityStartDate} <= NOW() && !BOUND(${validityEndDate}), 'case3-start-NoEnd', 
				IF(${validityStartDate} <= NOW() && ${validityEndDate}>= NOW(), 'case4-insideStartEnd', 
				IF(${validityEndDate}<NOW(), 'case5-notValid-past', 
				IF(${validityStartDate}>NOW(), 'case6-notValid-future',
				'case7-notValid'))))))
			  ) AS ${validityVarName}
			)

			FILTER (${validityVarName} IN ${valid_fragment})`.replaceAll("\t\t\t", "")
		);


		// Filter for child elements

		let dateClassesUsed = []

		for (let element of elements) {

			//console.log(element);

			element = element.element;

			if (element.input.definition.propertyType === "http://www.w3.org/2002/07/owl#ObjectProperty") {

				let targetClass = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: rdfs.range, o: null }
				}))[0].object.value;

				if (dateClasses.includes(targetClass) && !dateClassesUsed.includes(targetClass)) {

					//console.log("Valid filter for:", targetClass);

					dateClassesUsed.push(element.input.property);

					element.optional = false;

				} else {

					//console.log("Not valid filter for:", targetClass, dateClasses);

				}

			}

		}


		for (let dateClassUsed of dateClassesUsed) {

			//console.log(dateClassUsed);


			let classUsed = "?" + sha256_sync("/" + this.root + "/" + dateClassUsed);
			validityStartDate = "?" + sha256_sync("/" + this.root + "/" + dateClassUsed + "/" + era.validityStartDate);
			validityEndDate = "?" + sha256_sync("/" + this.root + "/" + dateClassUsed + "/" + era.validityEndDate);

			validityVarName = "?validityDateType_" + dateClassUsed.split("/").at(-1).toLowerCase();

			filter.push(`
			
			# Currently valid filter for ${dateClassUsed}
			
			OPTIONAL {
				${classUsed} <http://data.europa.eu/949/validityStartDate> ${validityStartDate}.
				${classUsed} <http://data.europa.eu/949/validityEndDate> ${validityEndDate}.
			}
	
			BIND (
			  COALESCE(
				IF(!BOUND(${validityStartDate}) && !BOUND(${validityEndDate}), 'case1-novaliditydates', 
				IF(!BOUND(${validityStartDate}) && ${validityEndDate} >= NOW(), 'case2-noStart-ValidEnd',
				IF(${validityStartDate} <= NOW() && !BOUND(${validityEndDate}), 'case3-start-NoEnd', 
				IF(${validityStartDate} <= NOW() && ${validityEndDate}>= NOW(), 'case4-insideStartEnd', 
				IF(${validityEndDate}<NOW(), 'case5-notValid-past', 
				IF(${validityStartDate}>NOW(), 'case6-notValid-future',
				'case7-notValid'))))))
			  ) AS ${validityVarName}
			)
	
			FILTER (${validityVarName} IN ${valid_fragment})`.replaceAll("\t\t\t", "")
			);

		}



		//return [];

		return filter;

	}


	build_extra_patterns = () => {

		let patterns = [];

		let rootObject = "?" + sha256_sync("/" + this.root);

		let validityStartDate = "?" + sha256_sync("/" + this.root + "/" + era.validityStartDate);

		let validityEndDate = "?" + sha256_sync("/" + this.root + "/" + era.validityEndDate);

		/*patterns.push(`${rootObject} <${era.validityStartDate}> ${validityStartDate}.`);

		patterns.push(`${rootObject} <${era.validityEndDate}> ${validityEndDate}.`)*/;

		return patterns;

	}


	build_extra_vars = () => {

		let vars = [];
		return vars;

	}

}
