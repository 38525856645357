import React from "react";

import {
	Container,
	SelectPicker,
	Button,
	ImageButton,
	Tag,
	Form,
	Panel,
	Placeholder,
	FlexboxGrid,
	PanelGroup,
} from "rsuite";

import { isEqual } from 'lodash';

import { getPhrase } from "../../utils/Languages.js";

import { era, rdfs, skos, addPrefixes, removePrefixes } from "../../utils/NameSpace.js";

import { TextInputFunction } from "./SearchFormComponents/TextInputFunction.js";

import { NumericInput } from "./SearchFormComponents/NumericInput.js";

import { DateInput } from "./SearchFormComponents/DateInput.js";

import { PickerInput } from "./SearchFormComponents/PickerInput.js";

import { SelectInput } from "./SearchFormComponents/SelectInput.js";

import { BooleanInputFunction } from "./SearchFormComponents/BooleanInputFunction.js";

import { MapInput } from "./SearchFormComponents/MapInput.js";

import componentMappingFile from './FormDefinition.yml'

import { Translation } from 'react-i18next';


const search_element = (id, formDefinition) => {

	let element = null;

	for (let definition of formDefinition) {

		for (let eid of Object.keys(definition.elements)) {

			if (eid == id) {

				element = definition.elements[id];

				break;

			}

		}

		for (let eid of Object.keys(definition.custom_filters)) {

			if (eid == id) {

				element = definition.custom_filters[id];

				break;

			}

		}

	}

	return element;

}


export class SearchForm extends React.Component {

	constructor(props) {

		super(props);

		//this.t = props.t;

		this.components = {

			TextInput: TextInputFunction,
			NumericInput: NumericInput,
			DateInput: DateInput,
			PickerInput: PickerInput,
			BooleanInput: BooleanInputFunction,
			MapInput: MapInput

		};

		({
			formDefinition: this.formDefinition,
			formValues: this.initialValues,
			language: this.language,
			onChange: this.onChange
		} = props);


		//console.log(this.formDefinition);

		this.state = {

			renderOptions: { object: null },

			objectSelector: false,

			object: {},

			inputs: {},
			elements: {},

			structure: [],

			foo: 0,

			query: { visible: {}, value: {} }

		}

		this.comp_refs = {};

		this.panelStyle = {
			marginBottom: "15px",
			borderRadius: "0px",
			borderWidth: "0px 0px 0px 4px",
			borderStyle: "solid",
			borderColor: "#95959a",

		}

	}

	async componentDidMount() {

		this.initialize();

	}

	async componentDidUpdate(prevProps, prevState) {

		if (this.props !== prevProps && JSON.stringify(prevState.query) !== JSON.stringify(this.props.formValues.query)) {

			//console.log("Form values updated...",  this.props.formValues);

			this.initialValues = this.props.formValues;

			this.initialize();

		}

	}

	initialize() {

		//console.log("Rendering form:", this.formDefinition);

		//console.log("[FORM]:", this.initialValues);

		if (this.initialValues.query) {

			for (let id of Object.keys(this.initialValues.query.value)) {

				if (this.state.inputs[id] === undefined) {

					this.state.inputs[id] = {}

				}

				this.state.inputs[id].value = this.initialValues.query.value[id];

				this.state.query.value[id] = this.initialValues.query.value[id];
			}

			for (let id of Object.keys(this.initialValues.query.visible)) {

				if (this.state.inputs[id] === undefined) {

					this.state.inputs[id] = {}

				}

				this.state.inputs[id].visible = this.initialValues.query.visible[id];

				this.state.query.visible[id] = this.initialValues.query.visible[id];

			}

		}

		//console.log(this.initialValues);


		if (this.initialValues.rootObject !== null) {

			this.state.object.selected = this.initialValues.rootObject;

			this.renderFormObjectSelector();

			this.setState({ structure: [] });

			this.renderFormStructure();

		} else {

			this.renderFormObjectSelector();

			this.cleanObjectSelector();

		}


	}


	// Inputs state update functions

	onInputChange = (id, state, type) => {

		//console.log("Form update:", id, state, type);

		let oldInputs = this.state.inputs;

		oldInputs[id] = { ...state };

		this.state.inputs = oldInputs;

		this.setState({ inputs: oldInputs });

		this.updateQuery(id, state, type);

		this.renderFormStructure()

		if (type === "visibility" && state.visible == true) {

			this.checkMissingElements(id);

		}

	}

	onInputClean = (id, state) => {

		//console.log("Form update:", id, state);

		let oldInputs = this.state.inputs;

		delete oldInputs[id];

		this.setState({ inputs: oldInputs });

		this.cleanQuery(id);

	}


	// Set used parameters in order to generate the SPARQL query

	cleanQuery(id) {

		let query = this.state.query;

		delete query.value[id];

		this.setState({ query: query });

		this.onChange(query, this.state.object.selected);

		//console.log("Cleaning search query...", id, query);


	}

	checkMissingElements(id) {

		let checked_element = { ...search_element(id, this.formDefinition) };

		//console.log("Searching for missing elements in path for", id, checked_element);

		if (checked_element.type == "custom") {

			return;

		}

		let elements = []

		for (let definition of this.formDefinition) {

			//console.log(definition.object.value, this.state.object.selected)

			if (definition.object.value === this.state.object.selected) {

				elements = definition.elements;

				break;

			}

		}

		let parent_path = [...checked_element.input.path];

		let parent_property = parent_path.pop();

		for (let key of Object.keys(elements)) {

			if (isEqual(elements[key].input.path, parent_path) && elements[key].input.property === parent_property) {

				//console.log("Selection propagated to:", elements[key].input.path, elements[key].input.property);

				//console.log(this.state.inputs);

				let new_state = this.state.inputs[key];

				new_state.visible = true;

				this.onInputChange(key, new_state, "visibility")

			}

		}

	}

	updateQuery(id, state, type) {

		//console.log("Updating search query...", id, state, type, this.state.query);

		let query = this.state.query;

		if (type === "value") {

			//console.log(state);

			if (state.value.options !== undefined) {

				query.value[id] = { selected: state.value.selected };

			} else {

				query.value[id] = state.value;

			}

		}

		if (type === "visibility") {

			if (state.visible === false) {

				delete query.visible[id];

			} else {

				query.visible[id] = state.visible;

			}

		}

		this.setState({ query: query });

		this.onChange(query, this.state.object.selected);

	}


	// Inputs rendering functions

	renderElement(element) {

		//console.log("Rendering element:", element);

		let id = element.id;

		let InputType = this.components?.[element.input?.component];

		if (InputType !== undefined) {

			if (this.state.inputs[id] === undefined) {

				this.state.inputs[element.id] = {};

				this.state.inputs[element.id].value = null;

				if (element.input?.defaults?.visible !== undefined) {

					//console.log("Element with defaults", element, element.input?.defaults);

					this.state.inputs[id].visible = element.input.defaults.visible;

					this.state.query.visible[id] = element.input.defaults.visible;

					this.onChange(this.state.query, this.state.object.selected);

				} else {

					this.state.inputs[id].visible = false;

				}

			} else {

				if (this.state.inputs[id].value === undefined) {

					this.state.inputs[element.id].value = null;

				}

				if (this.state.inputs[id].visible === undefined) {

					this.state.inputs[element.id].visible = false;

				}

			}

			if (InputType === PickerInput) {

				let selected = this.state.inputs[id]?.value?.selected || [];

				this.state.inputs[element.id].value = { "options": element.input.availableValues, "selected": selected }

				//console.log("Values:", element.input.availableValues);

			}

			//console.log(element.id, this.state.inputs[element.id]);			


			let label;

			if (element.input.definition?.rinfIndex !== undefined) {

				label = element.input.definition.label + " (" + element.input.definition.rinfIndex + ")";

			} else {

				label = element.input.definition.label;

			}

			let unit = undefined;

			if (element.input.definition?.unit !== undefined) {

				unit = element.input.definition.unit;

			}

			//console.log(element.input.definition, unit);

			return (
				<InputType
					key={id}
					id={id}
					label={label}
					unit={unit}
					invisible={element.input?.defaults?.invisible}
					visible={this.state.inputs[id].visible}
					value={this.state.inputs[id].value}
					onChange={this.onInputChange}
					onClean={this.onInputClean}>
				</InputType>
			);

		} else {

			//console.log("Not defined input component type", element.input?.component?.form, element);

			return null;

		}

	}

	renderFormStructureRecursive(tree, deep_level) {

		//console.log("Form structure:", tree.elements, tree.childs, deep_level);

		let inputs = []

		// Get generated components by reference

		if (tree.elements !== undefined) {

			for (let element of tree.elements) {

				//console.log(element);

				if (element.id == "deae2d4d75857c1081f113bcfc950dca567dd5a2e14e6ac8fb8e5785ff4dd5ec") {

					//console.log(element);

				}

				inputs.push(this.renderElement(element));

			}

		}

		let childs = []

		if (tree.childs) {

			for (let element of Object.keys(tree.childs)) {

				childs.push(this.renderFormStructureRecursive(tree.childs[element], deep_level + 1));

			}

		}

		let level_label;

		let panelStyle = structuredClone(this.panelStyle);

		if (deep_level == 0) {

			if (tree.index) {

				level_label = (<><h4>{tree.label} <span style={{ color: "#999" }}>({tree.index})</span></h4></>);

			} else {

				level_label = (<h4>{tree.label}</h4>);

			}

			panelStyle.borderColor = "#95959a";

			panelStyle.borderWidth = "0px 0px 0px 3px";


		}

		if (deep_level == 1) {

			if (tree.index) {

				level_label = (<><h5>{tree.label} <span style={{ color: "#999" }}>({tree.index})</span></h5></>);

			} else {

				level_label = (<h5>{tree.label}</h5>);

			}

			panelStyle.borderColor = "#b5b5ba";

			panelStyle.borderWidth = "0px 0px 0px 2px";


		}

		if (deep_level >= 2) {

			if (tree.index) {

				level_label = (<><h6 style={{ fontStyle: "italic" }}>{tree.label} <span style={{ color: "#999" }}>({tree.index})</span></h6></>);

			} else {

				level_label = (<h6>{tree.label}</h6>);

			}

			panelStyle.borderColor = "#e5e5ea";

			panelStyle.borderWidth = "0px 0px 0px 1px";


		}

		//console.log(!tree.collapsed);

		return (

			<Panel header={level_label} key={tree.label + deep_level.toString()} style={panelStyle} collapsible defaultExpanded={!tree.collapsed}>
				{inputs}
				{childs}
			</Panel>

		);


	}

	renderFormStructure() {

		let tree = {};

		for (let definition of this.formDefinition) {

			//console.log(definition, this.state.renderOptions.object);

			if (definition.object.value === this.state.object.selected) {

				Object.assign(tree, definition.structure);

			}

		}


		let structure = this.renderFormStructureRecursive(tree, 0);

		//console.log("Form struct:", structure);

		this.setState({ structure: structure });

	}

	renderFormObjectSelector() {

		let options = [];
		let id = "object_selector";

		for (let definition of this.formDefinition) {

			//console.log("Possible search objects", definition.object.value);

			options.push(definition.object);

		}

		this.state.object.options = options;

		//console.log("Search objects", this.state.object)

		this.comp_refs[id] = React.createRef();

		this.objectSelector = (
			<>

				<SelectInput
					key={id}
					id={id}
					ref={this.comp_refs[id]}
					label={"Search object"}
					cleanable={false}
					searchable={false}
					onChange={this.changeFormObject}
					value={this.state.object}
				>

				</SelectInput>
			</>

		);

		this.setState({ objectSelector: true });

	}


	// Render helper functions for search object change

	cleanObjectSelector() {

		let oldValue = this.state.object;

		oldValue.selected = null;

		this.setState({ object: oldValue });

		this.setState({ structure: [] });


	}

	changeFormObject = (id, object) => {

		//console.log("Rendering form for object:", object.value.selected, this.formDefinition);

		let empty_query = { visible: {}, value: {} };

		let objectSelector = this.state.object;

		objectSelector.selected = object.value.selected;

		this.setState({ object: objectSelector });

		this.state.inputs = {};

		this.state.query = empty_query;

		this.onChange(this.state.query, object.value.selected);

		if (object.value.selected !== null) {

			this.renderFormStructure();

		} else {

			this.setState({ structure: [] });

		}

	}


	render() {

		return (

			<PanelGroup style={{ margin: "2rem", borderRadius: "0px" }} >

				<Panel header={
					//<h3>{this.t('Search form')}</h3>}
					<h3>Search form</h3>
				}
					key={"root"} style={this.panelStyle}>
					{this.state.objectSelector && (this.objectSelector)}
					{this.state.structure}
				</Panel>

			</PanelGroup>

		);

	}

}
