import React, { useState, useEffect, useCallback } from "react";

import { Layer, Source } from "react-map-gl";

import { polygon, point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

const GeoJSONTemplate = {
  type: "FeatureCollection",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [],
};

const locationVars = {
  opStart: {
    lat: "60ec1ba6cd5c276d4069d1ba00c4127a7339c07ed038e05a8398b2ace0e05d1e_lat",
    lng: "60ec1ba6cd5c276d4069d1ba00c4127a7339c07ed038e05a8398b2ace0e05d1e_lng",
  },
  opEnd: {
    lat: "68de027727d5508346f687b553d1b69beb11765d01b030b5f685d43a3b3e744c_lat",
    lng: "68de027727d5508346f687b553d1b69beb11765d01b030b5f685d43a3b3e744c_lng",
  },
};

const uriVars = {
  opStart: "cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714",
  opEnd: "4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4",

  sol: "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc",
};

const labelVars = {
  opStart: "442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78",
  opEnd: "ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7",
  sol: "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc_label",
};

export const SoLLayer = ({ data, zoom, filter, onFilter }) => {
  const [GeoJSONData, setGeoJSONData] = useState();

  const [paint, setPaint] = useState({
    "line-color": "rgba(0,0,0,1)",
    "line-width": 1,
  });

  useEffect(() => {
    setGeoJSONData(generateGeoJSON(data, filter));
  }, [data, filter]);

  useEffect(() => {
    let newPaint = { ...paint };

    newPaint["line-width"] = (zoom / 22) * 10;

    setPaint(newPaint);
  }, [zoom]);

  const generateGeoJSON = (data, filter) => {
    let GeoJSON = { ...GeoJSONTemplate };

    let keys = {};

    let lines = [];

    //this.setState({showFeatures: false})

    let turfPolygon;

    if (filter.valid) {
      const closedPoly = [...filter.points, filter.points[0]];

      turfPolygon = polygon([
        closedPoly.map((x) => {
          return [x.lng, x.lat];
        }),
      ]);
    }

    for (let binding of data) {
      if (binding["wkt"]) {
        let inside = true;

        let coordinates = [];

        for (let value of binding["wkt"]["value"]
          .replace("LINESTRING(", "")
          .replace(")", "")
          .split(",")) {
          coordinates.push([value.split(" ")[0], value.split(" ")[1]]);

          if (filter.valid) {
            inside =
              inside &&
              booleanPointInPolygon(
                point([value.split(" ")[0], value.split(" ")[1]]),
                turfPolygon
              );
          }
        }

        if (!filter.valid || (filter.valid && inside)) {
          if (binding[uriVars.sol] && !(binding[uriVars.sol].value in keys)) {
            try {
              let geojson_line = {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: coordinates,
                },
                properties: {
                  type: "sol",
                  coordinates: coordinates,
                  uri: binding[uriVars.sol].value,
                  label: binding[labelVars.sol].value,
                },
              };

              keys[binding[uriVars.sol].value] = null;

              lines.push(geojson_line);
            } catch {
              //console.log("No valid GeoJSON for entity", binding);
            }
          }
        }
      } else {
        if (
          binding[locationVars.opStart.lng] &&
          binding[locationVars.opStart.lat] &&
          binding[locationVars.opEnd.lng] &&
          binding[locationVars.opEnd.lat]
        ) {
          let lngLat1 = [
            binding[locationVars.opStart.lng].value,
            binding[locationVars.opStart.lat].value,
          ];
          let lngLat2 = [
            binding[locationVars.opEnd.lng].value,
            binding[locationVars.opEnd.lat].value,
          ];

          if (
            !filter.valid ||
            (filter.valid &&
              booleanPointInPolygon(point(lngLat1), turfPolygon) &&
              booleanPointInPolygon(point(lngLat2), turfPolygon))
          ) {
            if (binding[uriVars.sol] && !(binding[uriVars.sol].value in keys)) {
              try {
                let geojson_line = {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: [lngLat1, lngLat2],
                  },
                  properties: {
                    type: "sol",
                    coordinates: [lngLat1, lngLat2],
                    uri: binding[uriVars.sol].value,
                    label: binding[labelVars.sol].value,
                  },
                };

                keys[binding[uriVars.sol].value] = null;

                lines.push(geojson_line);
              } catch {
                //console.log("No valid GeoJSON for entity", binding);
              }
            }
          }
        } else {
          //console.log("Something is wrong with this entity", binding);
        }
      }
    }

    GeoJSON.features = lines;

    if (filter.valid) {
      onFilter(lines);
    }

    return GeoJSON;
  };

  return (
    <>
      {GeoJSONData && (
        <Source type="geojson" data={GeoJSONData}>
          <Layer id={"sol_data"} type={"line"} paint={paint} />
        </Source>
      )}
    </>
  );
};

export const OPLayer = ({ data, zoom, filter, onFilter }) => {
  const [GeoJSONData, setGeoJSONData] = useState();

  const [paint, setPaint] = useState({
    "circle-radius": 1,
    "circle-color": "rgba(0, 124, 191, 1)",
  });

  useEffect(() => {
    setGeoJSONData(generateGeoJSON(data, filter));
  }, [data, filter]);

  useEffect(() => {
    let newPaint = { ...paint };

    newPaint["circle-radius"] = (zoom / 22) * 15;
    newPaint["circle-color"] = "rgba(0, 144, 211, " + (zoom / 22) * 1 + ")";

    setPaint(newPaint);
  }, [zoom]);

  const generateGeoJSON = (data, filter) => {
    let GeoJSON = { ...GeoJSONTemplate };

    let keys = {};

    let points = [];

    let turfPolygon;

    if (filter.valid) {
      const closedPoly = [...filter.points, filter.points[0]];

      turfPolygon = polygon([
        closedPoly.map((x) => {
          return [x.lng, x.lat];
        }),
      ]);
    }

    for (let binding of data) {
      if (
        binding[locationVars.opStart.lng] &&
        binding[locationVars.opStart.lat]
      ) {
        if (
          binding[uriVars.opStart] &&
          !(binding[uriVars.opStart].value in keys)
        ) {
          let lngLat1 = [
            binding[locationVars.opStart.lng].value,
            binding[locationVars.opStart.lat].value,
          ];

          if (
            !filter.valid ||
            (filter.valid && booleanPointInPolygon(point(lngLat1), turfPolygon))
          ) {
            try {
              let geojson_point1 = {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: lngLat1,
                },
                properties: {
                  type: "op",
                  coordinates: lngLat1,
                  uri: binding[uriVars.opStart].value,
                  label: binding[labelVars.opStart].value,
                },
              };

              keys[binding[uriVars.opStart].value] = null;

              points.push(geojson_point1);
            } catch {
              //console.log("No valid GeoJSON for entity", binding);
            }
          }
        }
      }

      if (binding[locationVars.opEnd.lng] && binding[locationVars.opEnd.lat]) {
        if (binding[uriVars.opEnd] && !(binding[uriVars.opEnd].value in keys)) {
          let lngLat2 = [
            binding[locationVars.opEnd.lng].value,
            binding[locationVars.opEnd.lat].value,
          ];

          if (
            !filter.valid ||
            (filter.valid && booleanPointInPolygon(point(lngLat2), turfPolygon))
          ) {
            try {
              let geojson_point2 = {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: lngLat2,
                },
                properties: {
                  type: "op",
                  coordinates: lngLat2,
                  uri: binding[uriVars.opEnd].value,
                  label: binding[labelVars.opEnd].value,
                },
              };

              keys[binding[uriVars.opEnd].value] = null;

              points.push(geojson_point2);
            } catch {
              //console.log("No valid GeoJSON for entity", binding);
            }
          }
        }
      } else {
        //console.log("Something is wrong with this entity", binding);
      }
    }

    GeoJSON.features = points;

    if (filter.valid) {
      onFilter(points);
    }

    //console.log(GeoJSON);

    return GeoJSON;
  };

  return (
    <>
      {GeoJSONData && (
        <Source type="geojson" data={GeoJSONData}>
          <Layer id={"op_data"} type={"circle"} paint={paint} />
        </Source>
      )}
    </>
  );
};

export const SelectLayer = ({ data, editing }) => {
  const [GeoJSONData, setGeoJSONData] = useState();

  const paintFill = {
    "fill-color": "#0080ff",
    "fill-opacity": 0.2,
  };

  const paintLine = {
    "line-color": "rgba(147, 147, 150, 0.8)",
    "line-width": 4,
    "line-dasharray": [2, 2],
  };

  const paintCircle = {
    "circle-radius": 6,
    "circle-color": "rgba(0, 200, 0, 1)",
  };

  useEffect(() => {
    setGeoJSONData(generateGeoJSON(data));
  }, [data]);

  const generateGeoJSON = (data) => {
    let GeoJSON = { ...GeoJSONTemplate };

    if (data.valid) {
      const closedPoly = [...data.points, data.points[0]];

      //console.log(closedPoly);

      let polygon = {
        type: "Feature",

        geometry: {
          type: "Polygon",
          coordinates: [
            closedPoly.map((x) => {
              return [x.lng, x.lat];
            }),
          ],
        },
      };

      GeoJSON.features = [polygon];
    }

    if (data.points.length == 1) {
      const points = [];

      for (let dataPoint of data.points) {
        let point = {
          type: "Feature",

          geometry: {
            type: "Point",
            coordinates: [dataPoint.lng, dataPoint.lat],
          },
        };

        points.push(point);
      }

      GeoJSON.features = points;
    }

    if (data.points.length == 2) {
      const points = [];

      for (let dataPoint of data.points) {
        let point = {
          type: "Feature",

          geometry: {
            type: "Point",
            coordinates: [dataPoint.lng, dataPoint.lat],
          },
        };

        points.push(point);
      }

      let line = {
        type: "Feature",

        geometry: {
          type: "LineString",
          coordinates: [
            data.points.map((x) => {
              return [x.lng, x.lat];
            }),
          ],
        },
      };

      GeoJSON.features = [line, ...points];
    }

    //console.log(GeoJSON);

    return GeoJSON;
  };

  return (
    <>
      {GeoJSONData && (
        <Source type="geojson" data={GeoJSONData}>
          <Layer id={"select_data"} type={"fill"} paint={paintFill} />
          <Layer id={"select_data_line"} type={"line"} paint={paintLine} />
          <Layer
            id={"select_data_circle"}
            type={"circle"}
            paint={paintCircle}
          />
        </Source>
      )}
    </>
  );
};
