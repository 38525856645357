import WebworkerPromise from 'webworker-promise';

//import ApiExecutor from "./workers/ApiExecutor.worker.js";

import {msGraphRequest, msalConfig} from "../../azure/authConfig.js";

const api = new WebworkerPromise(new Worker(new URL("./workers/ApiExecutor.worker.js", import.meta.url)));

async function acquireToken(instance) {
    return await instance.acquireTokenSilent({
        ...msGraphRequest,
        account: instance.getAllAccounts()[0]
    })
}


export const loadInfo = async (instance) => {
	
	const token = await acquireToken(instance);
	
	let response = await api.exec("/info/get", {"token": `${token.tokenType} ${token.accessToken}`});
	
	//console.log("Info loaded");
			
	return response.result;
	
	
	
}

export const loadSubscriptions = async (instance) => {
	
	const token = await acquireToken(instance);
		
	let response = await api.exec("/subscriptions/get", {"token": `${token.tokenType} ${token.accessToken}`});
	
	//console.log("Subscriptions loaded");
			
	return response.result;
	
}


export const subscriptionAdd = async (instance, type, value) => {
	
	const token = await acquireToken(instance);
		
	let obj = {"type": type, "value": value}
	
	await api.exec("/subscriptions/add", {"token": `${token.tokenType} ${token.accessToken}`, "data": {"subscription_obj": JSON.stringify(obj)}});
	
	
}

	
export const subscriptionDelete = async (instance, id) => {
	
	const token = await acquireToken(instance);
	
	await api.exec("/subscriptions/delete", {"token": `${token.tokenType} ${token.accessToken}`, "data": {"subscription_id": id}});
	
	
}

