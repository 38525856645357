import React, { Component } from "react";
import Yasgui from "@triply/yasgui";
import queryString from "query-string";

import "@triply/yasgui/build/yasgui.min.css";
import { SPARQL_ENDPOINT } from "../../config/config.js";

import default_query from "./default.sparql";

export default class Endpoint extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log("ENDPOINT: ", SPARQL_ENDPOINT);

    Yasgui.defaults.yasqe.value = default_query;

    const yasgui_config = {
      requestConfig: {
        endpoint: SPARQL_ENDPOINT,
        method: "POST",
      },
      resizeable: false,

      // Configuring which endpoints appear in the endpoint catalogue list
      endpointCatalogueOptions: {
        getData: () => {
          return [
            {
              endpoint: SPARQL_ENDPOINT,
            },
          ];
        },
      },
    };

    const parameters = queryString.parse(document.location.hash);

    if (
      parameters.endpoint != SPARQL_ENDPOINT &&
      parameters.endpoint != undefined //In case is not specified
    ) {
      document.location.hash = "";
    }

    const yasgui = new Yasgui(document.getElementById("yasgui"), yasgui_config);
  }

  render() {
    return <div id="yasgui" />;
  }
}
