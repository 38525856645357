import React, {useState} from "react";
import { Navbar, Nav, Modal, Message } from "rsuite";
import { getPhrase } from "../../utils/Languages.js";

import NewUserManualProd from "../../docs/RINF-User Manual v4 production.pdf"
import NewUserManual from "../../docs/RINF-User Manual v6.1.pdf"


export const RCCDisclaimer = ({show}) => {

	const [showDisclaimer,setShowDisclaimer] = useState(show);
	
	const disclaimerText = (<>
			<p>While every effort is made to ensure that the route compatibility checks’ algorithm adequately generates a route and an associated report according to the specific TSIs, the EU Agency for Railways cannot be held responsible or accept any liability for possible omissions of relevant data or errors, nor for subsequent use of the route compatibility report. Only information provided by the EU railway infrastructure managers is deemed to be authentic.</p>
		</>)
	
	const disclaimerClose = () => {
		
		localStorage.setItem("showRCCDisclaimer", "false");
		
		setShowDisclaimer(false);
		
	}
	
	return (
	
		<Modal open={showDisclaimer} onClose={disclaimerClose} size={"lg"}>
			<Modal.Header>
				<Modal.Title><b>Disclaimer for route calculation and checks</b></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Message type="warning">
					{disclaimerText}
				</Message>
			</Modal.Body>
		</Modal>
		
	);
	
}


export const MapDisclaimer = ({show}) => {

	const [showDisclaimer,setShowDisclaimer] = useState(show);
	
	const disclaimerText = (<>
			<p>The designations employed and the presentation of material on the map do not imply the expression of any opinion whatsoever on the part of the European Union concerning the legal status of any country, territory or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.</p>
			<p>Kosovo: This designation is without prejudice to positions on status, and is in line with UNSCR 1244/1999 and the ICJ Opinion on the Kosovo declaration of independence.</p>
			<p>Palestine: This designation shall not be construed as recognition of a State of Palestine and is without prejudice to the individual positions of the Member States on this issue.</p>
		</>)
	
	const disclaimerClose = () => {
		
		localStorage.setItem("showMapDisclaimer", "false");
		
		setShowDisclaimer(false);
		
	}
	
	return (
	
		<Modal open={showDisclaimer} onClose={disclaimerClose} size={"lg"}>
			<Modal.Header>
				<Modal.Title><b>Disclaimer for map representations</b></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Message type="warning">
					{disclaimerText}
				</Message>
			</Modal.Body>
		</Modal>
		
	);
	
}


export default function Footer({ language }) {


	
  return (
  
	<>
  
  
    <Nav justified style={{ backgroundColor: "#f7f7fa", padding: "1rem 0" }}>
      <Nav.Item
        href={NewUserManual}
        style={{ textAlign: "center" }}
      >
        {getPhrase("userManual", language)}
      </Nav.Item>
      <Nav.Item
        href="https://www.era.europa.eu/system/files/2022-11/rinf_terms_of_use_en.pdf"
        style={{ textAlign: "center" }}
      >
        {getPhrase("termsOfUse", language)}
      </Nav.Item>
      <Nav.Item
        href="https://www.era.europa.eu/content/data-protection"
        style={{ textAlign: "center" }}
      >
        {getPhrase("privacyStatement", language)}
      </Nav.Item>
      <Nav.Item
        href="https://www.era.europa.eu/content/disclaimer-and-copyright-notice"
        style={{ textAlign: "center" }}
      >
        {getPhrase("copyrightNotice", language)}
      </Nav.Item>
    </Nav>
    
    </>
  );
}
