import React, { useEffect } from "react";
import {
	Container,
	SelectPicker,
	Button,
	ImageButton,
	Tag,
	Panel,
	FlexboxGrid,
	Input
} from "rsuite";


export class AbstractInput extends React.Component {

	constructor(props) {

		super(props);

		this.state = {};

		//console.log("Abstract input:", this.state, this.props,);

		({ id: this.id, definition: this.definition, label: this.label, value: this.state.value, invisible: this.invisible, visible: this.state.visible, onChange: this.onChange, onClean: this.onClean } = props);


	}

	componentDidUpdate(prevProps, prevState) {



		if (this.props.value !== prevProps.value && prevState.value !== this.state.value) {

			let { value: value } = this.props;

			this.setState({ value: value });

		}

		if (this.props.visible !== this.state.visible) {

			//console.log(this.id, this.props, prevProps, this.state, prevState);

			let { visible: visible } = this.props;

			this.setState({ visible: visible });

		}
	}

	cleanup = (defaultValue) => {

		this.setState({ visible: this.state.visible, value: defaultValue });

		this.onClean(this.id, { value: defaultValue, visible: this.state.visible });

	}

	setVisible = (visible) => {

		this.setState({ visible: visible });

		this.onChange(this.id, { value: this.state.value, visible: visible }, "visibility");

	}

	setValue = (value) => {

		//console.log("Value change", value);

		this.setState({ value: value });

		this.onChange(this.id, { value: value, visible: this.state.visible }, "value");

	}


}
