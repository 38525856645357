import { Container, Message, Nav, Panel } from "rsuite";
import React, { useEffect, useState, useContext } from "react";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { UnauthenticatedLayout } from "../Authentication/UnauthenticatedLayout.js";
import { UnauthorizedLayout } from "../Authentication/UnauthorizedLayout.js";

import { appRoleMap } from "../../azure/authConfig.js";

import { DatasetsPanel } from "./DatasetsPanel.js";
import { ReferenceDocumentsPanel } from "./ReferenceDocumentsPanel.js";

import { AuthContext } from "./../App/Main.js";
import { ToolsContext } from "./../App/Main.js";

const DatasetManagementComponent = () => {
  const [activeTab, setActiveTab] = useState("");

  const { notify } = useContext(ToolsContext);

  useEffect(() => {
    setActiveTab(sessionStorage.getItem("activeTab") ?? "datasetsTab");
  }, [activeTab]);

  const handleTabSelect = (eventKey) => {
    sessionStorage.setItem("activeTab", eventKey);
    setActiveTab(eventKey);
  };

  const handleAlert = (type, message) => {
    notify(type, message);
  };

  function renderTable() {
    if (activeTab === "datasetsTab") {
      return (
        <DatasetsPanel
          dataAssetType={"datasets"}
          setAlert={(type, message) => handleAlert(type, message)}
        />
      );
    } else if (activeTab === "documentsTab") {
      return (
        <ReferenceDocumentsPanel
          dataAssetType={"documents"}
          setAlert={(type, message) => handleAlert(type, message)}
        />
      );
    }
  }

  return (
    <>
      <Nav
        appearance="tabs"
        activeKey={activeTab}
        onSelect={handleTabSelect}
        justified
        onChange={renderTable}
      >
        <Nav.Item eventKey="datasetsTab" style={{ textAlign: "center" }}>
          Datasets
        </Nav.Item>

        <Nav.Item eventKey="documentsTab" style={{ textAlign: "center" }}>
          Reference documents
        </Nav.Item>
      </Nav>
      {renderTable()}
    </>
  );
};

export const DatasetManagement = () => {
  const { auth } = useContext(AuthContext);

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userRoles, setUserRoles] = useState();
  const [activeRole, setActiveRole] = useState();

  useEffect(() => {
    if (auth && auth.auth) {
      setUserInfo(auth.info.userInfo);
      setUserRoles(auth.info.userRoles);
      setActiveRole(auth.info.activeRole);

      setIsAuthenticated(true);
    }
  }, [auth]);

  return (
    <Container style={{ margin: "2rem" }}>
      {auth !== undefined && (
        <>
          <Panel header={<h3>Datasets manager</h3>}></Panel>
          <Panel>
            <UnauthenticatedTemplate>
              <UnauthenticatedLayout />
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
              {appRoleMap.get(activeRole) === "IM" ||
              appRoleMap.get(activeRole) === "NRE" ? (
                <>
                  {appRoleMap.get(activeRole) === "IM" && (
                    <DatasetManagementComponent />
                  )}
                  {appRoleMap.get(activeRole) === "NRE" && (
                    <Message> NRE not ready </Message>
                  )}
                </>
              ) : (
                <UnauthorizedLayout />
              )}
            </AuthenticatedTemplate>
          </Panel>
        </>
      )}
    </Container>
  );
};
