

export class PickerQuery {

	constructor(element, value) {

		//console.log("PickerQuery:", element, value);

		this.element = element;

		this.value = value;

	}

	build_filter = () => {

		let filter = [];

		let options = this.value.selected.map(uri => `<${uri}>`).join(", ");

		filter.push(`FILTER(?${this.element.id} IN (${options}))`);

		return filter;

	}

	build_extra_patterns = () => {

		let patterns = [];

		return patterns;
	}

	build_extra_vars = () => {

		let vars = [];

		return vars;

	}


}
