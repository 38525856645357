import React, { useEffect } from "react";
import {
	Container,
	SelectPicker,
	Button,
	ImageButton,
	Tag,
	Panel,
	FlexboxGrid,
	Input
} from "rsuite";



export const DecoratedInput = (props) => {

	const [id, setId] = React.useState(props.id);
	const [label, setLabel] = React.useState(props.label);
	const [unit, setUnit] = React.useState(props.unit);
	const [children, setChildren] = React.useState(props.children);
	const [selector, setSelector] = React.useState(props.selector);
	const [invisible, setInvisible] = React.useState(props.invisible);

	//console.log(props.defaults);

	useEffect(() => {

		if (props.selector !== selector) {

			setSelector(props.selector);

		}

		if (props.children !== children) {

			setChildren(props.children);

		}

		if (props.label !== label) {

			setLabel(props.label);

		}

		if (props.invisible !== invisible) {

			setInvisible(props.invisible);

		}

		if (props.unit !== unit) {

			setUnit(props.unit);

		}

	}, [props]);

	return (

		<FlexboxGrid
			justify="space-around"
			align="middle"
			key={id}
			style={{ padding: "1rem" }}
		>
			<FlexboxGrid.Item colspan={5}>
				{label}
			</FlexboxGrid.Item>
			<FlexboxGrid.Item colspan={17}>
				{children}
			</FlexboxGrid.Item>
			<FlexboxGrid.Item colspan={1}>
				{!invisible && selector}
			</FlexboxGrid.Item>
		</FlexboxGrid>
	);



}
