export const availableLanguages = {
  en: "English",
  es: "Español",
};

const phrases = {
  en: {
	opStartEnd: "SoL start and end OPs",
    routeCompatibilityCheck: "Route Compatibility Check",
    status: "Status",
    dataStories: "Data Stories",
    datasetManagement: "Data Assets Management",
    notificationsManagement: "Notifications Management",
    userManual: "User manual",
    landingTitle: "RINF - Register of Infrastructure",
    landingText: "Welcome to the Register of Infrastructure (RINF) System. This platform enables the Search of information regarding the characteristics and capabilities of operational points and sections of lines that belong to the static rail network, and the Route Compatibility Check (RCC) where the objective is to check if a certain railway vehicle can travel the route between two operational points. Vehicle type information is originated from the European Registry Authorized Type of Vehicle (ERATV).",
    statusTitle: "Data Status Per Country",
    statusText: "You will find the number of Section of Line (SoL), tracks per SoL, Operational Point (OP) and tracks per OP for each country that are part of the European Union Agency for Railways (ERA).",
    search: "Search",
    loading: "Loading",
    cancel: "Cancel",
    close: "Close",
    searchCriteria: "Search criteria",
    searchResult: "Search result",
    exportExcel: "Export to Excel",
    exportXML: "Export to XML",
    exportRDF: "Export to RDF",
    generatingFile: "Generating file",
    downloadingData: "Downloading data",
    searchItem: "Search item",
    total: "Total",
    termsOfUse: "Terms of use",
    copyrightNotice: "Copyright notice",
    privacyStatement: "Privacy statement",
    rinfAPI: "RINF API",
    from: "From",
    to: "To",
    maxNumberOfRoutes: "Max Number of Routes",
    initLoading: "Loading Operational Points and Vehicle Types...",
    fetchingData: "Fetching data from the Knowledge Graph...",
    selectVehicleType: "Select a Vehicle Type",
    calculatingRoutes: "Calculating Routes...",
    assemblingRoute: "Assembling route report...",
    route: "Route",
    copyLinkToClipboard: "Copy Link to Clipboard",
    name: "Name",
    type: "Type",
    rinfId: "RINF Identifier",
    description: "Description",
    tafTapCode: "TAF/TAP Code(s)",
    geographicalLocation: "Geographical Location",
    country: "Country",
    validityDateStart: "Validity Date Start",
    validityDateEnd: "Validity Date End",
    details: "Details",
    seeInternalConnectivity: "See Internal Connectivity",
    vehicleType: "Vehicle type",
    track: "Track",
    properties: "Properties",
    compatible: "Compatible",
    noData: "No Data",
    unknown: "Unknown",
    genericInformation: "Generic information",
    memberState: "Member state",
    moreCriteria: "More criteria",
    runningTrack: "Running track",
    declarationsOfVerificationForTrack:
      "Declarations of verification for track",
    performanceParameters: "Performance parameters",
    lineLayout: "Line layout",
    trackParameters: "Track parameters",
    tunnel: "Tunnel",
    platform: "Platform",
    siding: "Siding",
    declarationOfVerificationForSiding:
      "Declaration of verification for siding",
    fixedInstallationsForServicingTrains:
      "Fixed installations for servicing trains",
    contactLineSystem: "Contact line system",
    rulesAndRestriction: "Rules and restriction",
    infrastructureSubsystem: "Infrastructure subsystem",
    energySubsystem: "Energy subsystem",
    switchesAndCrossings: "Switches and crossings",
    trackResistanceToAppliedLoads: "Track resistance to applied loads",
    healthSafetyAndEnvironment: "Health, safety and environment",
    pantograph: "Pantograph",
    oclSeparationSections: "OCL separation sections",
    requirementsForRollingStock: "Requirements for rolling stock",
    controlCommandAndSignallingSubsystem:
      "Control-command and signalling subsystem",
    tsiCompliantTrainProtectionSystem:
      "TSI compliant train protection system (ETCS)",
    tsiCompliantRadio: "TSI compliant radio (GSM-R)",
    trainDetectionSystemsFullyCompliantWithTheTSI:
      "Train detection systems fully compliant with the TSI",
    trainProtectionLegacySystems: "Train protection legacy systems",
    otherRadioSystems: "Other radio systems",
    trainDetectionSystemsNotFullyCompliantWithTheTSI:
      "Train detection systems not fully compliant with the TSI",
    transitionsBetweenSystems: "Transitions between systems",
    parametersRelatedToElectromagneticInterferences:
      "Parameters related to electromagnetic interferences",
    lineSideSystemForDegradedSituation:
      "Line-side system for degraded situation",
    brakeRelatedParameters: "Brake related parameters",
    otherCCSRelatedParameters: "Other CCS related parameters",
    trackParams: "Track Params",
    lineParams: "Line Params",
    tunnelParams: "Tunnel Params",
    contactLineSystemParams: "Contact Line System Params",
    currentLimitationParam: "Current Limitation Params",
    trainProtectionSystemParams: "Train Protection System Params",
    trainDetectionSystemParams: "Train Detection System Params",
    imCode: "IM Code",
    nationalLine: "National Line",
    operationalPointStart: "Operational Point Start",
    operationalPointEnd: "Operational Point End",
    opStartLocation: "OP start location",
    opEndLocation: "OP end location",
    lengthOfSOL: "Length Of SOL",
    lengthOfTrack: "Length Of Track",
    solId: "Section of Line ID",
    howToUse: "How to use this application",
    routeDescription: "Getting route description...",
    loadingInitData: "Fetching initial data...",
    via: "Via",
    viaPoint: "Via point",
    wrongPermalink:
      "Something went wrong with the permalink parameters. Please make sure you have the right link.",
    disconnectedOP: (op) => {
      return `The Operational Point ${op} is not connected to the railway infrastructure`;
    },
    "http://publications.europa.eu/resource/authority/country/ITA": "Italy",
    "http://publications.europa.eu/resource/authority/country/CZE": "Czechia",
    "http://publications.europa.eu/resource/authority/country/FIN": "Finland",
    "http://publications.europa.eu/resource/authority/country/BEL": "Belgium",
    "http://publications.europa.eu/resource/authority/country/FRA": "France",
    "http://publications.europa.eu/resource/authority/country/GBR":
      "United Kingdom",
    "http://publications.europa.eu/resource/authority/country/SWE": "Sweden",
    "http://publications.europa.eu/resource/authority/country/GRC": "Greece",
    "http://publications.europa.eu/resource/authority/country/PRT": "Portugal",
    "http://publications.europa.eu/resource/authority/country/DNK": "Denmark",
    "http://publications.europa.eu/resource/authority/country/BGR": "Bulgaria",
    "http://publications.europa.eu/resource/authority/country/HUN": "Hungary",
    "http://publications.europa.eu/resource/authority/country/CHE":
      "Switzerland",
    "http://publications.europa.eu/resource/authority/country/ROU": "Romania",
    "http://publications.europa.eu/resource/authority/country/DEU": "Germany",
    "http://publications.europa.eu/resource/authority/country/AUT": "Austria",
    "http://publications.europa.eu/resource/authority/country/NOR": "Norway",
    "http://publications.europa.eu/resource/authority/country/NLD":
      "Netherlands",
    "http://publications.europa.eu/resource/authority/country/EST": "Estonia",
    "http://publications.europa.eu/resource/authority/country/LTU": "Lithuania",
    "http://publications.europa.eu/resource/authority/country/LUX":
      "Luxembourg",
    "http://publications.europa.eu/resource/authority/country/ESP": "Spain",
    "http://publications.europa.eu/resource/authority/country/LVA": "Latvia",
    "http://publications.europa.eu/resource/authority/country/HRV": "Croatia",
    "http://publications.europa.eu/resource/authority/country/POL": "Poland",
    "http://publications.europa.eu/resource/authority/country/SVN": "Slovenia",
    "http://publications.europa.eu/resource/authority/country/SVK": "Slovakia",
  },
  es: {
    routeCompatibilityCheck: "Verificación de Compatibilidad de Rutas",
    search: "Buscar",
    loading: "Cargando",
    cancel: "Cancelar",
    close: "Cerrar",
    searchCriteria: "Criterios de Busqueda",
    searchResult: "Resultados de Busqueda",
    exportExcel: "Exportar a Excel",
    exportXML: "Exportar a XML",
    exportRDF: "Exportar a RDF",
    generatingFile: "Generando archivo",
    downloadingData: "Descargando datos",
    searchItem: "Buscar elemento",
    total: "Total",
    termsOfUse: "Términos de Uso",
    copyrightNotice: "Aviso de Copyright",
    privacyStatement: "Declaración de Privacidad",
    rinfAPI: "API RINF",
    from: "Desde",
    to: "Hasta",
    maxNumberOfRoutes: "Número Máximo de Rutas",
    initLoading: "Cargando Puntos Operacionales y Tipos de Vehiculos...",
    fetchingData: "Cargando datos del Grafo de Conocimiento...",
    selectVehicleType: "Seleccione un tipo de Vehículo",
    calculatingRoutes: "Calculando rutas...",
    assemblingRoute: "Ensamblando reporte de ruta...",
    route: "Ruta",
    copyLinkToClipboard: "Copian enlace en la papelera",
    name: "Nombre",
    type: "Tipo",
    rinfId: "Identificador RINF",
    description: "Descripción",
    tafTapCode: "Código(s) TAF/TAP",
    geographicalLocation: "Localización Geográfica",
    country: "País",
    validityDateStart: "Fecha Inicial de Validez",
    validityDateEnd: "Fechan Final de Validez",
    details: "Detalles",
    seeInternalConnectivity: "Ver Conectividad Interna",
    vehicleType: "Tipo de Vehículo",
    track: "Vía Ferroviaria",
    properties: "Propiedades",
    compatible: "Compatible",
    noData: "No hay datos",
    unknown: "Desconocido",
    genericInformation: "Información General",
    memberState: "Estado Miembro",
    moreCriteria: "Más Criterios",
    runningTrack: "Vía Ferroviaria",
    declarationsOfVerificationForTrack: "Declaración de Verificación para vía",
    performanceParameters: "Parámetros de desempeño",
    lineLayout: "Diseño de linea",
    trackParameters: "Parámetros de vía",
    tunnel: "Tunel",
    platform: "Plataforma",
    siding: "Vía lateral",
    declarationOfVerificationForSiding:
      "Declaración de Verificación para vía lateral",
    fixedInstallationsForServicingTrains:
      "Instalaciones fijas para servicio de trenes",
    contactLineSystem: "Sistema de contacto de linea",
    rulesAndRestriction: "Reglas y restricciones",
    infrastructureSubsystem: "Subsistema de infraestructura",
    energySubsystem: "Subsistema de energía",
    switchesAndCrossings: "Intercambios y cruces",
    trackResistanceToAppliedLoads: "Resistencia de vía a cargas aplicadas",
    healthSafetyAndEnvironment: "Salud, seguridad y entorno",
    pantograph: "Pantógrafo",
    oclSeparationSections: "Separación de secciones OCL",
    requirementsForRollingStock: "Requerimientos para material rodante",
    controlCommandAndSignallingSubsystem:
      "Subsistema de señalización y comandos de control",
    tsiCompliantTrainProtectionSystem:
      "Sistema de protección de trenes conforme a TSI (ETCS)",
    tsiCompliantRadio: "Radio conforme a TSI (GSM-R)",
    trainDetectionSystemsFullyCompliantWithTheTSI:
      "Sistema de detección de trenes conforme a TSI",
    trainProtectionLegacySystems: "Sistemas de protección de trenes legados",
    otherRadioSystems: "Otros sistemas de radio",
    trainDetectionSystemsNotFullyCompliantWithTheTSI:
      "Sistemas de detección de trenes no completamente compatibles con TSI",
    transitionsBetweenSystems: "Transiciones entre sistemas",
    parametersRelatedToElectromagneticInterferences:
      "Parámetros relacionados con interferencias electromagnéticas",
    lineSideSystemForDegradedSituation:
      "Sistemas de linea para situaciones de degradación",
    brakeRelatedParameters: "Parámetros relacionados con frenado",
    otherCCSRelatedParameters: "Otros parámetros relacionados con CSS",
    trackParams: "Parámetros de Vía",
    lineParams: "Parámtros de Linea",
    tunnelParams: "Parámetros de Tunel",
    contactLineSystemParams: "Parámetros de Sistema de Contacto de Linea",
    currentLimitationParam: "Parámetros de Limitación de Corriente",
    trainProtectionSystemParams:
      "Parámetros de Sistema de Protección de Trenes",
    trainDetectionSystemParams: "Parámetros de Sistema de Detección de Trenes",
    imCode: "Código AI",
    nationalLine: "Linea Nacional",
    operationalPointStart: "Punto Operacional de Partida",
    operationalPointEnd: "Punto Operacional de Llegada",
    lengthOfSOL: "Longitud de Sección de Linea",
    lengthOfTrack: "Longitud de Vía",
    solId: "ID de Sección de Linea",
    howToUse: "Cómo usar esta aplicación",
    routeDescription: "Obteniendo descripción de ruta...",
    loadingInitData: "Cargando datos iniciales...",
    wrongPermalink:
      "Algo salió mal con los parámetros del permalink. Por favor asegurese de tener el enlace correcto.",
    via: "Pasando por",
    viaPoint: "Punto de ruta",
    disconnectedOP: (op) => {
      return `El Punto Operacional ${op} no está conectado a la infraestructura ferroviaria`;
    },
    "http://publications.europa.eu/resource/authority/country/ITA": "Italia",
    "http://publications.europa.eu/resource/authority/country/CZE":
      "República Checa",
    "http://publications.europa.eu/resource/authority/country/FIN": "Finlandia",
    "http://publications.europa.eu/resource/authority/country/BEL": "Bélgica",
    "http://publications.europa.eu/resource/authority/country/FRA": "Francia",
    "http://publications.europa.eu/resource/authority/country/GBR":
      "Reino Unido",
    "http://publications.europa.eu/resource/authority/country/SWE": "Suecia",
    "http://publications.europa.eu/resource/authority/country/GRC": "Grecia",
    "http://publications.europa.eu/resource/authority/country/PRT": "Portugal",
    "http://publications.europa.eu/resource/authority/country/DNK": "Dinamarca",
    "http://publications.europa.eu/resource/authority/country/BGR": "Bulgaria",
    "http://publications.europa.eu/resource/authority/country/HUN": "Hungría",
    "http://publications.europa.eu/resource/authority/country/CHE": "Suiza",
    "http://publications.europa.eu/resource/authority/country/ROU": "Rumania",
    "http://publications.europa.eu/resource/authority/country/DEU": "Alemania",
    "http://publications.europa.eu/resource/authority/country/AUT": "Austria",
    "http://publications.europa.eu/resource/authority/country/NOR": "Noruega",
    "http://publications.europa.eu/resource/authority/country/NLD":
      "Paises Bajos",
    "http://publications.europa.eu/resource/authority/country/EST": "Estonia",
    "http://publications.europa.eu/resource/authority/country/LTU": "Lituania",
    "http://publications.europa.eu/resource/authority/country/LUX":
      "Luxemburgo",
    "http://publications.europa.eu/resource/authority/country/ESP": "España",
    "http://publications.europa.eu/resource/authority/country/LVA": "Letonia",
    "http://publications.europa.eu/resource/authority/country/HRV": "Croacia",
    "http://publications.europa.eu/resource/authority/country/POL": "Polonia",
    "http://publications.europa.eu/resource/authority/country/SVN": "Eslovenia",
    "http://publications.europa.eu/resource/authority/country/SVK":
      "Eslovaquia",
  },
};

export function getCurrentLanguage() {
  return window.localStorage.getItem("language") || "en";
}

export function getPhrase(code, language, ...params) {
  if (!language) {
    language = getCurrentLanguage();
  }
  if (!phrases[language]) {
    return phrases.en[code] || code;
  }

  if (typeof phrases[language][code] === "function") {
    return phrases[language][code](...params);
  } else {
    return phrases[language][code] || phrases.en[code] || code;
  }
}
