import React, { Component } from "react";
import ReactMapboxGl, { ZoomControl, AttributionControl, NavigationControl } from "react-mapbox-gl";
import queryString from 'query-string';
import { parse as wktParse } from 'wellknown';

import 'rsuite/dist/rsuite.min.css';

// Local dependencies

import { NetworkGraph } from '../../algorithm/NetworkGraph.js';
import { verifyCompatibility } from '../../algorithm/Compatibility.js';
import { verifyCompatibilityNew } from '../../algorithm/NewCompatibility.js';
import Utils from '../../utils/Utils.js';
import { ERA, GEOSPARQL, RDFS, CLS } from '../../utils/NameSpaces.js';
import { getPhrase } from "../../utils/Languages.js";

// Submodules/subcomponents

import { OperationalPointsLayer } from './OperationalPointsLayer.js';
import { RouteQuery } from "./RouteQuery.js";
import { RoutesLayer } from './RoutesLayer.js';
import { RoutesInfo } from './RoutesInfo.js';
import { RoutesExport } from './RoutesExport.js';
import { RoutesPermalink, loadPermalinkInfo } from './RoutesPermalink.js';
import { OPPopup } from "./OPPopup.js";

import { RCCDisclaimerModal } from "./MainComponents/RCCDisclaimer.js";
import { MapDisclaimer, MapDisclaimerModal } from "./MainComponents/MapDisclaimer.js";


import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// Component imports

import {
	Container,
	Sidebar,
	Sidenav,
	SelectPicker,
	Content,
	Message,
	Divider,
	Loader
} from "rsuite";

import {
	EraIcon
} from "../../styles/Icon.js";

import {
	infoButton,
	selectStyle,
	sideBar,
	sidebarHeader,
	stickyMenu,
	mapStyle,
	LoadingGIF,
	routeStyle
} from '../../styles/Styles.js';

//

import {
	ROUTER_API
} from '../../config/config.js';


const MapBox = ReactMapboxGl({
	accessToken:
		"pk.eyJ1Ijoic3VzaGlsZ2hhbWJpciIsImEiOiJjazUyZmNvcWExM2ZrM2VwN2I5amVkYnF5In0.76xcCe3feYPHsDo8eXAguw"
});

export default class RouteCompatibility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			center: [4.360854, 50.859658],
			zoom: [3],
			queryBounds: null,

			queryBoundsOpts: {
				padding: { top: 70, bottom: 70, right: 70, left: 70 }
			},
			bounds: null,

			displayOPs: {},
			popup: null,
			loading: false,

			helpPage: false,
			tileFrames: new Map(),
			operationalPoints: [],
			fetchingOPs: false,
			vehicleTypes: [],
			fetchingVTs: false,
			routes: [],
			route: {},
			loaderMessage: null,

			showReport: false,
			errorMessage: null,

			pathColor: null,
			language: "en",

			from: {},
			to: {},
			via: {},

			compatibilityVehicleType: {},

			map: null,

			rccDisclaimer: false,
			mapDisclaimer: false

		};

		//console.log(this.state);

	}

	componentDidMount() {

		let loadedState = loadPermalinkInfo(this.props.location.search);

		this.setState(loadedState);

		//console.log("Loading information from permalink ", loadedState);
	}

	componentDidUpdate() {
		const { language } = this.state;
		const storedLanguage = window.localStorage.getItem("language") || "en";

		if (language !== storedLanguage) {
			this.setState({ language: storedLanguage });
		}

	}


	toggleLoading = (value) => {
		this.setState({ loading: value });
	}

	toggleError = (type, text) => {

		let message = null;

		if (type && text) {

			message = { "type": type, "value": text }
		}

		this.setState({ errorMessage: message });
	}

	showReport = (value) => {
		this.setState({ showReport: value });
	}

	popupData = data => {
		this.setState({ popup: data });
	}

	closePopup = () => {
		this.setState({ popup: null });
	}

	focusMap = (center, zoom) => {
		this.setState({ center: [center[0], center[1]], zoom });
	};

	fitMap = coords => {
		return new Promise(resolve => {
			this.setState({ queryBounds: coords }, () => {
				resolve();
			})
		});
	}

	planRoute = async (from, to, via) => {

		let viaQuery = "";

		let new_routing = false;

		let routerQuery;

		if (new_routing) {

			if (via.length > 0) {
				viaQuery = via.map(v => `${v.id}`).join(';');
			}

			routerQuery = `${ROUTER_API}?type=new&from=${from.id}&to=${to.id}&via=${viaQuery}`;

		} else {

			if (via.length > 0) {
				viaQuery = via.map(v => `${v.lngLat}`).join(';');
			}

			routerQuery = `${ROUTER_API}?type=legacy&from=${from.lngLat}&to=${to.lngLat}&via=${viaQuery}`;

		}

		// Start loading animations

		this.setLoaderMessage(getPhrase('calculatingRoutes', this.state.language));

		const t0 = new Date();
		const res = await fetch(routerQuery);

		res.json().then(route => {

			if (route && route.length > 0) {
				// Check that route corresponds with the given locations
				//console.log('Route calculated in', new Date() - t0, 'ms');
				// Report found route
				this.setState({
					routes: [{
						path: route,
						renderNodes: false,
						style: routeStyle
					}]
				});

				//this.setLoaderMessage("Performing route compatibility check for "+((route.length/2)-1)+" tracks...");

				this.fitMap([route[0].lngLat, route[route.length - 1].lngLat]);

			} else {

				//console.log("No route");

				this.toggleError("warning", "No route found for the specified operational points.");

				this.setLoaderMessage(null);
				// There are no routes between the given OPs
				//Notification.warning('There are no possible routes between these two locations', 10000);
			}



		});

	}

	checkCompatibility = async route => {

		if (this.state.compatibilityVehicleType.id) {

			let report = await verifyCompatibilityNew(route, this.state.compatibilityVehicleType);

			this.setLoaderMessage("Preparing report...");

			return report;
		}
	};

	persistCompatibilityReports = (route) => {
		//console.log("Setting route info", route);
		this.setState({ route: route });
	};

	setLoaderMessage = (value) => {
		this.setState({ loaderMessage: value });
	}

	clearRoutes = () => {

		//console.log("Route cleanup");

		this.setLoaderMessage(null);

		this.toggleError(null, null);

		this.setState({
			route: {},
			routes: [],
			loading: false,
			showReport: false,
		},);

	}

	toggleInternalView = (show, mn, route) => {
		this.setState({
			internalView: show,
			internalViewNode: mn,
			internalViewPath: route ? route.path : null,
			pathColor: route ? route.style['line-color'] : null
		});
	};

	toggleHelpPage = show => {
		//this.setState({ helpPage: show });
	}


	updateGlobalState = (stateUpdate) => {
		this.setState(stateUpdate);
		//console.log("Global state update", stateUpdate, this.state);
	}

	onShowRCCDisclaimer = () => {
		this.setState({ rccDisclaimer: true });
	}

	onHideRCCDisclaimer = () => {
		this.setState({ rccDisclaimer: false });
	}

	onShowMapDisclaimer = () => {
		this.setState({ mapDisclaimer: true });
	}

	onHideMapDisclaimer = () => {
		this.setState({ mapDisclaimer: false });
	}

	onMapLoad = (map) => {
		//console.log(map);
		this.setState({ map: { current: map } });
	}

	render() {
		const {
			center,
			zoom,
			queryBounds,
			queryBoundsOpts,
			displayOPs,
			popup,
			loading,
			tileFrames,
			helpPage,
			showTileFrames,

			routes,
			route,
			loaderMessage,
			compatibilityVehicleType,
			language,
			from,
			to,
			via,

			showReport,
			errorMessage,
		} = this.state;

		return (
			<>

				<RCCDisclaimerModal show={this.state.rccDisclaimer} onClose={this.onHideRCCDisclaimer} />
				<MapDisclaimerModal show={this.state.mapDisclaimer} onClose={this.onHideMapDisclaimer} />


				<Container style={{ height: "calc(100vh - 150px)" }}>

					<Sidebar style={sideBar}>
						<div style={stickyMenu}>
							<Sidenav.Header>
								<div style={sidebarHeader}>
									<span style={{ marginLeft: 12 }}>{getPhrase("routeCompatibilityCheck", language)}</span>
									<EraIcon faName="triangle-exclamation" style={infoButton}
										title={getPhrase("howToUse", language)} onClick={this.onShowRCCDisclaimer} />
								</div>
							</Sidenav.Header>

							<RouteQuery

								style={{ padding: "5px" }}

								globalState={this.state}
								setGlobalState={this.updateGlobalState}

								focusMap={this.focusMap}
								fitMap={this.fitMap}
								planRoute={this.planRoute}
								clearRoutes={this.clearRoutes}
							>
							</RouteQuery>



							<Divider />
						</div>



						<RoutesInfo
							from={from}
							to={to}
							routes={routes}

							globalState={this.state}
							setGlobalState={this.updateGlobalState}

							visibility={showReport}
							compatibilityVehicleType={compatibilityVehicleType}
							checkCompatibility={this.checkCompatibility}
							setLoaderMessage={this.setLoaderMessage}
							persistCompatibilityReports={this.persistCompatibilityReports}
							language={language}>

						</RoutesInfo>

						{loaderMessage && (
							<Loader vertical style={{ width: "200px", marginLeft: "calc(50% - 100px)" }} size={'md'} speed={'normal'} content={loaderMessage}></Loader>
						)}

						{errorMessage && (
							<Message showIcon type={errorMessage.type} header="">
								{errorMessage.value}
							</Message>
						)}

						{!loaderMessage && (
							<RoutesPermalink
								from={from}
								to={to}
								via={via}
								compatibilityVehicleType={compatibilityVehicleType}
							/>
						)
						}

					</Sidebar>
					<Container>
						<Content>
							<MapBox
								//ref={this.mapRef}
								style="mapbox://styles/mapbox/light-v10"
								containerStyle={mapStyle}
								center={center}
								zoom={zoom}
								fitBounds={queryBounds}
								fitBoundsOptions={queryBoundsOpts}
								antiAlias={true}
								onStyleLoad={this.onMapLoad}
							>
								<RoutesLayer
									route={route}
								></RoutesLayer>



								{popup && (
									<OPPopup
										popup={popup}
										language={language}
										closePopup={this.closePopup}>
									</OPPopup>
								)}


								<MapDisclaimer
									map={this.state.map}
									onClick={this.onShowMapDisclaimer}
								/>

							</MapBox>


						</Content>
					</Container>
				</Container>
			</>
		);
	}
}

