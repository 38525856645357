import { saveAs } from "file-saver";
import { some, xor } from 'lodash';

import {
	SPARQL_ENDPOINT,
	NAMED_KG_RINF
} from "../../../config/config.js";

import WebworkerPromise from 'webworker-promise';

import OPFullQuery from '../queries/all_parameters_op.sparql';
import SoLFullQuery from '../queries/all_parameters_sol.sparql';


import JSZip from "jszip";

// We use the list of OP or SoL to export all the information related

const readme = "### RINF EXPORT ###\n\n" +

	"This data export is available as a compressed zip file with several files that contain parts of the data, so that you can merge them in your own device.\n" +
	"Note that merging NTriples files can be done by concatenating all files together, but merging JSON-LD and RDF/XML files needs some further processing.\n" +
	"For example, you can use CLI tools like 'riot' provided by Jena: https://jena.apache.org/documentation/io/ or libraries as rdflib for Python https://rdflib.readthedocs.io/en/stable/intro_to_parsing.html\n";

export class NTFullExport {

	constructor(query, formDefinition, skosValues, data, sparqlQuery) {

		this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

		this.rootObject = query.rootObject;

		if (this.rootObject === "http://data.europa.eu/949/OperationalPoint") {

			this.query = OPFullQuery;
			this.query = this.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);

			this.entity_name = "operational_points";

			this.entity_list = [];

			for (let entry of data.results.bindings) {

				this.entity_list.push(entry["e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"].value)

			}

			//console.log(this.entity_list);

		}

		if (this.rootObject === "http://data.europa.eu/949/SectionOfLine") {

			this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

			this.rootObject = query.rootObject;

			if (this.rootObject === "http://data.europa.eu/949/SectionOfLine") {

				this.query = SoLFullQuery;
				this.query = this.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);

				this.entity_name = "sections_of_line";

				this.entity_list = [];

				for (let entry of data.results.bindings) {

					this.entity_list.push(entry["fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc"].value)

				}

				//console.log(this.entity_list);

			}


		}

	}

	cancel() {

		//console.log("Cancelled!");

		this.cancelled = true;

	}

	async generateFile() {

		let part_size = 1000;

		let done = 0;

		let retry_count = 0;

		let full_data = [];

		let zipfile = new JSZip();

		let format_name = "ntriples"
		let origin = "search"

		while (done < this.entity_list.length && !this.cancelled && retry_count < 3) {

			//console.log("Done", done, "entities");

			let entity_part = this.entity_list.slice(done, done + part_size)

			let query_part = "<" + entity_part.join("> <") + ">"

			let query = this.query.replace("${{entity_list}}", query_part)

			let result = await this.executor.exec("construct_query", { data: { endpoint: SPARQL_ENDPOINT, query: query, format: "application/n-triples" } });

			if (result !== null) {

				zipfile.file("parts/" + this.entity_name + "_" + (done) + "_to_" + (done + part_size) + ".nt", result)

				//full_data.push(result);

				done += part_size;

				retry_count = 0;

			} else {

				retry_count += 1;

			}

		}

		if (!this.cancelled) {

			zipfile.file("README.txt", readme);

			let promise;

			if (JSZip.support.uint8array) {
				promise = zipfile.generateAsync({ type: "uint8array" });
			} else {
				promise = zipfile.generateAsync({ type: "string" });
			}

			const blob = new Blob([await promise], { type: "application/zip" });

			let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)

			saveAs(blob, "rinf_export_" + origin + "_" + format_name + "_" + date + ".zip");

		}

	}

}


export class RDFFullExport {

	constructor(query, formDefinition, skosValues, data, sparqlQuery) {

		this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

		this.rootObject = query.rootObject;

		if (this.rootObject === "http://data.europa.eu/949/OperationalPoint") {

			this.query = OPFullQuery;
			this.query = this.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);

			this.entity_name = "operational_points";

			this.entity_list = [];

			for (let entry of data.results.bindings) {

				this.entity_list.push(entry["e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"].value)

			}

			//console.log(this.entity_list);

		}

		if (this.rootObject === "http://data.europa.eu/949/SectionOfLine") {

			this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

			this.rootObject = query.rootObject;

			if (this.rootObject === "http://data.europa.eu/949/SectionOfLine") {

				this.query = SoLFullQuery;
				this.query = this.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);

				this.entity_name = "sections_of_line";

				this.entity_list = [];

				for (let entry of data.results.bindings) {

					this.entity_list.push(entry["fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc"].value)

				}

				//console.log(this.entity_list);

			}


		}

	}

	cancel() {

		//console.log("Cancelled!");

		this.cancelled = true;

	}

	async generateFile() {

		let part_size = 1000;

		let done = 0;

		let retry_count = 0;

		let full_data = [];

		let zipfile = new JSZip();

		let format_name = "rdf-xml"
		let origin = "search"

		while (done < this.entity_list.length && !this.cancelled && retry_count < 3) {

			//console.log("Done", done, "entities");

			let entity_part = this.entity_list.slice(done, done + part_size)

			let query_part = "<" + entity_part.join("> <") + ">"

			let query = this.query.replace("${{entity_list}}", query_part)

			let result = await this.executor.exec("construct_query", { data: { endpoint: SPARQL_ENDPOINT, query: query, format: "application/rdf+xml" } });

			if (result !== null) {

				zipfile.file("parts/" + this.entity_name + "_" + (done) + "_to_" + (done + part_size) + ".rdf", result);

				//full_data.push(result);

				done += part_size;

				retry_count = 0;

			} else {

				retry_count += 1;

			}

		}

		if (!this.cancelled) {

			zipfile.file("README.txt", readme);

			let promise;

			if (JSZip.support.uint8array) {
				promise = zipfile.generateAsync({ type: "uint8array" });
			} else {
				promise = zipfile.generateAsync({ type: "string" });
			}

			const blob = new Blob([await promise], { type: "application/zip" });

			let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)

			saveAs(blob, "rinf_export_" + origin + "_" + format_name + "_" + date + ".zip");

		}

	}

}


export class JSONLDFullExport {

	constructor(query, formDefinition, skosValues, data, sparqlQuery) {


		this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

		this.rootObject = query.rootObject;

		if (this.rootObject === "http://data.europa.eu/949/OperationalPoint") {

			this.query = OPFullQuery;
			this.query = this.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);

			this.entity_name = "operational_points";

			this.entity_list = [];

			for (let entry of data.results.bindings) {

				this.entity_list.push(entry["e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"].value)

			}

			//console.log(this.entity_list);

		}

		if (this.rootObject === "http://data.europa.eu/949/SectionOfLine") {

			this.executor = new WebworkerPromise(new Worker(new URL("../workers/QueryExecuter.worker.js", import.meta.url)));

			this.rootObject = query.rootObject;

			if (this.rootObject === "http://data.europa.eu/949/SectionOfLine") {

				this.query = SoLFullQuery;
				this.query = this.query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);

				this.entity_name = "sections_of_line";

				this.entity_list = [];

				for (let entry of data.results.bindings) {

					this.entity_list.push(entry["fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc"].value)

				}

				//console.log(this.entity_list);

			}


		}

	}

	cancel() {

		//console.log("Cancelled!");

		this.cancelled = true;

	}

	async generateFile() {

		let part_size = 1000;

		let done = 0;

		let retry_count = 0;

		let full_data = [];

		let zipfile = new JSZip();

		let format_name = "json-ld"
		let origin = "search"

		while (done < this.entity_list.length && !this.cancelled && retry_count < 3) {

			//console.log("Done", done, "entities");

			let entity_part = this.entity_list.slice(done, done + part_size)

			let query_part = "<" + entity_part.join("> <") + ">"

			let query = this.query.replace("${{entity_list}}", query_part)

			let result = await this.executor.exec("construct_query", { data: { endpoint: SPARQL_ENDPOINT, query: query, format: " application/ld+json" } });

			if (result !== null) {

				zipfile.file("parts/" + this.entity_name + "_" + (done) + "_to_" + (done + part_size) + ".jsonld", JSON.stringify(result, null, 2));

				//full_data.push(result);

				done += part_size;

				retry_count = 0;

			} else {

				retry_count += 1;

			}

		}

		if (!this.cancelled) {

			zipfile.file("README.txt", readme);

			let promise;

			if (JSZip.support.uint8array) {
				promise = zipfile.generateAsync({ type: "uint8array" });
			} else {
				promise = zipfile.generateAsync({ type: "string" });
			}

			const blob = new Blob([await promise], { type: "application/zip" });

			let date = (new Date()).toISOString().replaceAll(":", "_").replaceAll("-", "_").slice(0, 19)

			saveAs(blob, "rinf_export_" + origin + "_" + format_name + "_" + date + ".zip");

		}

	}

}
