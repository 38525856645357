import sha256_digest from 'crypto-digest-sync/sha256.js';
import arrayBufferToHex from 'array-buffer-to-hex';
import encodeUtf8 from 'encode-utf8';


export const sha256_sync = message => {
		
	const input = encodeUtf8(message);
	const hash = sha256_digest(input);
	const hashHex = arrayBufferToHex(hash);
	
	return hashHex;
	
}



