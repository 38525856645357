import {
  Container,
  Message,
  Nav,
  Panel,
  PanelGroup,
  Notification,
  Button,
  ButtonToolbar,
  IconButton,
  List,
  Tag,
  Loader,
  Input,
} from "rsuite";

import React, { useState, useContext, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";

import { appRoleMap } from "../../azure/authConfig.js";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { UnauthorizedLayout } from "../Authentication/UnauthorizedLayout.js";
import { UnauthenticatedLayout } from "../Authentication/UnauthenticatedLayout.js";

import { useMsal } from "@azure/msal-react";

import { EraIcon } from "../../styles/Icon.js";

import { AuthContext } from "./../App/Main.js";

import { Subscription } from "./MainComponents/Main.js";

import { CreateSubscription } from "./MainComponents/CreateSubscription.js";

import {
  loadInfo,
  loadSubscriptions,
  subscriptionAdd,
  subscriptionDelete,
} from "./NotificationsService.js";

export const NotificationsManager = () => {
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);

  const [subscriptions, setSubscriptions] = useState([]);

  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [create, setCreate] = useState(false);
  const [activeRole, setActiveRole] = useState(0);

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [userRoles, setUserRoles] = useState();

  const { instance } = useMsal();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    console.log(auth);

    if (auth && auth.auth) {
      setUserRoles(auth.info.userRoles);
      setActiveRole(auth.info.activeRole);
      setIsAuthenticated(true);
    }
  }, [auth]);

  const onLoadInformation = async () => {
    let requestUser = await loadInfo(instance);
    setUserInfo(requestUser);
  };

  const onLoadSubscriptions = async () => {
    let requestSubscriptions = await loadSubscriptions(instance);

    //console.log(requestSubscriptions);
    setSubscriptions(requestSubscriptions);
    setFilteredSubscriptions(requestSubscriptions);
  };

  useEffect(() => {
    if (isAuthenticated && appRoleMap.get(activeRole) === "RRU") {
      onLoadInformation();
      onLoadSubscriptions();
    }
  }, [isAuthenticated]);

  const onSubscriptionDialog = () => {
    setCreate(true);
  };

  const onSubscriptionDialogClose = () => {
    setCreate(false);
  };

  const onSubscriptionAdd = async (type, value) => {
    await subscriptionAdd(instance, type, value);
    await onLoadSubscriptions();
  };

  const onSubscriptionDelete = async (id) => {
    await subscriptionDelete(instance, id);
    await onLoadSubscriptions();
  };

  const onSubscriptionsFilter = (filter) => {
    let newSubscriptions = subscriptions.filter((x) => {
      //console.log(x)

      let typeBool =
        x.data.type && x.data.type.toLowerCase().includes(filter.toLowerCase());

      let valueBool = false;

      let labelBool = false;

      if (Array.isArray(x.data.value)) {
        for (let y of x.data.value) {
          //console.log(y);

          valueBool =
            valueBool ||
            (typeof y === "string" &&
              y.toLowerCase().includes(filter.toLowerCase())) ||
            (typeof y.value === "string" &&
              y.value.toLowerCase().includes(filter.toLowerCase()));

          labelBool =
            labelBool ||
            (typeof y.label === "string" &&
              y.label.toLowerCase().includes(filter.toLowerCase()));
        }
      } else {
        valueBool =
          (typeof x.data.value === "string" &&
            x.data.value.toLowerCase().includes(filter.toLowerCase())) ||
          (typeof x.data.value.value === "string" &&
            x.data.value.value.toLowerCase().includes(filter.toLowerCase()));

        labelBool =
          typeof x.data.value.label === "string" &&
          x.data.value.label.toLowerCase().includes(filter.toLowerCase());
      }

      return typeBool || valueBool || labelBool;
    });

    setFilteredSubscriptions(newSubscriptions);
  };

  return (
    <Container style={{ margin: "2rem" }}>
      {auth !== undefined && (
        <>
          <Panel header={<h3>Notifications manager</h3>}></Panel>

          <UnauthenticatedTemplate>
            <UnauthenticatedLayout />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            {appRoleMap.get(activeRole) === "RRU" ? (
              <>
                <CreateSubscription
                  onCreate={onSubscriptionAdd}
                  onClose={onSubscriptionDialogClose}
                  open={create}
                ></CreateSubscription>

                <Panel>
                  <ButtonToolbar>
                    <Input
                      placeholder={"Search for a subscription..."}
                      style={{ width: "50em" }}
                      onChange={onSubscriptionsFilter}
                    ></Input>
                    <div style={{ flexGrow: 1 }}></div>
                    <IconButton
                      onClick={onSubscriptionDialog}
                      appearance={"primary"}
                      color={"green"}
                      placement={"left"}
                      size="md"
                      icon={<EraIcon faName={"add"} style={null} />}
                    >
                      Create subscription
                    </IconButton>
                  </ButtonToolbar>
                </Panel>

                <PanelGroup accordion bordered>
                  <Panel
                    key={"user"}
                    header={<h5>Recipient information</h5>}
                    collapsible={false}
                    expanded
                    bordered
                    style={{ marginBottom: "15px" }}
                  >
                    <List>
                      <List.Item>
                        User name: <Tag>{userInfo.name}</Tag>
                      </List.Item>
                      <List.Item>
                        Notifications mailbox: <Tag>{userInfo.mail}</Tag>
                      </List.Item>
                    </List>
                  </Panel>

                  <Panel
                    key={"subscriptions"}
                    header={<h5>Active subscriptions</h5>}
                    collapsible={false}
                    expanded
                    bordered
                    style={{ marginBottom: "15px" }}
                  >
                    {loading && (
                      <Loader
                        style={{
                          width: "200px",
                          marginLeft: "calc(50% - 100px)",
                        }}
                        vertical
                        content="Loading subscriptions..."
                        size="md"
                      />
                    )}

                    {!loading &&
                      filteredSubscriptions.map((subscription, index) => {
                        return (
                          <Subscription
                            key={"subscription-" + subscription.id}
                            id={subscription.id}
                            index={index}
                            data={subscription.data}
                            onDelete={onSubscriptionDelete}
                          ></Subscription>
                        );
                      })}
                  </Panel>
                </PanelGroup>
              </>
            ) : (
              <UnauthorizedLayout />
            )}
          </AuthenticatedTemplate>
        </>
      )}
    </Container>
  );
};
