import React from "react";

import { Modal, Form, Message, Loader, SelectPicker, IconButton } from "rsuite";

import {
  NTFullExport,
  JSONLDFullExport,
  RDFFullExport,
} from "./ExportComponents/ConstructFullExport.js";

import { EraIcon } from "../../styles/Icon.js";

export class Export extends React.Component {
  constructor(props) {
    super(props);

    this.exportModules = [
      {
        label: "RDF/XML",
        value: "rdf-full",
        component: RDFFullExport,
        type: "Linked data full dump",
      },
      {
        label: "N-Triples",
        value: "nt-full",
        component: NTFullExport,
        type: "Linked data full dump",
      },
      {
        label: "JSON-LD",
        value: "json-ld-full",
        component: JSONLDFullExport,
        type: "Linked data full dump",
      },
    ];

    ({ data: this.data, onClose: this.onClose } = props);

    this.state = {
      selection: null,
      isOpen: true,
      running: false,
      error: false,
    };

    this.exporter;

    //console.log("Export")
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen != this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }

    if (JSON.stringify(prevProps.data) != JSON.stringify(this.props.data)) {
      this.data = this.props.data;
    }

    if (
      JSON.stringify(prevProps.formDefinition) !=
      JSON.stringify(this.props.formDefinition)
    ) {
      this.formDefinition = this.props.formDefinition;
    }

    if (JSON.stringify(prevProps.query) != JSON.stringify(this.props.query)) {
      this.query = this.props.query;
    }

    if (
      JSON.stringify(prevProps.sparqlQuery) !=
      JSON.stringify(this.props.sparqlQuery)
    ) {
      this.sparqlQuery = this.props.sparqlQuery;

      //console.log(this.sparqlQuery);
    }

    if (
      JSON.stringify(prevProps.skosValues) !=
      JSON.stringify(this.props.skosValues)
    ) {
      this.skosValues = this.props.skosValues;
    }
  }

  // Export UI

  onSelect = (value, item) => {
    this.setState({ selection: { ...this.state.selection, format: item } });
  };

  onClean = () => {
    this.setState({ selection: null });
  };

  onCancel = () => {
    try {
      if (this.exporter) {
        this.exporter.cancel();
      }
    } catch (e) {
      //console.log("Cancel not implemented for this exporter...");
    }
  };

  // Export main function

  onExport = async () => {
    this.setState({ running: true });

    // This is an ugly form to force UI update before a block in the main thread...

    await new Promise((r) => setTimeout(r, 100));
    this.exporter = new this.state.selection.format.component(this.data);

    let result = await this.exporter.generateFile();

    if (result) {
      this.setState({ running: false });
      this.onClose();
    } else {
      this.setState({ error: true, selection: null });
    }
  };

  render() {
    return (
      <Modal open={this.state.isOpen} onClose={this.onClose}>
        <Modal.Header>
          <Modal.Title>Export element properties</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!this.state.error && (
            <>
              {!this.state.running && (
                <Form fluid>
                  <SelectPicker
                    data={this.exportModules}
                    block={true}
                    groupBy={"type"}
                    onSelect={this.onSelect}
                    onClean={this.onClean}
                    searchable={false}
                  />
                </Form>
              )}

              {this.state.running && (
                <Loader
                  style={{
                    width: "400px",
                    marginLeft: "calc(50% - 200px)",
                    marginTop: "25px",
                  }}
                  content="Gathering data, this may take a while. Please be patient."
                  size="md"
                />
              )}
            </>
          )}

          {this.state.error && (
            <Message type="error">
              An error was found generating the data export, please try again
              later.
            </Message>
          )}
        </Modal.Body>

        <Modal.Footer>
          <IconButton
            onClick={this.onCancel}
            disabled={!this.state.running || !this.state.error}
            placement={"right"}
            icon={<EraIcon faName={"xmark"} style={null} />}
          >
            Cancel
          </IconButton>

          <IconButton
            onClick={this.onExport}
            appearance={"primary"}
            color={"green"}
            placement={"right"}
            disabled={this.state.selection === null || this.state.running}
            icon={<EraIcon faName={"file-export"} style={null} />}
          >
            Export
          </IconButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
