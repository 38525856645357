import wktParse from 'wellknown';
import * as rdfString from 'rdf-string';
import rdfjs from '@rdfjs/data-model';
import { a, ERA, GEOSPARQL, WGS84, RDFS, OWL, RDF, XSD, CLS, ESS, SKOS } from './NameSpaces.js';
import { deburr } from 'lodash';

const { stringQuadToQuad, quadToStringQuad } = rdfString;
const { namedNode, literal, blankNode } = rdfjs;

function validateCoords(coords) {
    if (!isNaN(coords[0]) && !isNaN(coords[1])) {
        return true;
    } else {
        return false;
    }
}

function text_normalize(text) {
    return deburr(text);
}

function long2Tile(long, zoom) {
    return (Math.floor((long + 180) / 360 * Math.pow(2, zoom)));
}

function lat2Tile(lat, zoom) {
    return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)));
}

function tile2long(x, z) {
    return (x / Math.pow(2, z) * 360 - 180);
}

function tile2lat(y, z) {
    var n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
    return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
}

function longLat2Tile(lngLat, z) {
    return `${long2Tile(lngLat[0], z)}/${lat2Tile(lngLat[1], z)}`;
}

function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

function printLiteral(literal) {
    if (literal.datatype) {
        switch (literal.datatype.value) {
            case XSD.decimal:
                // Round to 2 decimals
                return Math.round((parseFloat(literal.value) + Number.EPSILON) * 100) / 100;
            case XSD.double:
                // Round to 2 decimals
                return Math.round((parseFloat(literal.value) + Number.EPSILON) * 100) / 100;
            default:
                return literal.value;
        }
    }
    return literal.value;
}

// Returns the amount of pixels for a given relative viewport height
function vh(v) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

// Returns the amount of pixels for a given relative viewport width
function vw(v) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

function getTileFrame(coords, z, asXY) {
    let tile = null;
    if (asXY) {
        tile = { x: coords[0], y: coords[1] };
    } else {
        tile = { x: long2Tile(coords[0], z), y: lat2Tile(coords[1], z) };
    }
    // Get the coordinates for the clock-wise [A, B, C, D, A] square
    const A = [tile2long(tile.x, z), tile2lat(tile.y, z)];
    const B = [tile2long(tile.x + 1, z), tile2lat(tile.y, z)];
    const C = [tile2long(tile.x + 1, z), tile2lat(tile.y + 1, z)];
    const D = [tile2long(tile.x, z), tile2lat(tile.y + 1, z)];

    return [A, B, C, D, A];
}

function rebuildQuad(str) {
    return stringQuadToQuad(str);
}

function serializeQuad(quad) {
    return quadToStringQuad(quad);
}

function processTopologyQuads(quads, NG) {
    for (const quad of quads) {
        /**
         * Build rail Network Graph (NG) from RDF quads.
         * The NG is a data structure G = (V, E) 
         * where V are built from era:NetElement entities and
         * E are built from era:NetRelation entities,
         * which have been simplified by the SPARQL CONSTRUCT queries
         * to simple entities that are era:linkedTo each other.
         * 
        */
        if (quad.predicate.value === GEOSPARQL.asWKT) {
            // Got node geo coordinates
            NG.setNode({
                id: quad.subject.value,
                lngLat: wktParse(quad.object.value).coordinates
            });
        } else if (quad.predicate.value === ERA.length) {
            // Got era:length property
            NG.setNode({
                id: quad.subject.value,
                length: quad.object.value
            });
        } else if (quad.predicate.value === ERA.lineNationalId) {
            // Got era:lineNationalId property
            NG.setNode({
                id: quad.subject.value,
                lineNationalId: quad.object.value
            });
        } else if (quad.predicate.value === ERA.partOf) {
            // Got era:partOf property then link NG node to its meso-level entity
            NG.setNode({
                id: quad.subject.value,
                mesoElement: quad.object.value
            });
        } else if (quad.predicate.value === ERA.linkedTo) {
            // Got a era:linkedTo property that indicates a reachable node
            NG.setNode({
                id: quad.subject.value,
                nextNode: quad.object.value
            });
        }
    }
}

function rebuildGraphIndex(index, newStore) {
    // Get reference to the new triple store Symbols
    const $_KEYS = Object.getOwnPropertySymbols(newStore._h_quad_tree)
        .find(sym => String(sym) === "Symbol(key-count)");
    const $_QUADS = Object.getOwnPropertySymbols(newStore._h_quad_tree)
        .find(sym => String(sym) === "Symbol(quad-count)");

    // Iterate over the index tree to properly assign Symbols
    for (const graph in index) {
        if (graph === '{KEYS}') {
            index[$_KEYS] = index[graph];
            delete index[graph];
        } else if (graph === '{QUADS}') {
            index[$_QUADS] = index[graph];
            delete index[graph];
        } else {
            const triples = index[graph];
            for (const subject in triples) {
                if (subject === '{KEYS}') {
                    triples[$_KEYS] = triples[subject];
                    delete triples[subject];
                } else if (subject === '{QUADS}') {
                    triples[$_QUADS] = triples[subject];
                    delete triples[subject];
                } else {
                    const pairs = triples[subject];
                    for (const predicate in pairs) {
                        if (predicate === '{KEYS}') {
                            pairs[$_KEYS] = pairs[predicate];
                            delete pairs[predicate];
                        } else if (predicate === '{QUADS}') {
                            pairs[$_QUADS] = pairs[predicate];
                            delete pairs[predicate];
                        }
                    }
                }
            }
        }
    }
}

function queryGraphStore(params) {
    let res = {};
    let sub = null;
    let obj = null;

    // Handle Subject being a NamedNode or a BlankNode
    if (params.s) {
        if (params.s.value) {
            sub = params.s.value.startsWith('n3-') ? blankNode(params.s.value) : namedNode(params.s.value);
        } else {
            sub = params.s.startsWith('n3-') ? blankNode(params.s) : namedNode(params.s);
        }
    }

    // Handle Object being a NamedNode, Literal or BlankNode
    if (params.o) {
        obj = /(https?):\/\//.test(params.o) ? namedNode(params.o)
            : params.o.startsWith('n3-') ? blankNode(params.o)
                : literal(params.o);
    }

    // Execute query
    const iterator = params.store.match(
        sub,
        params.p ? namedNode(params.p) : null,
        obj
    );

    // Extract results (if any)
    if (iterator.size > 0) {
        for (const q of iterator) {
            if (res[q.subject.value]) {
                let path = res[q.subject.value][q.predicate.value];
                if (path) {
                    if (Array.isArray(path)) {
                        if (path.findIndex(o => o.value === q.object.value && o.language === q.object.language) < 0) {
                            res[q.subject.value][q.predicate.value].push(q.object);
                        }
                    } else {
                        if (path.value !== q.object.value) {
                            const arr = [path, q.object];
                            res[q.subject.value][q.predicate.value] = arr;
                        }
                    }
                } else {
                    res[q.subject.value][q.predicate.value] = q.object;
                }

            } else {
                res[q.subject.value] = { [q.predicate.value]: q.object };
            }
        }
        return res;
    } else {
        return null;
    }
}

function getPropertyDomain(property, store) {
    const domain = queryGraphStore({ s: property, p: RDFS.domain, store })

    if (!domain) return [];

    const domainBN = domain[property][RDFS.domain];

    if (domainBN && domainBN.startsWith('n3-')) {
        const domains = [];
        const unionBN = queryGraphStore({ s: domainBN, p: OWL.unionOf, store })[domainBN][OWL.unionOf];
        let domainList = queryGraphStore({ s: unionBN, store })[unionBN];
        domains.push(domainList[RDF.first]);

        while (domainList[RDF.rest] != RDF.nil) {
            domainList = queryGraphStore({ s: domainList[RDF.rest], store })[domainList[RDF.rest]];
            domains.push(domainList[RDF.first]);
        }

        return domains
    } else {
        return [domainBN];
    }
}


async function getOPInfo(opid, store, local) {
    // Query for all OP attributes

	//console.log(opid, store, local);

	if(opid){
    
		const op = local ? queryGraphStore({ s: opid, store })
			: await store.exec("query", { data: { s: opid } });
		// Check this is an OP
		if (op && op[opid][ERA.uopid]) {
			// Expand object properties
			await deepExpansion(op[opid], store, local);
			// Attach entity @id
			op[opid]['@id'] = opid;
			return op[opid];
		}
		
    }else{
		let op = {}
		op[opid] = {}
		op[opid]['@id'] = opid;
		return null;
	}
}

async function getCountryInfo(country, store) {
    const c = await store.exec("query", {
        data: {
            s: country
        }
    });
    if (c[country]) {
        c[country]['@id'] = country;
        return c[country];
    }
}


async function getOPInfoFromLocation(geometry, lngLat, graphStore) {
    let op = null;
    op = await getOPInfo(geometry, graphStore);

    if (op) {

        if (lngLat) op.lngLat = [lngLat.lng, lngLat.lat];
        return op;
    } else {
        // Query for OP ID
        const opId = await graphStore.exec("query", {
            data: {
                p: WGS84.location,
                o: geometry
            }
        });

        if (opId) {
            // Query for all OP attributes
            const op = await getOPInfo(Object.keys(opId)[0], graphStore);
            if (lngLat) op.lngLat = [lngLat.lng, lngLat.lat];
            return op;
        }
    }
}

function getOPFromMicroNetElement(mne, store) {
    // Get associated meso NetElement
    const ne = queryGraphStore({ store, p: ERA.elementPart, o: mne });
    if (ne) {
        const mesoNeId = Object.keys(ne)[0];
        // Get associated OP URI if any
        const op = queryGraphStore({
            store,
            p: ERA.hasAbstraction,
            o: mesoNeId
        });
        if (op) {
            return Object.keys(op)[0];
        } else {
            return null;
        }
    } else {
        return null;
    }
}

function getTrackIdFromMicroNetElement(mne, store) {
    const track = queryGraphStore({
        store: store,
        p: ERA.hasAbstraction,
        o: mne
    });

    if (track) {
        return Object.keys(track)[0];
    } else {
        return null;
    }
}

function getCoordsFromOP(op, store) {
    const loc = queryGraphStore({ store, s: op, p: WGS84.location });
    if (loc) {
        const l = loc[op][WGS84.location];
        return getCoordsFromLocation(Array.isArray(l) ? l[0].value : l.value, store);
    }
    return null;
}

async function getCoordsFromLocation(loc, store) {
    const location = (await store.exec("query", {
        data: {
            s: loc
        }
    }))[loc];
    return wktParse(location[GEOSPARQL.asWKT].value).coordinates;
}

function getLengthFromMicroNetElement(mne, store) {
    // Get micro NetElement properties
    const microNe = queryGraphStore({ store, s: mne });
    return parseFloat(microNe[mne][ERA.length].value);
}

async function getMicroNetElements(op, store) {
    // Get Operational Point meso NetElement
    const mesoNe = (await store.exec("query", {
        data: {
            s: op,
            p: ERA.hasAbstraction
        }
    }))[op][ERA.hasAbstraction].value;

    // Query for all micro NetElements associated to this meso NetElement
    const queryNps = await store.exec("query", {
        data: {
            s: mesoNe,
            p: ERA.elementPart
        }
    });

    // Check in there any micro NetElements because there are disconnected OPs
    if (queryNps) {
        return Array.isArray(queryNps[mesoNe][ERA.elementPart])
            ? queryNps[mesoNe][ERA.elementPart] : [queryNps[mesoNe][ERA.elementPart]];
    } else {
        return [];
    }
}

async function getVehicleTypeInfo(v, store) {
    const vh = await store.exec("query", { data: { s: v } });

    if (vh && vh[v]) {
        // Expand object properties
        await deepExpansion(vh[v], store);

        vh[v]["@id"] = v;
        return vh[v];
    }
}

async function getTrackInfo(t, store, local) {
    const track = local ? queryGraphStore({ store, s: t })
        : await store.exec("query", { data: { s: t } });

    if (track && track[t]) {
        // Expand object properties
        await deepExpansion(track[t], store, local);
        track[t]["@id"] = t;

        return track[t];
    }
}

async function getConstantTriples(constants, store) {
    const obj = {};

    for (const c of constants) {
        obj[c] = (await store.exec("query", { data: { s: c } }))[c];
        obj[c]["@id"] = c;
    }

    return obj;
}

async function deepExpansion(entity, store, local) {
    for (const pred of Object.keys(entity)) {
        // Skip unnecessary predicate expansions
        if ([
            ERA.hasAbstraction,
            ERA.notApplicable,
            ERA.notYetAvailable,
            SKOS.inScheme,
            SKOS.exactMatch, // This will cause an infinite loop
            SKOS.closeMatch, // This will cause an infinite loop
            RDF.type
        ].includes(pred)) {
            continue;
        }

        const value = entity[pred];
        let expanded = null;

        if (Array.isArray(value)) {
            const exps = [];
            for (const val of value) {
                if (isValidHttpUrl(val.value)) {
                    const exp = local ? queryGraphStore({ s: val.value, store })
                        : await store.exec("query", { data: { s: val.value } });
                    if (exp) {
                        // Expand recursively
                        await deepExpansion(exp[val.value], store, local);
                        exp[val.value]["@id"] = val.value;
                        exps.push(exp[val.value]);
                    } else {
                        exps.push(val);
                    }
                    if (exps.length > 0) expanded = exps;
                }
            }
        } else {
            // Check if value is a named entity that can be expanded
            if (isValidHttpUrl(value.value)) {
                const exp = local ? queryGraphStore({ s: value.value, store })
                    : await store.exec("query", { data: { s: value.value } });
                if (exp) {
                    // Expand recursively
                    expanded = exp[value.value];
                    await deepExpansion(expanded, store, local);
                    expanded["@id"] = value.value;
                }
            }
        }

        if (expanded) {
            entity[pred] = expanded;
        }
    }
}

function deepClone(obj) {
    let copy;

    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (obj instanceof Map) {
        return new Map(deepClone(Array.from(obj)));
    }

    if (obj instanceof Set) {
        return new Set(deepClone(Array.from(obj)));
    }

    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepClone(obj[i]);
        }
        return copy;
    }

    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = deepClone(obj[attr]);
            }
        }
        return copy;
    }
    throw new Error('Unable to copy object! Its type isn\'t supported');
}

function deepLookup(obj, paths) {
    if (Array.isArray(obj)) {
        const arr = [];
        for (const o of obj) {
            if (paths.length === 1) {
                arr.push(o[paths[0]]);
            } else {
                const path = paths.shift();
                if (o[path]) {
                    arr.push(deepLookup(o[path], paths));
                }
            }
        }
        return arr;
    } else {
        if (paths.length === 1) {
            return obj[paths[0]];
        } else {
            const path = paths.shift();
            if (obj[path]) {
                return deepLookup(obj[path], paths);
            } else {
                return null;
            }
        }
    }
}

function concatToPosition(fullList, partList, page, size) {
    const newList = [...fullList];
    let position = page * size;

    for (let i = 0; i < size; i++) {
        if (partList[i]) {
            newList[position + i] = partList[i];
        }
    }

    return newList;
}

function getLiteralInLanguage(values, language) {
    if (!values) {
        return '';
    } else if (Array.isArray(values)) {
        let i = values.findIndex(v => v.language === language);
        if (i < 0) {
            i = values.findIndex(v => v.language === 'en');
            if (i < 0) return values[0].value;
            return values[i].value;
        } else {
            return values[i].value;
        }
    } else {
        return values.value;
    }
}

function getIterationIndexes(from, to, path) {
    let min = null;
    let max = null;

    for (const [i, node] of path.entries()) {
        if (node.implType === ERA.OperationalPoint) {
            if (node.impl === from) {
                min = i;
                continue;
            }
            if (node.impl === to) {
                max = i;
                break;
            }
        }
    }
    return [min, max]
}

/**
 * This horrible hack is needed because when one writes triples
 * about a certain subject in a Graphy.js graph store
 * and then one reads them (via graph.match()),
 * is not possible to add more triples about that same subject afterwards.
 * The reason for this is because a Symbol(has-descendents) property 
 * is added to the subject's index during graph.match(),
 * which prevents new triples to be added via graph.add().
 * This is probably a bug in Graphy.js but afaik is not actively maintained anymore.
 * 
 * So what happens here is that we check if the subject's index
 * has the Symbol(has-descendents) and if so, delete it. 
 */
function handleWeirdStoreIssue(quad, graph) {
    if (graph._h_quad_tree['*']) {
        const subIndex = graph._h_quad_tree['*'][`>${quad.subject.value}`];
        if (subIndex) {
            const sym = Object.getOwnPropertySymbols(subIndex)
                .find(sym => String(sym) === "Symbol(has-descendents)");
            if (sym) delete subIndex[sym];
        }
    }
}

export default {
    validateCoords,
    text_normalize,
    concatToPosition,
    long2Tile,
    lat2Tile,
    tile2long,
    tile2lat,
    longLat2Tile,
    vh,
    vw,
    isValidHttpUrl,
    printLiteral,
    getTileFrame,
    rebuildQuad,
    serializeQuad,
    processTopologyQuads,
    rebuildGraphIndex,
    queryGraphStore,
    getOPInfo,
    getCountryInfo,
    getOPInfoFromLocation,
    getOPFromMicroNetElement,
    getCoordsFromOP,
    getCoordsFromLocation,
    getLengthFromMicroNetElement,
    getMicroNetElements,
    getVehicleTypeInfo,
    getTrackInfo,
    getTrackIdFromMicroNetElement,
    getConstantTriples,
    deepClone,
    deepLookup,
    getPropertyDomain,
    getLiteralInLanguage,
    getIterationIndexes,
    handleWeirdStoreIssue
};
