import React, {useEffect} from "react";
import {
	ButtonGroup,
	Button,
	Panel,
	Stack,
	Modal,
	SelectPicker,
	Input,
	Tag,
	TagGroup
} from "rsuite";

import { CreateSubscription } from "./CreateSubscription.js";
					  
					  
export const Subscription = (props) => {
	
	//{ id: let id, label: let label, value: this.state.value, invisible: this.invisible, visible: this.state.visible, onChange: this.onChange, onClean: this.onClean } = props);

	const [id, setId] = React.useState(props.id);
		
	const [data, setData] = React.useState(props.data);
	
	const [header, setHeader] = React.useState();
	
	const tagStyle = {color: "white"}
	
	useEffect(() => {
		
		
		if (data.type == "country"){
	
			setHeader(
				<>
				<h6>Country: </h6>
				<TagGroup style={{paddingLeft: "15px", marginTop: "5px"}}> 
							
					<Tag color={"blue"}><a style={tagStyle} href={data.value.value} target="_blank">{data.value.label}</a></Tag>
					
				</TagGroup>
				</>
			);
			
		}
		
		if (data.type == "sol"){
	
			setHeader(
				<>
					<h6>Section(s) of Line: </h6>
					<TagGroup style={{paddingLeft: "15px", marginTop: "5px"}}> 
						{
							data.value.map((element, index) => {
								
								return(
									<Tag color={"blue"} key={index}><a style={tagStyle} href={element.value} target="_blank">{element.label}</a></Tag>
								);
								
							})
						}
					</TagGroup>
				</>
			);
			
		}
		
		if (data.type == "op"){
				
			setHeader(
				<>
					<h6>Operational Point(s): </h6>
					<TagGroup style={{paddingLeft: "15px", marginTop: "5px"}}>
						{
							data.value.map((element, index) => {
								return(
									<Tag color={"blue"} key={index}><a style={tagStyle} href={element.value} target="_blank">{element.label}</a></Tag>
								);
								
							})
						}
					</TagGroup>
				</>
			);
			
		}
		
		
		if (data.type == "national-line"){
	
			
			setHeader(
				<>
					<h6>National Line(s): </h6>
					<TagGroup style={{paddingLeft: "15px", marginTop: "5px"}}>
						{
							data.value.map((element, index) => {
								return(
									<Tag color={"blue"} key={index}><a style={tagStyle} href={element.value} target="_blank">{element.label}</a></Tag>
								);
								
							})
						}
					</TagGroup>
				</>
			);
			
		}
		
				
		if (data.type == "im"){
	
			
			setHeader(
				<>
					<h6>IM(s): </h6>
					<TagGroup style={{paddingLeft: "15px", marginTop: "5px"}}>
						{
							data.value.map((element, index) => {
								return(
									<Tag color={"blue"} key={index}><a style={tagStyle} href={element.value} target="_blank">{element.label}</a></Tag>
								);
								
							})
						}
					</TagGroup>
				</>
			);
			
		}
		
		
		if (data.type == "geographical-zone"){
	
			setHeader(
				<>
					<h6>Geographical Zone: </h6>
					<div style={{paddingLeft: "15px", marginTop: "5px"}}>
						Bounding box defined by <Tag color={"green"} style={{marginLeft: "0px"}}>{data.value[0][0].toFixed(4)}, {data.value[0][1].toFixed(4)}</Tag> and <Tag color={"green"} style={{marginLeft: "0px"}}>{data.value[1][0].toFixed(4)}, {data.value[1][1].toFixed(4)}</Tag>
					</div>
				</>
			);
			
		}
		
		if (data.type == "route"){
	
			setHeader(<h6>Route: <Tag>{data.value}</Tag></h6>);
			
		}
		
		
		
		
	}, [props]);
	

	const onDelete = () => {
		
		
		props.onDelete(id);
		
		
	}

	

	return(

		<Panel collapsible={false} expanded bordered header={<h5>{"Subscription "+(props.index+1)}</h5>} style={{"marginBottom": "15px"}}>
		
			<Stack justifyContent="space-between">
					
					{header}
					
					<ButtonGroup>
						<Button color="red" appearance="primary" onClick={onDelete}>Delete</Button>
					</ButtonGroup>
			</Stack>
			
		</Panel>
	);

	

}
