import Cookies from "js-cookie";

export function setActiveRole(appRoleId){
	
	// 1h timeout
	
	let now = new Date();
	
	now.setTime(now.getTime() + 1000 * 60 * 60 * 1)
	
	let expire = now.toUTCString();
	
    document.cookie = "activeRole=" + appRoleId + "; expires="+expire+"; SameSite=Lax";

}

export function getActiveRole(){
    return Cookies.get("activeRole")
}

export function clearCookies(){
    Cookies.remove("activeRole")
}
