import React, { Component } from "react";
import { Container, Panel } from "rsuite";
import { MainImg } from "../../styles/Styles.js";
import mainImgPath from "../../img/new_bg.png";
import { getPhrase } from "../../utils/Languages.js";



export default class landing extends Component{

	constructor(props) {

		super(props);
		this.state = {
			
			language: "en"

		};
		

	}

	componentDidUpdate() {
		const { language } = this.state;
		const storedLanguage = window.localStorage.getItem("language") || "en";

		if (language !== storedLanguage) {
			this.setState({ language: storedLanguage });
		}

	}

	render(){
			
		const {
			language
		} = this.state;
			
		return( 
		
		<Container style={{background: 'no-repeat center/100% url('+mainImgPath+') ', minHeight: "calc(100vh - 148px)"}}>
			<Container >
				
				<Panel style={{margin: "10vh 5vw", padding: "25px", background: "rgba(248, 251, 255, 0.8)"}} header={<h2>{getPhrase("landingTitle", language)}</h2> } bordered shaded>
					 <p>{getPhrase("landingText", language)}</p>
				</Panel>
			</Container>
		</Container>
		)
	}
}
